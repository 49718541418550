import { Provider } from '../models/provider';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { ProviderVideo } from '../models/provider-video';

@Injectable({
  providedIn: 'root'
})
export class HierarchySecurityService {
  constructor(private configurationService: ConfigurationService) {}

  public shouldShowVideos(provider: Provider): boolean {
    if (provider.videos.length <= 0) {
      return false;
    }
    let returnValue = true;
    provider.videos?.forEach((video: ProviderVideo) => {
      if(video.url.trim() === '') {
        returnValue = false;
      } else {
        returnValue = true;
      }       
    });

    if (!returnValue) {
      return returnValue;
    }

    return !!this.getValue<boolean>(provider, 'showProviderVideo');
  }

  public shouldShowLogos(provider: Provider): boolean {
    if (
      !this.configurationService.getMedGroupCodes().find((p) => provider.medicalGroupIds?.includes(p.code))?.logoPathAEM ||
      !this.configurationService.getMedGroupCodes().find((p) => provider.medicalGroupIds?.includes(p.code))?.logoPathFAD
    ) {
      return false;
    }

    return !!this.getValue<boolean>(provider, 'showMedGroupLogo');
  }

  public shouldShowStarRatings(provider: Provider): boolean {
    const showRatings = !!this.getValue<boolean>(provider, 'providerRatings', 'showStarRatings');
    const useLiveMedgroups = !!this.getValue<boolean>(provider, 'providerRatings', 'useLiveMedGroups');
    const liveMedGroups = this.getValue<string[]>(provider, 'providerRatings', 'liveMedGroups');

    if (showRatings === false) return false;
    else if (showRatings === true && useLiveMedgroups === false) return true;
    else if (useLiveMedgroups && !liveMedGroups) return false;

    return liveMedGroups?.some((r) => provider.medicalGroupIds?.indexOf(r) >= 0);
  }

  public getRatingsFooterText(provider: Provider): string {
    return this.getValue<string>(provider, 'providerRatings', 'ratingsFooterText') ?? null;
  }

  public getRatingsFooterLinkText(provider: Provider): string {
    return this.getValue<string>(provider, 'providerRatings', 'ratingsFooterLinkText') ?? null;
  }

  public getRatingsFooterLink(provider: Provider): string {
    return this.getValue<string>(provider, 'providerRatings', 'ratingsFooterLink') ?? null;
  }

  public getRatingsBannerText(provider: Provider): string {
    return this.getValue<string>(provider, 'providerRatings', 'ratingsBannerText') ?? null;
  }

  public getRatingsBannerLinkText(provider: Provider): string {
    return this.getValue<string>(provider, 'providerRatings', 'ratingsBannerLinkText') ?? null;
  }

  public getRatingsBannerLink(provider: Provider): string {
    return this.getValue<string>(provider, 'providerRatings', 'ratingsBannerLink') ?? null;
  }

  public getRatingsTooltipText(provider: Provider): string {
    return this.getValue<string>(provider, 'providerRatings', 'ratingsTooltipText') ?? null;
  }

  public shouldShowStarRatingsBanner(provider: Provider): boolean {
    return !!this.getValue<boolean>(provider, 'providerRatings', 'showStarRatingsBanner');
  }

  public shouldShowRatingText(provider: Provider): boolean {
    return !!this.getValue<boolean>(provider, 'providerRatings', 'showRatingText');
  }
  
  public shouldShowProviderReviews(provider: Provider): boolean {
    return !!this.getValue<boolean>(provider, 'providerRatings', 'showProviderReviews');
  }

  public shouldShowReviewCount(provider: Provider): boolean {
    return !!this.getValue<boolean>(provider, 'providerRatings', 'showReviewCount');
  }

  private getValue<T>(provider: Provider, parentProperty: string, childProperty: string = null): T {
    const providerDivision = provider.divisionCodes?.[0];
    const providerMarket = provider.marketCodes?.[0];

    const tenantValue: T = this.getTenantValue<T>(parentProperty, childProperty);
    const divisionValue: T = this.getDivisionValue<T>(providerDivision, parentProperty, childProperty);
    const marketValue: T = this.getMarketValue<T>(providerDivision, providerMarket, parentProperty, childProperty);

    if (marketValue !== undefined) {
      return marketValue;
    }
    if (divisionValue !== undefined) {
      return divisionValue;
    }
    return tenantValue ? tenantValue : undefined;
  }

  private getTenantValue<T>(parentProperty: string, childProperty: string = null): T {
    const tenantConfig = this.configurationService.getTenantSection();

    if (parentProperty && childProperty) {
      return tenantConfig[parentProperty] ? tenantConfig[parentProperty][childProperty] : undefined;
    }

    return tenantConfig[parentProperty] ?? undefined;
  }

  private getDivisionValue<T>(providerDivision: string, parentProperty: string, childProperty: string = null): T {
    const divisionConfig = this.configurationService.getDivisions().find((d) => d.divisionCode === providerDivision);
    if (!divisionConfig) {
      return undefined;
    }

    if (parentProperty && childProperty) {
      return divisionConfig[parentProperty] ? divisionConfig[parentProperty][childProperty] : undefined;
    }

    return divisionConfig[parentProperty] ?? undefined;
  }

  private getMarketValue<T>(providerDivision: string, providerMarket: string, parentProperty: string, childProperty: string = null): T {
    const divisionConfig = this.configurationService.getDivisions().find((d) => d.divisionCode === providerDivision);
    if (!divisionConfig) {
      return undefined;
    }
    const marketConfig = divisionConfig.markets?.find((d) => d.marketCode === providerMarket);
    if (!marketConfig) {
      return undefined;
    }

    if (parentProperty && childProperty) {
      return marketConfig[parentProperty] ? marketConfig[parentProperty][childProperty] : undefined;
    }

    return marketConfig[parentProperty] ?? undefined;
  }
}
