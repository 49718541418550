<div class="get-to-know-container" id="{{routId}}">
  <div>
    <div [ngClass]="{ 'get-to-know-container-has-video': isShowProviderVideo }">
      <div class="provider-stat fad-col-sm-12" *ngIf="provider.displayFullName">
        <h2 *ngIf="!isUpdatedProviderDetails">Get to know {{ provider.displayFullName }}</h2>
        <h2 *ngIf="isUpdatedProviderDetails">About</h2>
      </div>
      <div class="provider-stat fad-col-sm-12">
        <div class="fad-col-sm-2 provider-languages" *ngIf="provider.languages.length > 0">
          <div class="languages-header">
            <h3>Languages<br /></h3>
          </div>
          <cs-expandable-list [listItems]="provider.languages" [useColumns]="false"></cs-expandable-list>
        </div>
        <div class="fad-col-sm-2 provider-gender" *ngIf="provider.gender">
          <h3>Gender<br /></h3>
          <div role="list">
            <p role="listitem">{{ provider.gender }}</p>
          </div>
        </div>
        <div class="fad-col-sm-2 provider-experience" *ngIf="provider.yearsOfExperience">
          <h3>
            Experience<br />
            <p *ngIf="provider.yearsOfExperience > 1">{{ provider.yearsOfExperience }} years</p>
            <p *ngIf="provider.yearsOfExperience == 1">{{ provider.yearsOfExperience }} year</p>
          </h3>
        </div>
      </div>
      <cs-personal-info *ngIf="provider.personalInfo" [information]="provider.personalInfo"> </cs-personal-info>
      <cs-care-philosophy
        [philosophy]="provider.philosophy"
        [hideExtraContent]="hideExtraPhilosophyContent"
        [showMoreLessButton]="showMoreLessButtonForPhilosophy"
        *ngIf="provider.philosophy"
      ></cs-care-philosophy>
      <cs-own-words
        [ownWords]="provider.inMyOwnWords"
        [inMyOwnWordsTitle]="inMyOwnWordsTitle"
        [hideExtraContent]="hideExtraOwnwordsContent"
        [showMoreLessButton]="showMoreLessButtonForOwnwords"
        *ngIf="provider.inMyOwnWords"
      >
      </cs-own-words>
    </div>
    <h3 class="provider-name" *ngIf="isUpdatedProviderDetails && isShowProviderVideo">{{ provider.displayFullName }}</h3>
    <cs-provider-video
      [ngClass]="{ 'fix-center': isUpdatedProviderDetails }"
      [provider]="provider"
      [videoId]="videoId"
      (callTealiumService)="onStateChange($event)"
      *ngIf="isShowProviderVideo"
    ></cs-provider-video>
  </div>
  <div class="provider-stat fad-col-sm-12" *ngIf="getToKnowContent.length > 0">
    <h3>Medical Group<br /></h3>
    <div role="list">
      <div *ngFor="let getToKnow of getToKnowContent" role="listitem">
        <img class="provider-logo" src="{{ getToKnow.logo }}" alt="" loading="lazy" />
        <p class="medgroup-content" [innerHTML]="getToKnow.content" (click)="medGrpLinkClicked()"></p>
      </div>
    </div>
  </div>
  <cs-expandable-list
    *ngIf="showHospitalAffiliations"
    [sectionTitle]="'Hospital Affiliations'"
    [listItems]="provider.hospitalNames"
    [isAffiliationSection]="true"
  ></cs-expandable-list>
</div>
