export const LogoConstants = {
  default: '/assets/logos/dh_r_hrz_rgb_grd_pos 1.png',
  dhmg: '/assets/logos/dh_r_hrz_rgb_grd_pos.png',
  'dignity generic': '/assets/logos/dh_r_hrz_rgb_grd_pos 1.png',
  'dhmg arizona': '/assets/logos/dh_r_hrz_rgb_grd_pos_arizona.png',
  'dhmg nevada': '/assets/logos/dh_r_hrz_rgb_grd_pos_nevada.png',
  fad: 'fad',
  mycareteam: 'mycareteam',
  chart: 'chart'
};
