import { FadUrlBuilderStrategy } from './fad-url-builder-strategy';
import { MedGroupUrlBuilderStrategy } from './med-group-url-builder-strategy';
import { LocationUrlBuilderStrategy } from './location-url-builder-strategy';
import { LocationAndTermUrlBuilderStrategy } from './location-and-term-url-builder-strategy';
import { SearchTermUrlBuilderStrategy } from './search-term-url-builder-strategy';
import { UrlBuilderParams } from './url-builder-params';
import { Injectable } from '@angular/core';
import { SpecialtyUrlBuilderStrategy } from './specialty-url-builder-strategy';
import { ConfigurationService } from '../../services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class FadUrlBuilder {
  builders: FadUrlBuilderStrategy[] = [
    // Note: the order of these matters.
    new MedGroupUrlBuilderStrategy(),
    new SpecialtyUrlBuilderStrategy(this.configurationService),
    new SearchTermUrlBuilderStrategy(),
    new LocationUrlBuilderStrategy(),
    new LocationAndTermUrlBuilderStrategy()
  ];

  defaultBuilder: FadUrlBuilderStrategy;

  constructor(private configurationService: ConfigurationService) {
    this.defaultBuilder = new SearchTermUrlBuilderStrategy();    
  }

  public build(urlBuilderParams: UrlBuilderParams, baseUrl: string = null): string {
    for (const builder of this.builders) {
      if (builder.shouldBuild(urlBuilderParams)) {
        return builder.build(urlBuilderParams, baseUrl);
      }
    }
    return this.defaultBuilder.build(urlBuilderParams, baseUrl);
  }
}
