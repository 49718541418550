import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VaccinationLocationComponent } from './vaccination-location.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/components/shared.module';
import { CovidVaccinationLocationCardComponent } from 'src/app/components/covid-vaccination-location-card/covid-vaccination-location-card.component';

@NgModule({
  declarations: [VaccinationLocationComponent, CovidVaccinationLocationCardComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: VaccinationLocationComponent
      }
    ])
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VaccinationLocationModule {}