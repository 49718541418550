import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule, StarRatingModule, TooltipModule } from '@dignity-health/ngx-components';
import { ResizeService } from '../../services/resize.service';
import { BadgesModule } from '../badges/badges.module';
import { DocAsapBookingLauncherModule } from '../doc-asap-booking-launcher/doc-asap-booking-launcher.module';
import { InNetworkBannerComponent } from '../in-network-banner/in-network-banner.component';
import { ResultCardLocationListComponent } from './result-card-location-list/result-card-location-list.component';
import { ResultCardComponent } from './result-card.component';
import { PipesModule } from '../../pipes/pipes.module';
import { HospitalAffiliationsComponent } from './hospital-affiliations/hospital-affiliations.component';
import { PatientAgeRestrictionsModule } from '../patient-age-restrictions/patient-age-restrictions.module';

@NgModule({
  declarations: [
    ResultCardComponent,
    ResultCardLocationListComponent,
    InNetworkBannerComponent,
    HospitalAffiliationsComponent
  ],
  imports: [
    CommonModule,
    BadgesModule,
    ButtonModule,
    RouterModule,
    DocAsapBookingLauncherModule,
    StarRatingModule,
    TooltipModule,
    PipesModule,
    PatientAgeRestrictionsModule
  ],
  exports: [ResultCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ResizeService]
})
export class ResultCardModule {}
