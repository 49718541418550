<div class="cards-container" #container>
  <div *ngIf="showCards && providerCards.length > 0">
    <div class="cards-container-header">
      <span class="cards-container-header-title h2">{{ headingText }}</span>
      <span class="cards-container-header-description">{{ subHeadingText }}</span>
    </div>
    <div class="provider-cards">
      <cs-result-card
        *ngFor="let card of suggestedProviderCards"
        class="result-card"
        [provider]="card"
        (scheduleProvider)="scheduleProviderHandler($event)"
        [showDocAsap]="showDocAsap"
        [docAsapIds]="docAsapIds"
      >
      </cs-result-card>
    </div>
    <div *ngIf="providerCards.length > 3" class="pagination">
      <dhcl-pagination [totalPages]="totalPages" [activePage]="pageIndex" (pagerClicked)="getPaginatorData($event)"> </dhcl-pagination>
    </div>
    <div *ngIf="searchButtonLink && searchParams.displaySearchButton" class="network-search">
      <a href="{{ searchButtonLink }}" target="blank">Search our network</a>
    </div>
  </div>
</div>

<dhcl-modal
  *ngIf="onlineSchedulingType === 'GuidedFlow' && launchGuidedFlow && guidedFlowProvider"
  [alignTop]="true"
  [customClass]="'guided-flow-modal'"
  [closeOnOverlay]="true"
  [show]="true"
  (closeClicked)="launchGuidedFlow = false"
>
  <div *ngIf="isCernerPage">
    <dhgf-guided-flow
      *ngIf="runtimeConfig.envName"
      [environment]="runtimeConfig.envName"
      [provider]="guidedFlowProvider"
      [facets]="[]"
      [npi]="guidedFlowProvider.npi"
      [showNewSchedulingApp]="true"
      [isCaptchaEnabled]="isCaptchaEnabled"
      [baseUrl]="bookingBaseUrl_Cerner"
      [handleNavigateToSchedulingSpa]="true"
      (navigateToSchedulingSpa)="navigateToCernerSchedulingSpa($event)"
      [market]="market"
      [useLegacyAPI]="useLegacyAPI"
      [embedSource]="embedSource"
      [showProviderPrefix]="showProviderPrefix"
      (closeClicked)="launchGuidedFlow = false"
    >
    </dhgf-guided-flow>
  </div>
  <div *ngIf="!isCernerPage">
    <dhgf-guided-flow
      *ngIf="runtimeConfig.envName"
      [environment]="runtimeConfig.envName"
      [provider]="guidedFlowProvider"
      [facets]="[]"
      [npi]="guidedFlowProvider.npi"
      [showNewSchedulingApp]="true"
      [isCaptchaEnabled]="isCaptchaEnabled"
      [baseUrl]="bookingBaseUrl"
      [handleNavigateToSchedulingSpa]="handleNavigateToSchedulingSpa"
      (navigateToSchedulingSpa)="navigateToSchedulingSpa"
      [market]="market"
      [useLegacyAPI]="useLegacyAPI"
      [embedSource]="embedSource"
      [showProviderPrefix]="showProviderPrefix"
      (closeClicked)="launchGuidedFlow = false"
    >
    </dhgf-guided-flow>
  </div>
</dhcl-modal>
