<div class="cs-doc-asap-booking-launcher">
  <div>
    <button
      dhclButton
      buttonType="primary"
      [buttonSize]="buttonSize"
      [hasArrow]="launcherButtonText === 'Start here'"
      (click)="showLauncher()"
    >
      {{ launcherButtonText }}
    </button>

    <dhcl-modal
      *ngIf="showDocAsapLauncher"
      [alignTop]="false"
      [customClass]="'doc-asap-booking-modal'"
      [closeOnOverlay]="false"
      [show]="true"
      (closeClicked)="handleCloseClicked()"
    >
      <dhcl-loading-spinner
        class="dhcl-loading-spinner"
        [bgColor]="'transparent'"
        [showSpinner]="isLoading"
        [fixed]="false"
      ></dhcl-loading-spinner>
      <iframe class="doc-asap-iframe" [src]="docUrl" title=""></iframe>
    </dhcl-modal>
  </div>
</div>
