<div class="google-maps-container" *ngIf="apiLoaded | async">
  <h2 class="locations-label">Locations</h2>
  <a
    tabindex="0"
    (click)="showMapClick()"
    [attr.aria-label]="displayMap(this.showMap)"
    class="map-toggle p p2"
    [ngClass]="{hideMap: this.showMap}"
    (keydown.enter)="showMapClick()"
    (keydown.space)="showMapClick()"
    role="button"
  >
    <svg viewBox="0 0 21 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 0C19.9286 0 19.8571 0.0357143 19.7857 0.0714286L13.7143 2.28571L7.57143 0.142857C7.35714 0.0714286 7.03571 0.0357143 6.82143 0C6.60714 0 6.35714 0.0714286 6.10714 0.142857L0.714286 2C0.321429 2.17857 0 2.64286 0 3.07143V15.4286C0 15.7857 0.25 16 0.535714 16C0.607143 16 0.678571 16 0.75 15.9643L6.85714 13.7143L13 15.8929C13.1786 15.9643 13.5 16 13.7143 16C13.8929 16 14.2143 15.9643 14.4286 15.8929L19.8214 14.0357C20.2143 13.8571 20.5714 13.3929 20.5714 12.9643V0.607143C20.5714 0.25 20.2857 0 20 0ZM8 2.10714L12.5714 3.71429V13.9286L8 12.3214V2.10714ZM1.71429 13.8214V3.5L6.28571 1.89286V12.1071H6.25L1.71429 13.8214ZM18.8571 12.5357L14.2857 14.1429V3.92857L18.8571 2.21429V12.5357Z"
      />
    </svg>
    {{ this.showMap ? 'Hide Map' : 'Show Map' }}
  </a>
  <div class="maps-container" [style.display]="showMap ? 'block' : 'none'">
    <div #mapContainer id="map" class="fad-location-map"></div>
  </div>
</div>
