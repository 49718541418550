import { Inject, Injectable } from '@angular/core';
import { ROOT_ELEMENT } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class FadLinkService {
  private static CsFadElementName = 'cs-fad';
  private static FadSaasWebElementName = 'fad-saas';

  public isFadSpa: boolean;
  public baseUrl: string;

  constructor(@Inject(ROOT_ELEMENT) private rootEl: HTMLElement) {
    if (rootEl) {
      this.isFadSpa = this.isSelfHosted() || this.isFadSaasAngularElement();
      this.baseUrl =
        rootEl.getAttribute('fad-base-url') ??
        rootEl.getAttribute('fadBaseUrl') ??
        rootEl.getAttribute('base-url') ??
        rootEl.getAttribute('baseUrl') ??
        '';
    }
  }

  public createUrlWithBase(url: string): string {
    url = url.trimTrailingForwardSlash();
    if (this.isFadSpa) {
      return url;
    }
    return `${this.baseUrl}/${url.trimForwardSlashes()}`;
  }

  public isSelfHosted(): boolean {
    return this.rootEl.tagName.toLowerCase() === FadLinkService.CsFadElementName;
  }

  public isFadSaasAngularElement(): boolean {
    return this.rootEl.tagName.toLowerCase() === FadLinkService.FadSaasWebElementName;
  }
}
