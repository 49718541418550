<div class="error-container" *ngIf="errorMessage" (window:resize)="(isDesktopViewport)">
  <div class="error-header">
    <p class="message">{{ errorMessage }}</p>
    <svg class="error-icon" *ngIf="isDesktopViewport" viewBox="0 0 335 251" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M141.763 42.7037C145.435 38.7553 149.66 35.2183 152.077 33.2945L156.23 34.6085L286.257 75.7907C287.317 79.37 288.02 83.5998 288.467 87.0994L141.763 42.7037ZM274.975 51.0707C277.146 54.463 279.577 59.5108 281.613 64.1243L230.838 48.0427L210.229 41.5167L274.975 51.0707ZM294.795 70.2486L294.79 70.2405C294.79 70.2405 294.79 70.2389 294.79 70.2372C294.5 69.4746 287.656 51.5065 281.824 43.7137C281.047 42.6746 279.879 41.9818 278.58 41.7899L151.821 23.0852C151.743 23.0738 151.668 23.0803 151.59 23.0738C151.472 23.0608 151.355 23.0575 151.236 23.0527C150.977 23.0445 150.724 23.0575 150.473 23.0884C150.367 23.1014 150.263 23.1112 150.159 23.1307C149.852 23.1893 149.553 23.2738 149.263 23.3877C149.221 23.4039 149.177 23.4121 149.135 23.4299C148.81 23.5682 148.505 23.7454 148.219 23.9471C148.192 23.9682 148.157 23.978 148.128 23.9991C147.617 24.3764 135.549 33.3141 129.292 42.564C128.428 43.8373 128.23 45.4391 128.753 46.8799C129.277 48.3224 130.461 49.438 131.951 49.8868L292.637 98.5138C293.113 98.657 293.603 98.7285 294.089 98.7285C295.178 98.7285 296.253 98.374 297.133 97.6959C298.405 96.7201 299.109 95.1899 299.02 93.6059C298.985 93.0221 298.166 79.2221 294.795 70.2486Z"
        fill="var(--dhcl-color-primary)"
      />
      <path
        d="M209.105 190.299C169.812 163.434 130.009 190.027 129.612 190.299C127.391 191.818 126.823 194.844 128.336 197.069C129.851 199.293 132.884 199.874 135.111 198.358C136.516 197.408 169.899 175.313 203.597 198.353C204.439 198.931 205.399 199.205 206.347 199.205C207.903 199.205 209.433 198.462 210.378 197.08C211.899 194.855 211.33 191.821 209.105 190.299Z"
        fill="var(--dhcl-color-primary)"
      />
      <path
        d="M257.725 23.4564C257.725 20.7666 259.905 18.5842 262.597 18.5842C265.287 18.5842 267.467 20.7666 267.467 23.4564"
        fill="var(--dhcl-color-primary)"
      />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="54" width="334" height="196">
        <path d="M0 54.1035H333.536V249.755H0V54.1035Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M304.807 107.897C303.439 107.066 301.512 106.533 299.486 107.921C299.396 107.978 299.312 108.04 299.226 108.102C299.159 108.152 299.094 108.187 299.027 108.242C298.944 108.312 298.878 108.383 298.801 108.453C298.731 108.518 298.66 108.578 298.593 108.648C297.089 110.133 296.983 111.701 297.318 112.964C297.557 114.192 298.264 115.248 299.239 115.958C314.709 129.303 323.78 148.731 323.78 169.392C323.78 208.325 292.106 239.998 253.174 239.998H70.6987C37.0946 239.998 9.7563 212.659 9.7563 179.057C9.7563 152.751 26.5258 129.509 51.4866 121.221L54.9813 120.061L54.8236 116.384C54.7927 115.656 54.7683 114.924 54.7683 114.187C54.7683 86.4375 77.3434 63.8608 105.095 63.8608C109.588 63.8608 114.008 64.4641 118.301 65.635C118.316 65.6382 118.321 65.635 118.335 65.6382C120.126 66.0985 124.13 66.5522 125.509 62.7436C126.888 58.9382 123.487 57.1397 122.672 56.7299C121.859 56.32 111.092 54.1035 105.095 54.1035C72.2989 54.1035 45.5574 80.5149 45.0191 113.184C17.9475 123.718 -0.000976562 149.749 -0.000976562 179.057C-0.000976562 218.039 31.7151 249.755 70.6987 249.755H253.174C297.487 249.755 333.537 213.705 333.537 169.392C333.537 145.523 322.902 123.097 304.807 107.897Z"
          fill="var(--dhcl-color-primary-dark)"
        />
      </g>
      <path
        d="M170.13 14.4104C170.13 14.4104 170.572 15.1958 171.513 15.8301C171.515 15.8317 171.517 15.8317 171.517 15.8333C171.733 15.978 171.966 16.1179 172.235 16.235L172.265 16.2382C172.873 16.5098 173.54 16.6675 174.247 16.6675C174.99 16.6675 175.626 16.3423 176.317 16.1911C176.347 16.1813 176.373 16.1764 176.4 16.165C176.712 16.0496 177.002 15.9618 177.277 15.8886C187.021 11.9369 197.572 9.75777 208.471 9.75777C227.236 9.75777 244.552 16.0073 258.504 26.5045L258.506 26.4834C258.506 26.4834 258.649 26.6118 258.901 26.7989C258.908 26.8037 258.916 26.8086 258.924 26.8151C260.066 27.6559 263.347 29.6106 266.141 26.7956C269.143 23.7708 266.678 20.7184 265.995 19.985C250.141 7.49083 230.176 0.000488281 208.471 0.000488281C196.175 0.000488281 184.276 2.48697 173.302 6.98833C171.154 7.75427 169.36 9.40488 169.36 11.7792C169.36 12.7061 169.634 13.5647 170.082 14.3047C170.099 14.3388 170.112 14.3746 170.13 14.4104Z"
        fill="var(--dhcl-color-primary-dark)"
      />
      <path
        d="M223.245 136.81C210.2 136.81 200.424 125.812 200.01 125.339C198.237 123.311 198.442 120.229 200.47 118.455C202.493 116.682 205.568 116.886 207.346 118.904C207.507 119.084 214.725 127.053 223.242 127.053C223.349 127.053 223.456 127.051 223.565 127.048C228.27 126.937 232.77 124.251 236.943 119.068C238.634 116.97 241.704 116.637 243.804 118.326C245.901 120.016 246.233 123.088 244.544 125.186C238.466 132.736 231.461 136.644 223.72 136.805C223.562 136.808 223.403 136.81 223.245 136.81Z"
        fill="var(--dhcl-color-primary)"
      />
      <path
        d="M117.542 136.81C104.496 136.81 94.7212 125.812 94.3065 125.339C92.534 123.311 92.7389 120.229 94.7668 118.455C96.7898 116.682 99.8666 116.886 101.642 118.904C101.803 119.084 109.022 127.053 117.539 127.053C117.646 127.053 117.753 127.051 117.862 127.048C122.567 126.937 127.067 124.251 131.239 119.068C132.929 116.97 136.001 116.637 138.1 118.326C140.198 120.016 140.53 123.088 138.84 125.186C132.763 132.736 125.758 136.644 118.017 136.805C117.859 136.808 117.7 136.81 117.542 136.81Z"
        fill="var(--dhcl-color-primary)"
      />
    </svg>
  </div>
  <div class="error-links">
    <p *ngIf="errorLinks.length > 0">Here are some helpful links instead:</p>
    <div class="links">
      <a *ngFor="let link of errorLinks" href="{{ link.url }}"> {{ link.title }} </a>
    </div>
  </div>
  <svg class="error-icon mobile" *ngIf="!isDesktopViewport" viewBox="0 0 335 251" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M141.763 42.7037C145.435 38.7553 149.66 35.2183 152.077 33.2945L156.23 34.6085L286.257 75.7907C287.317 79.37 288.02 83.5998 288.467 87.0994L141.763 42.7037ZM274.975 51.0707C277.146 54.463 279.577 59.5108 281.613 64.1243L230.838 48.0427L210.229 41.5167L274.975 51.0707ZM294.795 70.2486L294.79 70.2405C294.79 70.2405 294.79 70.2389 294.79 70.2372C294.5 69.4746 287.656 51.5065 281.824 43.7137C281.047 42.6746 279.879 41.9818 278.58 41.7899L151.821 23.0852C151.743 23.0738 151.668 23.0803 151.59 23.0738C151.472 23.0608 151.355 23.0575 151.236 23.0527C150.977 23.0445 150.724 23.0575 150.473 23.0884C150.367 23.1014 150.263 23.1112 150.159 23.1307C149.852 23.1893 149.553 23.2738 149.263 23.3877C149.221 23.4039 149.177 23.4121 149.135 23.4299C148.81 23.5682 148.505 23.7454 148.219 23.9471C148.192 23.9682 148.157 23.978 148.128 23.9991C147.617 24.3764 135.549 33.3141 129.292 42.564C128.428 43.8373 128.23 45.4391 128.753 46.8799C129.277 48.3224 130.461 49.438 131.951 49.8868L292.637 98.5138C293.113 98.657 293.603 98.7285 294.089 98.7285C295.178 98.7285 296.253 98.374 297.133 97.6959C298.405 96.7201 299.109 95.1899 299.02 93.6059C298.985 93.0221 298.166 79.2221 294.795 70.2486Z"
      fill="var(--dhcl-color-primary)"
    />
    <path
      d="M209.105 190.299C169.812 163.434 130.009 190.027 129.612 190.299C127.391 191.818 126.823 194.844 128.336 197.069C129.851 199.293 132.884 199.874 135.111 198.358C136.516 197.408 169.899 175.313 203.597 198.353C204.439 198.931 205.399 199.205 206.347 199.205C207.903 199.205 209.433 198.462 210.378 197.08C211.899 194.855 211.33 191.821 209.105 190.299Z"
      fill="var(--dhcl-color-primary)"
    />
    <path
      d="M257.725 23.4564C257.725 20.7666 259.905 18.5842 262.597 18.5842C265.287 18.5842 267.467 20.7666 267.467 23.4564"
      fill="var(--dhcl-color-primary)"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="54" width="334" height="196">
      <path d="M0 54.1035H333.536V249.755H0V54.1035Z" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M304.807 107.897C303.439 107.066 301.512 106.533 299.486 107.921C299.396 107.978 299.312 108.04 299.226 108.102C299.159 108.152 299.094 108.187 299.027 108.242C298.944 108.312 298.878 108.383 298.801 108.453C298.731 108.518 298.66 108.578 298.593 108.648C297.089 110.133 296.983 111.701 297.318 112.964C297.557 114.192 298.264 115.248 299.239 115.958C314.709 129.303 323.78 148.731 323.78 169.392C323.78 208.325 292.106 239.998 253.174 239.998H70.6987C37.0946 239.998 9.7563 212.659 9.7563 179.057C9.7563 152.751 26.5258 129.509 51.4866 121.221L54.9813 120.061L54.8236 116.384C54.7927 115.656 54.7683 114.924 54.7683 114.187C54.7683 86.4375 77.3434 63.8608 105.095 63.8608C109.588 63.8608 114.008 64.4641 118.301 65.635C118.316 65.6382 118.321 65.635 118.335 65.6382C120.126 66.0985 124.13 66.5522 125.509 62.7436C126.888 58.9382 123.487 57.1397 122.672 56.7299C121.859 56.32 111.092 54.1035 105.095 54.1035C72.2989 54.1035 45.5574 80.5149 45.0191 113.184C17.9475 123.718 -0.000976562 149.749 -0.000976562 179.057C-0.000976562 218.039 31.7151 249.755 70.6987 249.755H253.174C297.487 249.755 333.537 213.705 333.537 169.392C333.537 145.523 322.902 123.097 304.807 107.897Z"
        fill="var(--dhcl-color-primary-dark)"
      />
    </g>
    <path
      d="M170.13 14.4104C170.13 14.4104 170.572 15.1958 171.513 15.8301C171.515 15.8317 171.517 15.8317 171.517 15.8333C171.733 15.978 171.966 16.1179 172.235 16.235L172.265 16.2382C172.873 16.5098 173.54 16.6675 174.247 16.6675C174.99 16.6675 175.626 16.3423 176.317 16.1911C176.347 16.1813 176.373 16.1764 176.4 16.165C176.712 16.0496 177.002 15.9618 177.277 15.8886C187.021 11.9369 197.572 9.75777 208.471 9.75777C227.236 9.75777 244.552 16.0073 258.504 26.5045L258.506 26.4834C258.506 26.4834 258.649 26.6118 258.901 26.7989C258.908 26.8037 258.916 26.8086 258.924 26.8151C260.066 27.6559 263.347 29.6106 266.141 26.7956C269.143 23.7708 266.678 20.7184 265.995 19.985C250.141 7.49083 230.176 0.000488281 208.471 0.000488281C196.175 0.000488281 184.276 2.48697 173.302 6.98833C171.154 7.75427 169.36 9.40488 169.36 11.7792C169.36 12.7061 169.634 13.5647 170.082 14.3047C170.099 14.3388 170.112 14.3746 170.13 14.4104Z"
      fill="var(--dhcl-color-primary-dark)"
    />
    <path
      d="M223.245 136.81C210.2 136.81 200.424 125.812 200.01 125.339C198.237 123.311 198.442 120.229 200.47 118.455C202.493 116.682 205.568 116.886 207.346 118.904C207.507 119.084 214.725 127.053 223.242 127.053C223.349 127.053 223.456 127.051 223.565 127.048C228.27 126.937 232.77 124.251 236.943 119.068C238.634 116.97 241.704 116.637 243.804 118.326C245.901 120.016 246.233 123.088 244.544 125.186C238.466 132.736 231.461 136.644 223.72 136.805C223.562 136.808 223.403 136.81 223.245 136.81Z"
      fill="var(--dhcl-color-primary)"
    />
    <path
      d="M117.542 136.81C104.496 136.81 94.7212 125.812 94.3065 125.339C92.534 123.311 92.7389 120.229 94.7668 118.455C96.7898 116.682 99.8666 116.886 101.642 118.904C101.803 119.084 109.022 127.053 117.539 127.053C117.646 127.053 117.753 127.051 117.862 127.048C122.567 126.937 127.067 124.251 131.239 119.068C132.929 116.97 136.001 116.637 138.1 118.326C140.198 120.016 140.53 123.088 138.84 125.186C132.763 132.736 125.758 136.644 118.017 136.805C117.859 136.808 117.7 136.81 117.542 136.81Z"
      fill="var(--dhcl-color-primary)"
    />
  </svg>
</div>
