<div class="fad-app-container-close-icon" *ngIf="isCernerPage">
  <a href="{{slotUrl}}/my-portal/my-account" class="close cernerCloseBtn"
    ><svg xmlns="http://www.w3.org/2000/svg" x="32px" y="32px" width="32" height="32" viewBox="0 0 50 50">
      <path
        d="M 40.783203 7.2714844 A 2.0002 2.0002 0 0 0 39.386719 7.8867188 L 25.050781 22.222656 L 10.714844 7.8867188 A 2.0002 2.0002 0 0 0 9.2792969 7.2792969 A 2.0002 2.0002 0 0 0 7.8867188 10.714844 L 22.222656 25.050781 L 7.8867188 39.386719 A 2.0002 2.0002 0 1 0 10.714844 42.214844 L 25.050781 27.878906 L 39.386719 42.214844 A 2.0002 2.0002 0 1 0 42.214844 39.386719 L 27.878906 25.050781 L 42.214844 10.714844 A 2.0002 2.0002 0 0 0 40.783203 7.2714844 z"
      ></path>
    </svg>
  </a>
</div>
<div class="fad-app-container" [ngClass]="pageWidthStyling === true ? 'fad-app-container-left-filter': '' ">
  <router-outlet></router-outlet>
</div>
<div class="disclaimer-container fad-col-sm-12" *ngIf="disclaimerVisible">
  <div class="provider-details-container">
    <cs-disclaimer [disclaimer]="disclaimer" [marketDivision]="marketDivision"></cs-disclaimer>
  </div>
</div>
