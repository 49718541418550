<div class="dialog-container" #filterContainer>
  <h3>
    <div
      tabindex="0"
      role="button"
      [attr.aria-label]="'Select ' + filterTitle + ' filter'"
      class="filter-button"
      (click)="showDialogClicked()"
      [ngClass]="{ active: showDialog || showCloseIcon }"
      (mouseenter)="emitHover(true)"
      (mouseleave)="emitHover(false)"
      (keydown.enter)="showDialogClicked()"
      (keydown.space)="showDialogClicked()"
      [attr.aria-expanded]="showDialog"
    >
      {{ filterTitle }}
      <svg
        tabindex="0"
        role="button"
        [attr.aria-label]="'Clear selected ' + filterTitle + ' filter'"
        class="clear-filter"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        (click)="clearFilterContent()"
        (keydown.enter)="clearFilterContent()"
        (keydown.space)="clearFilterContent()"
        *ngIf="showCloseIcon"
        [class.disabled]="isFilterProcessing"
      >
        <path d="M1 1L15 15" stroke="#0071C2" stroke-width="2" />
        <path d="M1 14.998L15 1" stroke="#0071C2" stroke-width="2" />
      </svg>
    </div>
  </h3>
  <div class="filter-dialog" [style.visibility]="showDialog ? 'visible' : 'hidden'">
    <div class="filter-content">
      <ng-content></ng-content>
    </div>
    <hr *ngIf="!hideDialogButtons" class="filter-divider" />
    <div *ngIf="!hideDialogButtons" class="dialog-buttons">
      <div
        tabindex="0"
        role="button"
        [attr.aria-label]="'Clear selected ' + filterTitle + ' filter'"
        class="clear-filter-selection"
        [ngClass]="{ active: resetActive }"
        (click)="clearFilterSelection()"
        [attr.aria-disabled]="!resetActive"
      >
        Clear
      </div>
      <div class="apply-button" (click)="enableFiltering && filterChange()">
        <button [ngClass]="{ 'apply-filter-selection' : true, 'inactive': !enableFiltering}" (blur)="onFocusLost($event)">Apply</button>
      </div>
    </div>
  </div>
</div>
