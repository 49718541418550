import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FacetSummary, FiltersModel, FiltersModelPartialHelper, MedicalGroup } from '../../models/filters';
import { AvailabilitySelect } from '../../models/availability-select.enum';
import { ResizeService } from '../../services/resize.service';
import { SelectedLocation } from '../../models/location';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FilterService } from 'src/app/services/filter.service';
import { clearAllSessionVariables } from 'src/app/utils/utils';
import { SessionObjectNames } from 'src/app/models/stringconst-names';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'cs-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() selectedLocation: SelectedLocation;
  @Input() filtersModel: FiltersModel;

  @Input() medicalGroupToolTip: string;
  @Input() medicalGroupHoverText: string;
  @Input() affiliationToolTip: string;
  @Input() affiliationHoverText: string;
  @Input() defaultSearchDistance: number;
  @Input() defaultSearchDistanceWithMedGroup: number;

  @Input() showOnlineSchedulingFilter: boolean;
  @Input() showOnlineSchedulingAllVisitFilter: boolean;

  @Input() showVideoVisitsFilter: boolean;
  @Input() showAcceptingNewPatientsFilter: boolean;
  @Input() showAvailabilityFilter: boolean;
  @Input() showSpecialtyFilter: boolean;
  @Input() showMedicalGroupFilter: boolean;
  @Input() showAffiliationFilter: boolean;

  @Input() showGenderFilter: boolean;
  @Input() showInsuranceFilter: boolean;
  @Input() showLanguageFilter: boolean;
  @Input() showSearchRadiusFilter: boolean;

  @Input() embedSource: string;
  @Input() multipleMedicalGroupSelection: boolean;
  @Input() showInsuranceAcceptedFilter: boolean;
  @Input() isDignityHealthDivision: boolean;

  @Output() filtersModelChange = new EventEmitter<FiltersModel>();
  @Output() medicalGroupChange = new EventEmitter<FiltersModel>();
  selectedMedicalGroupName = '';
  @Output() addMedicalGroupItems = new EventEmitter<FiltersModel>();
  isHoveringOnMedGroup = false;
  isHoveringOnAffiliation = false;
  showModal = false;
  FiltersModelPartialHelperLocal = FiltersModelPartialHelper;
  closeOnOverlay = true;

  AvailabilitySelect = AvailabilitySelect;

  LastVideoVisitsProvidersCount = 0;
  isDesktopFilterSelected = false;
  useLegacyAPI = false;
  moreFilterText: string;
  moreFilterCount: number;

  get isDesktopViewport() {
    return this.resizeService.isDesktopViewport;
  }

  get displayRadius() {
    return FiltersModelPartialHelper.getDistanceForSearchRadiusKey(this.filtersModel);
  }

  get showCloseIcon() {
    return FiltersModelPartialHelper.isDesktopFiltersSelected(
      this.filtersModel,
      this.selectedLocation,
      this.defaultSearchDistance,
      this.defaultSearchDistanceWithMedGroup,
      this.showSearchRadiusFilter,
      this.showInsuranceFilter
    );
  }

  get isFilterSelected() {
    return FiltersModelPartialHelper.isMoreFiltersSelected(
      this.filtersModel,
      this.selectedLocation,
      this.defaultSearchDistance,
      this.defaultSearchDistanceWithMedGroup,
      this.showSearchRadiusFilter,
      this.showInsuranceFilter,
      this.showMedicalGroupFilter
    );
  }

  get isSelected(){
    return FiltersModelPartialHelper.isMoreFilters(
     this.filtersModel,
     this.selectedLocation,
     this.defaultSearchDistance,
     this.showSearchRadiusFilter,
     this.showInsuranceFilter
    )
  }

  get isAnyFilterSelected() {
    return (
      (this.filtersModel.onlineSchedulingApplied) ||
      (this.filtersModel.videoVisitsApplied) ||
      (this.filtersModel.newPatientsApplied || this.filtersModel.newPatientsToggle) ||
      (this.filtersModel.medicalGroupTitle !== 'Medical group') ||
      (this.filtersModel.specialtyFilterText !== 'Specialty') ||
      (this.filtersModel.genderSelect !== '') ||
      (this.filtersModel.searchRadius !== FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistance)) ||
      (this.filtersModel.selectedLanguages && this.filtersModel.selectedLanguages.length > 0) ||
      (this.filtersModel.selectedInsurances && this.filtersModel.selectedInsurances.length > 0 && this.showInsuranceFilter) ||
      (this.filtersModel.hospitalFilterText !== 'Hospital Affiliation') ||
      (this.filtersModel.availabilitySelect !== AvailabilitySelect.None) ||
      (this.filtersModel.insuranceFilterText !== 'Insurances accepted') ||
      (this.filtersModel.onlineSchedulingAllVisitApplied)
    );
  }

  get showMoreFilters() {
    return this.showGenderFilter || this.showInsuranceFilter || this.showLanguageFilter || this.showSearchRadiusFilter || this.showMedicalGroupFilter;
  }
  get showMobileFilters() {
    return (
      this.showOnlineSchedulingFilter ||
      this.showVideoVisitsFilter ||
      this.showAcceptingNewPatientsFilter ||
      this.showAvailabilityFilter ||
      this.showSpecialtyFilter ||
      this.showAffiliationFilter ||
      this.showMedicalGroupFilter ||
      this.showGenderFilter ||
      this.showInsuranceFilter ||
      this.showLanguageFilter ||
      this.showSearchRadiusFilter ||
      this.showOnlineSchedulingAllVisitFilter
    );
  }

  get alignTop() {
    return this.embedSource != null && (this.embedSource === 'cerner' || this.embedSource === 'mychart') ? true : false;
  }

  constructor(private resizeService: ResizeService, private configurationService: ConfigurationService
    , private filterService: FilterService, private route: ActivatedRoute) {
    this.useLegacyAPI = this.configurationService.useLegacyAPI()
  }

  ngOnInit(): void {
    if (this.filtersModel) {
      this.filtersModel.medicalGroupList = FiltersModelPartialHelper.getMedGroups(this.configurationService.getMedGroupCodes());

      if (this.filtersModel.selectedMedicalGroup == null) {
        this.filtersModel.selectedMedicalGroup = [];
        this.filtersModel.providerMedicalGroup = [];
      } else {
        this.selectedMedicalGroupName = this.getSelectedMedgroup();
      }
    }
    this.getMoreFiltersCount();
  }

  ngAfterViewInit(): void {
    this.isDesktopFilterSelected =
      ((this.filtersModel.onlineSchedulingProvidersCount > 0 && !this.filtersModel.onlineSchedulingApplied) ||
        (this.filtersModel.videoVisitsProvidersCount > 0 && !this.filtersModel.videoVisitsApplied) ||
        (this.filtersModel.newPatientsProvidersCount > 0 && (this.filtersModel.newPatientsApplied || this.filtersModel.newPatientsToggle)) ||
        (this.filtersModel.providerMedicalGroup && this.filtersModel.providerMedicalGroup.length > 0) ||
        (this.filtersModel.providerSpecialties && this.filtersModel.providerSpecialties.length > 0) ||
        (this.filtersModel.genderSelect) ||
        (this.filtersModel.searchRadius !== this.defaultSearchDistance) ||
        (this.filtersModel.providerLanguages.length > 0) ||
        (this.filtersModel.onlineSchedulingAllVisitProvidersCount > 0 && !this.filtersModel.onlineSchedulingAllVisitApplied)
      ) ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filtersModel.currentValue.searchDone) {
      this.LastVideoVisitsProvidersCount = 0;
    }
  }

  applyFilterMessage(filterMessage: string, canFilter: boolean): string {
    if (this.isDignityHealthDivision) {
      return canFilter ? `Only show providers who ${filterMessage}` : `This search contains no providers ${filterMessage}`;
    }
    else {
      return canFilter ? `Only show providers that ${filterMessage}` : `This search contains no providers that ${filterMessage}`;
    }


  }

  applyFilterMessageOnlineSchedulingAllVisit(filterMessage: string, canFilter: boolean): string {

    return canFilter ? `Show all providers who ${filterMessage}` : `This search contains no providers ${filterMessage}`;
  }

  filterOnlineScheduling(filteringActive: boolean): void {
    if (filteringActive) {
      this.filtersModel.onlineSchedulingApplied = this.filtersModel.onlineSchedulingToggle;
    } else {
      this.filtersModel.onlineSchedulingApplied = this.filtersModel.onlineSchedulingToggle = false;
    }

    this.filtersModelChange.emit(this.filtersModel);
  }

  filterOnlineSchedulingAllVisit(filteringActive: boolean): void {
    if (filteringActive) {
      this.filtersModel.onlineSchedulingAllVisitApplied = this.filtersModel.onlineSchedulingAllVisitToggle;
    } else {
      this.filtersModel.onlineSchedulingAllVisitApplied = this.filtersModel.onlineSchedulingAllVisitToggle = false;
    }

    this.filtersModelChange.emit(this.filtersModel);
  }

  filterVideoVisits(filteringActive: boolean) {
    this.LastVideoVisitsProvidersCount = this.filtersModel.videoVisitsProvidersCount;
    this.filtersModel.videoVisitsApplied = filteringActive
      ? this.filtersModel.videoVisitsToggle : false;
    if (!filteringActive) {
      this.filtersModel.videoVisitsToggle = false
    }
    this.filtersModelChange.emit(this.filtersModel);
  }

  filterNewPatients(filteringActive: boolean) {
    this.filtersModel.newPatientsApplied = filteringActive
      ? this.filtersModel.newPatientsToggle : false;
    if (!filteringActive) {
      this.filtersModel.newPatientsToggle = false
    }
    this.filtersModelChange.emit(this.filtersModel);
  }

  filterAvailability(filteringActive: boolean) {
    if (filteringActive) {
      this.filtersModel.providerAvailability = this.filtersModel.availabilitySelect;
      this.filtersModel.availabilityTitle = this.filtersModel.providerAvailability;

      if (this.filtersModel.availabilityTitle === '') {
        this.filtersModel.availabilityTitle = 'Availability';
      }
    } else {
      this.filtersModel.providerAvailability = AvailabilitySelect.None;
      this.filtersModel.availabilitySelect = AvailabilitySelect.None;
      this.filtersModel.availabilityTitle = 'Availability';
    }

    this.filtersModelChange.emit(this.filtersModel);
  }

  filterSpecialties(filteringActive: boolean) {
    if (!filteringActive) {
      this.filtersModel.selectedSpecialties = [];
      this.filtersModel.providerSpecialties = [];
      this.filtersModel.specialtyFilterText = 'Specialty';
      window.sessionStorage.removeItem('singleSpecialty');
      window.sessionStorage.removeItem(SessionObjectNames.SingleSpecialityText);
    } else {
      if (this.filtersModel.selectedSpecialties.length > 0) {
        this.filtersModel.providerSpecialties = [...this.filtersModel.selectedSpecialties];

        this.filtersModel.specialtyFilterText =
          this.filtersModel.providerSpecialties.length > 1
            ? `${this.filtersModel.providerSpecialties.length} specialties`
            : this.filtersModel.providerSpecialties[0].name;
      } else {
        this.filtersModel.selectedSpecialties = [];
        this.filtersModel.providerSpecialties = [];
        this.filtersModel.specialtyFilterText = 'Specialty';
      }
    }
    if (this.filtersModel?.selectedSpecialties?.length === 1) {
      if (
        window.sessionStorage.getItem('singleSpecialty')?.toLowerCase() !== this.filtersModel?.selectedSpecialties?.[0].name.toLowerCase()
      )
        this.filtersModelChange.emit(this.filtersModel);
    } else {
      window.sessionStorage.removeItem('singleSpecialty');
      this.filtersModelChange.emit(this.filtersModel);
    }
  }

  filterMedicalGroups(filteringActive: boolean) {
    if (!filteringActive) {
      this.filtersModel.selectedMedicalGroup = [];
      this.filtersModel.providerMedicalGroup = [];
      this.filtersModel.medicalGroupTitle = 'Medical group';
      window.sessionStorage.removeItem('singleMedGroup');
      this.medicalGroupChange.emit(this.filtersModel);
    } else {
      if (this.filtersModel.selectedMedicalGroup.length > 0) {
        this.filterService.handleManualProviderRoleSelectionProcess(this.filtersModel);
        this.filtersModel.providerMedicalGroup = [...this.filtersModel.selectedMedicalGroup];

        this.filtersModel.medicalGroupTitle =
          this.filtersModel.providerMedicalGroup.length === 1
            ? this.filtersModel.providerMedicalGroup[0].name
            : `${this.filtersModel.providerMedicalGroup.length} Medical groups`;
      } else {
        this.filtersModel.selectedMedicalGroup = [];
        this.filtersModel.providerMedicalGroup = [];
        this.filtersModel.medicalGroupTitle = 'Medical group';
      }
      this.medicalGroupChange.emit(this.filtersModel);
    }
  }
  clearMedGroupFilters() {
    this.filtersModel.selectedMedicalGroup = [];
    this.selectedMedicalGroupName = null;
    this.filtersModel.medicalGroupList = [];
    this.filtersModel.medicalGroupTitle = 'Medical group';
    this.addMedicalGroupItems.emit(this.filtersModel);
  }

  filterLanguages(filteringActive: boolean) {
    if (!filteringActive) {
      this.filtersModel.selectedLanguages = [];
      this.filtersModel.providerLanguages = [];
    } else {
      this.filtersModel.selectedLanguages = [...this.filtersModel.selectedLanguages];
      this.filtersModel.providerLanguages = [...this.filtersModel.selectedLanguages];
    }
    this.filtersModelChange.emit(this.filtersModel);
  }

  filterInsurances(filteringActive: boolean) {
    if (!filteringActive) {
      this.filtersModel.selectedInsurances = [];
      this.filtersModel.providerInsurances = [];
      this.filtersModel.insuranceFilterText = 'Insurances accepted';
      window.sessionStorage.removeItem('singleInsurances');
      window.sessionStorage.removeItem(SessionObjectNames.SingleInsurancesText);
    } else {
      if (this.filtersModel.selectedInsurances.length > 0) {
        this.filtersModel.providerInsurances = [...this.filtersModel.selectedInsurances];

        this.filtersModel.insuranceFilterText =
          this.filtersModel.providerInsurances.length > 1
            ? `${this.filtersModel.providerInsurances.length} Insurances accepted`
            : this.filtersModel.providerInsurances[0].name;
      } else {
        this.filtersModel.selectedInsurances = [];
        this.filtersModel.providerInsurances = [];
        this.filtersModel.insuranceFilterText = 'Insurances accepted';
      }
    }
    if (this.filtersModel?.selectedInsurances?.length === 1) {
      if (
        window.sessionStorage.getItem('singleInsurances')?.toLowerCase() !== this.filtersModel?.selectedInsurances?.[0].name.toLowerCase()
      )
        this.filtersModelChange.emit(this.filtersModel);
    } else {
      window.sessionStorage.removeItem('singleInsurances');
      this.filtersModelChange.emit(this.filtersModel);
    }
  }

  filterHospitals(filteringActive: boolean) {
    if (!filteringActive) {
      this.filtersModel.selectedHospitals = [];
      this.filtersModel.providerHospitals = [];
      this.filtersModel.hospitalFilterText = 'Hospital Affiliation';
    } else {
      if (this.filtersModel.selectedHospitals.length > 0) {
        this.filtersModel.providerHospitals = [...this.filtersModel.selectedHospitals];

        this.filtersModel.hospitalFilterText =
          this.filtersModel.providerHospitals.length > 1
            ? `${this.filtersModel.providerHospitals.length} hospital affiliations`
            : this.filtersModel.providerHospitals[0].name;
      } else {
        this.filtersModel.selectedHospitals = [];
        this.filtersModel.providerHospitals = [];
        this.filtersModel.hospitalFilterText = 'Hospital Affiliation';
      }
    }

    this.filtersModelChange.emit(this.filtersModel);
  }

  toggleSpecialty(specialty: any) {
    FiltersModelPartialHelper.SpecialityToggle(specialty, this.filtersModel);
  }

  toggleMedgroup(medgroup: any) {
    FiltersModelPartialHelper.MedgroupToggle(medgroup, this.filtersModel);
  }

  checkSpecialty(specialty: FacetSummary): boolean {
    return this.filtersModel?.selectedSpecialties?.map((n) => n.name?.toLowerCase()).includes(specialty.name?.toLowerCase()) ?? false;
  }

  checkMedgroup(medgroup: any): boolean {
    return this.filtersModel?.selectedMedicalGroup?.map((n) => n.name?.toLowerCase()).includes(medgroup.label?.toLowerCase()) ?? false;
  }

  selectMedGroup(medGroup: any) {
    this.filtersModel.selectedMedicalGroup = [];
    const tempGroup: MedicalGroup = medGroup as unknown as MedicalGroup;
    const selectedMedgroup: FacetSummary = {
      name: tempGroup.label,
      count: 0,
      selected: true
    };
    this.filtersModel.selectedMedicalGroup.push(selectedMedgroup);
    this.selectedMedicalGroupName = selectedMedgroup.name;
  }

  toggleHospital(hospital: any) {
    FiltersModelPartialHelper.HospitalToggle(hospital, this.filtersModel);
  }

  checkHospital(hospital: FacetSummary): boolean {
    return this.filtersModel?.selectedHospitals?.map((n) => n.name?.toLowerCase()).includes(hospital.name?.toLowerCase()) ?? false;
  }

  isArray(value: any): boolean {
    return (!!value) && (value.constructor === Array);
  }

  getSelectedMedgroup(): string {
    let selectedGroupName = '';
    if (!this.isArray(this.filtersModel.selectedMedicalGroup)) {
      selectedGroupName = this.filtersModel.selectedMedicalGroup ? (this.filtersModel.selectedMedicalGroup as unknown as MedicalGroup).label : null;
    } else {
      selectedGroupName = this.filtersModel.selectedMedicalGroup.length > 0 ? this.filtersModel.selectedMedicalGroup[0].name : null;
    }
    return selectedGroupName;
  }

  filterMedicalGroup(filteringActive: boolean) {
    if (filteringActive && this.filtersModel.selectedMedicalGroup) {
      if (!this.isArray(this.filtersModel.selectedMedicalGroup)) {
        const medGroups: FacetSummary[] = [];
        const tempGroup: MedicalGroup = this.filtersModel.selectedMedicalGroup as unknown as MedicalGroup;
        const selectedMedgroup: FacetSummary = {
          name: tempGroup.label,
          count: 0,
          selected: true
        };
        medGroups.push(selectedMedgroup);
        this.filtersModel.selectedMedicalGroup = medGroups;
      }

      if (this.filtersModel.selectedMedicalGroup.length === 0) {
        this.filtersModel.providerMedicalGroup = [];
        this.filtersModel.medicalGroupTitle = 'Medical group';
      } else {
        this.filtersModel.providerMedicalGroup = this.filtersModel.selectedMedicalGroup;
        this.selectedMedicalGroupName = this.getSelectedMedgroup();
      }

      this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius = FiltersModelPartialHelper.getRadiusKeyForDistance(
        this.defaultSearchDistanceWithMedGroup
      );
      this.medicalGroupChange.emit(this.filtersModel);
    } else {
      this.filtersModel.selectedMedicalGroup = [];
      this.filtersModel.providerMedicalGroup = [];
      this.selectedMedicalGroupName = null;
      if (
        this.filtersModel.providerSearchRadius === FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistanceWithMedGroup)
      ) {
        this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius = FiltersModelPartialHelper.getRadiusKeyForDistance(
          this.defaultSearchDistance
        );
      }
      this.filtersModel.medicalGroupTitle = 'Medical group';
      this.addMedicalGroupItems.emit(this.filtersModel);
      this.medicalGroupChange.emit(this.filtersModel);
    }
  }

  selectGender(gender: string) {
    FiltersModelPartialHelper.genderSelect(this.filtersModel, gender);
  }

  filterGender(filteringActive: boolean) {
    if (filteringActive) {
      this.filtersModel.providerGender = this.filtersModel.genderSelect;
    } else {
      this.filtersModel.providerGender = '';
      this.filtersModel.genderSelect = '';
    }

    this.filtersModelChange.emit(this.filtersModel);
  }

  filterSearchRadius(filteringActive: boolean) {
    if (filteringActive) {
      this.filtersModel.providerSearchRadius = FiltersModelPartialHelper.getRadiusKeyForDistance(this.filtersModel.searchRadius);
    } else {

      this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius = FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistance);
    }
    this.filtersModelChange.emit(this.filtersModel);
  }

  applyFilters() {
    if (this.isDignityHealthDivision) {
      this.moreFilterCount = this.filtersModel.selectedMedicalGroup.length > 0 ? 1 : 0;
      this.moreFilterCount += this.filtersModel.selectedLanguages.length > 0 ? 1 : 0;
      this.moreFilterCount += this.filtersModel.genderSelect.length > 0 ? 1 : 0;
      if(this.filtersModel.searchRadius !==FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistance)){
        this.moreFilterCount++;
      }
      this.moreFilterText = this.moreFilterCount != 0 ? '(' + this.moreFilterCount.toString() + ' selected)' : '';
    }
    this.filtersModel.providerGender = this.filtersModel.genderSelect;
    this.filtersModel.providerInsurances = [...this.filtersModel.selectedInsurances];
    this.filtersModel.providerLanguages = [...this.filtersModel.selectedLanguages];
    this.filtersModel.providerMedicalGroup = [...this.filtersModel.selectedMedicalGroup];
    this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius;

    this.showModal = false;

    this.filtersModelChange.emit(this.filtersModel);
  }

  clearFilters(event: any = null) {
    window.sessionStorage.removeItem('singleSpecialty');
    if(this.isDignityHealthDivision){
    this.selectedMedicalGroupName = 'Select Medical group';
    }
    if (event) {
      event.stopPropagation();
      this.moreFilterCount = 0;
      sessionStorage.removeItem('moreFilters');
      sessionStorage.removeItem('moreFiltersCount');
      FiltersModelPartialHelper.toClearFilters(this.filtersModel, true, this.defaultSearchDistance, false, this.isDignityHealthDivision);

      this.filtersModelChange.emit(this.filtersModel);
    } else {
      FiltersModelPartialHelper.toClearFilters(this.filtersModel, false, this.defaultSearchDistance, false, this.isDignityHealthDivision);

      this.filtersModel.searchRadius = this.filtersModel.selectedMedicalGroup.length > 0
        ? FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistanceWithMedGroup)
        : FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistance);
    }
  }

  handleFilterChange(event: any) {
    this.filtersModel = event;
    if (this.filtersModel.selectedMedicalGroup
      !== JSON.parse(sessionStorage.getItem('SelectedMedGroupFiltersModel'))) {

      sessionStorage.setItem('SelectedMedGroupFiltersModel', JSON.stringify(this.filtersModel.selectedMedicalGroup));
      this.filterService.handleManualProviderRoleSelectionProcess(this.filtersModel);
    }
    this.filtersModelChange.emit(this.filtersModel);
  }

  moreFiltersClicked() {
    this.showModal = true;

    if (this.filtersModel) {
      this.filtersModel.selectedInsurances = [...this.filtersModel.selectedInsurances];
      this.filtersModel.selectedLanguages = [...this.filtersModel.selectedLanguages];
    }
  }

  initializeSpecialtyDialog() {
    if (this.filtersModel) {
      this.filtersModel.selectedSpecialties = [...this.filtersModel.selectedSpecialties];
    }
  }
  initializeMedicalGroupsDialog() {
    if (this.filtersModel) {
      if (this.filtersModel.selectedMedicalGroup == null) {
        this.filtersModel.selectedMedicalGroup = [];
        this.filtersModel.providerMedicalGroup = [];
      }
      this.filtersModel.selectedMedicalGroup = [...this.filtersModel.selectedMedicalGroup];
    }
  }

  initializeLanguageDialog() {
    if (this.filtersModel) {
      this.filtersModel.selectedLanguages = [...this.filtersModel.selectedLanguages];
    }
  }

  toggleLanguage(language: any) {
    FiltersModelPartialHelper.LanguageToggle(language, this.filtersModel);
  }

  checkLanguage(language: any): boolean {
    return this.filtersModel?.selectedLanguages?.map((n) => n.name?.toLowerCase()).includes(language?.name.toLowerCase()) ?? false;
  }

  initializeAffiliationsDialog() {
    if (this.filtersModel) {
      this.filtersModel.selectedHospitals = [...this.filtersModel.selectedHospitals];
    }
  }

  clearDesktopFilters() {
    this.moreFilterCount = 0;
    sessionStorage.removeItem('moreFilters');
    sessionStorage.removeItem('moreFiltersCount');
    clearAllSessionVariables();
    this.clearMedGroupFilters();
    FiltersModelPartialHelper.clearDesktopFilters(this.filtersModel, FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistance));
    this.filtersModelChange.emit(this.filtersModel);
  }

  specialtyReset(): void {
    window.sessionStorage.removeItem(SessionObjectNames.SingleSpecialityText);
    this.filtersModel.selectedSpecialties = [];
  }

  closeFilterDialog(): void {
    this.filterService.handleDialogDisplay(false);
  }

  insuranceReset(): void {
    window.sessionStorage.removeItem(SessionObjectNames.SingleInsurancesText);
    this.filtersModel.selectedInsurances = [];
  }

  initializeInsurancesDialog() {
    if (this.filtersModel) {
      this.filtersModel.selectedInsurances = [...this.filtersModel.selectedInsurances];
    }
  }
  toggleInsurance(insurance: any) {
    FiltersModelPartialHelper.InsuranceToggle(insurance, this.filtersModel);
  }

  checkInsurance(insurance: FacetSummary): boolean {
    return this.filtersModel?.selectedInsurances?.map((n) => n.name?.toLowerCase()).includes(insurance.name?.toLowerCase()) ?? false;
  }

  getMoreFiltersCount() {
    if (this.isDignityHealthDivision) {
      const params = this.route.snapshot.queryParams;
      this.selectedMedicalGroupName = this.route.snapshot.paramMap.get("medGroup") ? this.configurationService.getMedGroupCodes().filter(x => x.code === this.route.snapshot.paramMap.get("medGroup"))[0].title : null;
      this.moreFilterCount = params.languages ? 1 : 0;
      this.moreFilterCount += params.gender ? 1 : 0;
      this.moreFilterCount += this.selectedMedicalGroupName ? 1 : 0;
      this.moreFilterText = this.moreFilterCount != 0 ? '(' + this.moreFilterCount.toString() + ' selected)' : null;
    }
  }
}
