import { Component, Input,OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ERLinkCategories } from 'src/app/models/configuration';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'cs-emergency-care',
  templateUrl: './emergency-care.component.html',
  styleUrls: ['./emergency-care.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmergencyCareComponent implements OnInit {
  @Input() emergencyCareLinkContent: ERLinkCategories[] = [];
  @Input() emergencyCopy: string;
  @Input() emergencyNumber: string;
  @Input() showEmergencyLinkContent: boolean;
  @Input() showEmergencyCopy: boolean;
  isCernerPage: boolean;
  constructor(
    private configurationService: ConfigurationService,
  ) {}
 
  ngOnInit(): void {
  this.isCernerPage=this.configurationService.getCernerPage();
  }
}
