import { Injectable } from '@angular/core';
import { Provider } from '../models/provider';
import { TealiumJsUtagService } from './tealium-js-utag.service';
import { RuntimeConfiguration } from './configuration-injection.service';
import { TealiumSearchFilter } from '../models/search-filter';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class TealiumService {
  tenantCode: string;

  constructor(private tealium: TealiumJsUtagService, private configurationService: ConfigurationService) {}

  init(runtimeConfig: RuntimeConfiguration): void {
    this.tenantCode = this.configurationService.getTenantCode();
    this.tealium.addScriptTag(runtimeConfig);
  }

  public link(data?: any): void {
    this.tealium.link(data);
  }

  private view(provider: Provider, tealiumEvent: string) {
    this.sendTealiumView({
      tealium_event: tealiumEvent,
      page: {
        name: `${provider.displayFullName}`,
        section: 'Search and Schedule',
        system: `${this.tenantCode}`,
        division: `${provider.divisionCodes[0]}`,
        facility: `${provider.officeNames}`,
        medicalGroup: `${provider.medicalGroupNames.join(', ')}`,
        serviceLine: `${provider.primarySpecialty}`,
        market: `${provider.marketCodes[0]}`,
        doctor_name: `${provider.displayFullName}`,
        doctor_NPI: `${provider.npi}`
      },
      site: {
        language: `${provider.languages}`
      },
      visitor: {
        loginStatus: ''
      },
      patientEngagement: 'false'
    });
  }

  providerProfileViewed(provider: Provider): void {
    this.view(provider, 'search_schedule:provider_profile_view');
  }

  providerVideoViewed(provider: Provider): void {
    this.view(provider, 'search_schedule:youtube_open');
  }

  searchResults(tealiumSearchFilter: TealiumSearchFilter): void {
    this.sendTealiumView({
      tealium_event: `${tealiumSearchFilter.tealiumEventName}`,
      page: {
        name: ``,
        section: 'Search and Schedule',
        system: `${this.tenantCode}`,
        division: ``,
        facility: `${tealiumSearchFilter.facility}`,
        medicalGroup: `${tealiumSearchFilter.medicalGroup}`,
        serviceLine: `${tealiumSearchFilter.serviceLine}`,
        market: ``,
        subMarket: ``,
        url: `${tealiumSearchFilter.searchURL}`
      },
      site: {
        language: `en`
      },
      visitor: {
        loginStatus: ``
      },
      search: {
        schedule_filter_details: `${tealiumSearchFilter.scheduleFilterDetails}`,
        schedule_location: `${tealiumSearchFilter.scheduleLocation}`,
        schedule_book_online: `${tealiumSearchFilter.scheduleBookOnline}`,
        schedule_keyword: `${tealiumSearchFilter.scheduleKeyword}`,
        schedule_results_count: `${tealiumSearchFilter.totalResultsCount}`
      },
      patientEngagement: `false`
    });
  }

  providersImpressions(providers: Provider[]) {
    const data = {
      tealium_event: 'search and schedule:provider detail:impressions',
      event_category: 'search and schedule',
      event_action: 'providers impression',
      event_label: 'rating and comments',
      doctor_name_list: providers.map((p) => p.displayFullName),
      doctor_npi_list: providers.map((p) => p.npi),
      doctor_starRating_list: providers.map((p) => (p.rating !== undefined ? p.rating?.overallRating.value : 'not set')),
      doctor_ratingCount_list: providers.map((p) => (p.rating !== undefined ? p.rating?.totalRatingCount : 'not set')),
      doctor_commentsCount_list: providers.map((p) => (p.rating !== undefined ? p.rating?.totalCommentCount : 'not set')),
      patientEngagement: 'false',
      visitor_loginStatus: ''
    };
    this.link(data);
  }

  providerRatingsAndComments(provider: Provider): void {
    const data = {
      tealium_event: 'search and schedule:provider detail:profile view',
      event_category: 'search and schedule',
      event_action: 'provider profile view',
      event_label: 'rating and comments',
      event_doctor_name: `${provider.displayFullName}`,
      event_doctor_npi: `${provider.npi}`,
      event_doctor_hasStarRating: `${provider.rating?.totalRatingCount === 0 ? false : true}`,
      event_doctor_starRating: `${provider.rating !== undefined ? provider.rating?.overallRating.value : 'not set'}`,
      event_doctor_starRatingCount: `${provider.rating !== undefined ? provider.rating?.totalRatingCount : 'not set'}`,
      event_doctor_hasComments: `${provider.rating?.totalCommentCount === 0 ? false : true}`,
      event_doctor_commentsCount: `${provider.rating !== undefined ? provider.rating?.totalCommentCount : 'not set'}`,
      patientEngagement: 'false',
      visitor_loginStatus: ''
    };
    this.link(data);
  }

  private sendTealiumView(data?: any): void {
    this.tealium.view(data);
  }
}
