import { Component, Input } from '@angular/core';
import { FiltersModel } from '../../models/filters';
import { AvailabilitySelect } from '../../models/availability-select.enum';

@Component({
  selector: 'cs-availability-dialog',
  templateUrl: './availability-dialog.component.html',
  styleUrls: ['./availability-dialog.component.scss']
})
export class AvailabilityDialogComponent {
  @Input() filtersModel: FiltersModel;
  AvailabilitySelect = AvailabilitySelect;

  selectAvailability(availability: AvailabilitySelect) {
    if (this.filtersModel.availabilitySelect === availability) {
      this.filtersModel.availabilitySelect = AvailabilitySelect.None;
    } else {
      this.filtersModel.availabilitySelect = availability;
    }
  }
}
