import { Observable, of } from 'rxjs';

export class ApiResponseCreator {
  public static create<T>(object: T, isValid = true): ApiResponse<T> {
    return {
      result: object,
      isValid
    } as ApiResponse<T>;
  }

  public static createObservable<T>(object: T, isValid = true): Observable<ApiResponse<T>> {
    return of(ApiResponseCreator.create(object, isValid));
  }
}

export interface ApiResponse<T> {
  errors: string[];
  isValid: boolean;
  warnings: string[];
  result: T;
}
