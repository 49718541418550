import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CarePhilosophyComponent } from 'src/app/components/care-philosophy/care-philosophy.component';
import { CredentialsComponent } from 'src/app/components/credentials/credentials.component';
import { DocAsapBookingLauncherModule } from 'src/app/components/doc-asap-booking-launcher/doc-asap-booking-launcher.module';
import { ErrorPageComponent } from 'src/app/components/error-page/error-page.component';
import { GetToKnowComponent } from 'src/app/components/get-to-know/get-to-know.component';
import { GoogleMapsComponent } from 'src/app/components/google-maps/google-maps.component';
import { LocationsListComponent } from 'src/app/components/locations-list/locations-list.component';
import { OwnWordsComponent } from 'src/app/components/own-words/own-words.component';
import { PatientAgeRestrictionsModule } from 'src/app/components/patient-age-restrictions/patient-age-restrictions.module';
import { PersonalInfoComponent } from 'src/app/components/personal-info/personal-info.component';
import { PrintPageComponent } from 'src/app/components/print-page/print-page.component';
import { ProviderCommentsComponent } from 'src/app/components/provider-comments/provider-comments.component';
import { ProviderHeroComponent } from 'src/app/components/provider-hero/provider-hero.component';
import { ProviderVideoComponent } from 'src/app/components/provider-video/provider-video.component';
import { ScheduleAnAppointmentComponent } from 'src/app/components/schedule-an-appointment/schedule-an-appointment.component';
import { SharedModule } from 'src/app/components/shared.module';
import { SuggestAnEditComponent } from 'src/app/components/suggest-an-edit/suggest-an-edit.component';
import { YouTubePlayerComponent } from 'src/app/components/you-tube-player/you-tube-player.component';
import { ProviderDetailsResolver } from '../../resolvers/provider-details.resolver';
import { ProviderDetailsComponent } from './provider-details.component';
import { ProviderDetailsCardComponent } from 'src/app/components/provider-details-card/provider-details-card.component';

@NgModule({
  declarations: [ProviderDetailsComponent, 
    ProviderHeroComponent,
    ScheduleAnAppointmentComponent,
    LocationsListComponent,
    GetToKnowComponent,
    CredentialsComponent,
    ProviderCommentsComponent,
    ErrorPageComponent, 
    CarePhilosophyComponent,
    PersonalInfoComponent,
    OwnWordsComponent,
    SuggestAnEditComponent,
    GoogleMapsComponent,
    ProviderVideoComponent,
    YouTubePlayerComponent,
    PrintPageComponent,
    ProviderDetailsCardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    YouTubePlayerModule,
    GoogleMapsModule,
    PatientAgeRestrictionsModule,
    DocAsapBookingLauncherModule,
    RouterModule.forChild([
      {
        path: '',
        component: ProviderDetailsComponent
      }
    ])
  ],
  providers: [ProviderDetailsResolver],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProviderDetailsModule {}
