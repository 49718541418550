import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'cs-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollToTopComponent implements OnInit {
  @Input() windowScrolled = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.windowScrolled = window.pageYOffset > 400;
  }

  get isMyChart(): boolean {
    return this.configurationService.getEmbedSource() != null && this.configurationService.getEmbedSource() === 'mychart';
  }

  get isCerner(): boolean {
    return this.configurationService.getEmbedSource() != null && this.configurationService.getEmbedSource() === 'cerner';
  }

  constructor(private configurationService: ConfigurationService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.isMyChart || this.isCerner) {
      this.windowScrolled = true;
      const parentTop = window.parent.scrollY + 270;
      document.getElementById('scrollTopDiv').style.opacity = '1';
      document.getElementById('scrollTopDiv').style.top = parentTop.toString() + 'px';
      window.parent.addEventListener('scroll', this.checkScrolling.bind(this), true);
    }
  }

  checkScrolling(): void {
    const parentTop = window.parent.scrollY + 270;
    document.getElementById('scrollTopDiv').style.top = parentTop.toString() + 'px';
    this.changeDetector.detectChanges();
  }

  scrollToTop(): void {
    if (this.isMyChart || this.isCerner) {
      window.parent.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}