import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'cs-slide-toggle',
  templateUrl: 'ang-slide-toggle.component.html',
  styleUrls: ['ang-slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AngSlideToggleComponent {
  @Input() barCheckedBgColor;
  @Input() sliderCheckedColor;
  @Input() barUncheckedBgColor;
  @Input() sliderUncheckedColor;
  @Input() barDisabledBgColor;
  @Input() sliderDisabledColor;
  @Input() checked;
  @Input() disabled: boolean;
  @Output() contentClosed = new EventEmitter<any>();

  onFocusLost(event: FocusEvent | any) {
    this.contentClosed.emit();
  }

  switchToggle(): void{
    if(!this.disabled){
      this.checked = !this.checked;
    }
  }
}
