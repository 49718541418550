import { Component, Input } from '@angular/core';
import { FiltersModel, FiltersModelPartialHelper } from '../../models/filters';

@Component({
  selector: 'cs-common-more-filters',
  templateUrl: './common-more-filters.component.html',
  styleUrls: ['./common-more-filters.component.scss']
})
export class CommonMoreFiltersComponent {
  @Input() filtersModel: FiltersModel;

  @Input() showInsuranceFilter: boolean;
  @Input() showLanguageFilter: boolean;

  toggleInsurance(insurance: any) {
    FiltersModelPartialHelper.InsuranceToggle(insurance, this.filtersModel);
  }

  checkInsurance(insurance: any) {
    return this.filtersModel.selectedInsurances.map((n) => n.name).includes(insurance.name);
  }

  toggleLanguage(language: any) {
    FiltersModelPartialHelper.LanguageToggle(language, this.filtersModel);
  }

  checkLanguage(language: any) {
    return this.filtersModel.selectedLanguages.map((n) => n.name).includes(language.name);
  }
}
