import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cs-my-providers',
  templateUrl: './my-providers.component.html',
  styleUrls: ['./my-providers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyProvidersComponent {
  @Input() myProvidersLinkContent: string;
  @Input() showRegionalHelplineNumbers: boolean;
}
