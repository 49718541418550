import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ApiConstants } from 'src/app/constants/api-constants.new';
import { Provider } from '../../models/provider';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'cs-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgesComponent implements OnInit {
  @Input() provider: Provider;
  @Input() isPrint = false;
  @Input() isUpdatedProviderDetails = false;
  showOnlineScheduleBadge: boolean;
  divisionCodes: string[];
  showImprovedUX: boolean;
  constructor(private configurationService: ConfigurationService) {}

  ngOnInit(): void {
   this.showImprovedUX = this.configurationService.showImprovedUX();
  }


  setOnlineScheduleBadge() {
    this.divisionCodes = this.configurationService.getDivisionCodes();
    if (this.divisionCodes.length > 0 && this.divisionCodes[0].toString() === 'Texas') {
      this.showOnlineScheduleBadge =
        this.provider?.isBookOnline && Boolean(this.provider?.providerSchedulingId) && Boolean(this.provider?.ehrCode);
    } 
    else if (this.divisionCodes.length > 0 && this.divisionCodes[0].toString() === 'Southeast') {
      this.showOnlineScheduleBadge =
      this.provider?.isBookOnline && Boolean(typeof this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsDepartmentId) !== 'undefined') && Boolean(typeof this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsProviderId) !== 'undefined');
    }
    else if (this.divisionCodes.length > 0 && this.divisionCodes[0].toString() === 'dignity-health') {
      this.showOnlineScheduleBadge =
        this.provider?.onlineBookingAvailability
        && this.provider?.onlineBookingAvailability[0]?.appointmentTypes
        && Boolean(typeof this.provider?.onlineBookingAvailability[0]?.appointmentTypes?.find(({ name }) => name.toLowerCase() == 'online scheduling for new patients' || 'online scheduling for all visits' || 'online scheduling for video visits') !== 'undefined')
        && Boolean(typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsBlockIt) !== 'undefined');
    }
    else {
        this.showOnlineScheduleBadge =
        this.provider.onlineBookingAvailability && this.provider.onlineBookingAvailability[0]?.supportsOnlineBooking;
    }
    return this.showOnlineScheduleBadge;
  }

  setAcceptNewPatientBadge() {
    this.divisionCodes = this.configurationService.getDivisionCodes();
    let showAcceptNewPatientLabel = false;
    if (this.divisionCodes.length > 0 && this.divisionCodes[0].toString() === 'dignity-health') {
      showAcceptNewPatientLabel =
      this.provider?.onlineBookingAvailability 
      && this.provider?.onlineBookingAvailability[0]?.appointmentTypes 
      && Boolean(typeof this.provider?.onlineBookingAvailability[0]?.appointmentTypes?.find(({name}) => name.toLowerCase() == 'online scheduling for new patients') !== 'undefined');
      
    }
    
    return showAcceptNewPatientLabel;
  }
}
