<div class="search-box-module our-doctors">
  <div class="search-box-heading" *ngIf="hideHeaderForVMFHAEMMobileView">
    <h1 class="heading-text">{{ landingPageHeader }}</h1>
  </div>
  <div>
    <cs-legal-copy
      *ngIf="showLegalCopy"
      [legalCopy]="legalCopy"
      [legalCopyToolTip]="legalCopyToolTip"
      [legalCopyToolTipInfo]="legalCopyToolTipInfo"
    ></cs-legal-copy>
    <cs-fad-lite-search-input></cs-fad-lite-search-input>
    <cs-emergency-care
      *ngIf="showEmergencyCare || showEmergencyCopy"
      [showEmergencyLinkContent]="showEmergencyCare"
      [showEmergencyCopy]="showEmergencyCopy"
      [emergencyCareLinkContent]="emergencyCareLinkContent"
      [emergencyCopy]="emergencyCopy"
      [emergencyNumber]="emergencyNumber"
    ></cs-emergency-care>
  </div>
</div>
