import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private storedRoutes = new Map<string, DetachedRouteHandle>();

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (route.data?.storageKey) {
      return this.storedRoutes.get(route.data.storageKey);
    }
    return undefined;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!(route.data?.storageKey && this.storedRoutes.has(route.data.storageKey));
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route?.data?.storageKey;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    let result: boolean;
    if (!!future.routeConfig && !!curr.routeConfig) {
      result = future.routeConfig === curr.routeConfig;
    } else if (!!future.firstChild?.routeConfig && !!curr.firstChild?.routeConfig) {
      result = future.firstChild.routeConfig === curr.firstChild.routeConfig;
    } else {
      result = false;
    }
    return result;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (!!route.data.storageKey && !!handle) {
      this.storedRoutes.set(route.data.storageKey, handle);
    }
  }
}
