import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-own-words',
  templateUrl: './own-words.component.html',
  styleUrls: ['./own-words.component.scss']
})
export class OwnWordsComponent {
  @Input() ownWords: string;
  @Input() hideExtraContent = true;
  @Input() showMoreLessButton = false;
  @Input() inMyOwnWordsTitle: string;
  moreLessExpanded = false;
}
