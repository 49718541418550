import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OfficeAddress } from '../../../models/office-address';
import { Provider, ProviderInterfaceHelper } from '../../../models/provider';
import { ResizeService } from '../../../services/resize.service';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'cs-result-card-location-list',
  templateUrl: './result-card-location-list.component.html',
  styleUrls: ['./result-card-location-list.component.scss']
})
export class ResultCardLocationListComponent implements OnInit{
  @Input() provider: Provider;
  @Input() isProviderCardContent = false;
  hideExtraContent = true;
  officeAddresses: OfficeAddress[] = [];
  showImprovedUX: boolean;

  constructor(private router: Router, public resizeService: ResizeService,  private configurationService: ConfigurationService ) {}

  ngOnInit(): void {
    if (this.provider) {
      this.officeAddresses = ProviderInterfaceHelper.getProviderOfficesSortedByRank(this.provider);
    }
    this.showImprovedUX = this.configurationService.showImprovedUX();
  }
  
  handleMoreLess(): void {
    this.hideExtraContent = !this.hideExtraContent;
  }

  goToProvider() {
    sessionStorage.setItem('searchResultsOrigin', 'true');
    this.router.navigateByUrl(
      `/${this.provider.npi}-${this.provider.firstName.makeURLFriendly()}-${this.provider.lastName.makeURLFriendly()}`
    );
  }
  getDirectionText(value: string) {
    return 'Get directions to ' + value;
  }
}
