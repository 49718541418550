import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration.service';
import { LocationLegacyService } from './location.legacy.service';
import { LocationNewService } from './location.new.service';

export function LocationServiceFactory(http: HttpClient, configService: ConfigurationService) {
  if (configService.useLegacyAPI()) {
    return new LocationLegacyService(http, configService);
  }
  return new LocationNewService(http, configService);
}
