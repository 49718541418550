import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatDistancePipe } from './format-distance.pipe';

@NgModule({
  declarations: [FormatDistancePipe],
  exports: [FormatDistancePipe],
  imports: [CommonModule]
})
export class PipesModule {}
