import * as uuid from 'uuid';
import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router, ParamMap } from '@angular/router';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, debounceTime, finalize, tap } from 'rxjs/operators';
import { FacetSummary, FiltersModel, FiltersModelPartialHelper } from '../../models/filters';
import { LocationConverter, SelectedLocation } from '../../models/location';
import { Provider, ProviderInterfaceHelper } from '../../models/provider';
import { ProviderImage } from '../../models/provider-image';
import { SearchFilter, TealiumSearchFilter } from '../../models/search-filter';
import { RuntimeConfiguration } from '../../services/configuration-injection.service';
import { FilterService, LocationSearchRequest, ProviderSearchResponse } from '../../services/filter.service';
import { ProviderService } from '../../services/provider.service';
import { ProviderCardParams } from '../../components/provider-card/provider-card.component';
import { MedGroup } from '../../models/medgroup';
import { CookieService } from '../../services/cookie.service';
import { LocationService } from '../../services/location.service';
import { ApiResponse, ApiResponseCreator } from '../../utils/api-response';
import '../../utils/string-prototypes';
import { FadUrlBuilder } from '../../utils/url/fad-url-builder';
import { UrlBuilderParams } from '../../utils/url/url-builder-params';
import { ConfigurationService } from '../../services/configuration.service';
import { SortingOption } from '../../models/configuration';
import { TealiumService } from '../../services/tealium.service';
import { ProviderRatingService } from '../../services/provider-rating.service';
import { HierarchySecurityService } from '../../configs/HierarchySecurityService';
import { EventPublisherService } from '../../services/event-publisher.service';
import { FadEvent, mapSearchCriteriaToFadEvent } from '../../models/fad-event';
import { LogService } from '../../services/log.service';
import { ResizeService } from '../../../app/services/resize.service';
import { setDialogPosition } from '../../utils/utils';
import { SearchFacet } from 'src/app/models/search-facet';
import { FadEventTypes } from 'src/app/models/fad-event-types';
import { DynamicFilterContentDetails } from 'src/app/models/DynamicFilterContentDetails';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppInsightsMonitoringService } from 'src/app/services/app-insights-monitoring.service';
import { FadLinkService } from 'src/app/services/fad-link.service';
import { ProviderRating } from 'src/app/models/provider-rating';
import { CernerModalService } from 'src/app/services/cerner-modal.service';
import { isNumeric } from 'rxjs/internal-compatibility';
import { TitleTagHandlerService } from 'src/app/services/title-tag-handler.service';
import { SessionObjectNames } from 'src/app/models/stringconst-names';

@Component({
  selector: 'cs-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() providers: Provider[] = [];
  @Input() headingText = '';
  launchGuidedFlow: boolean;
  redirectFromFAL = false;
  guidedFlowProvider: Provider;
  providerResults: Provider[] = [];
  dynamicFilterContents: Array<DynamicFilterContentDetails> = [];
  contents: boolean;
  resultsLoaded = false;
  pageNumber = 1;
  keyword = '';
  loadingMoreResults = false;
  bannerDisabled = true;
  selectedLocation: SelectedLocation;
  runtimeConfig: RuntimeConfiguration;
  medGroupDictionary = [];
  locationCookieSubscription$: Subscription;
  urlSubscription$: Subscription;
  providerImage: ProviderImage;
  searchFilters: SearchFilter = new SearchFilter();
  medicalGroupLink: any;
  showSpinner = true;
  providerCardSearchParams: ProviderCardParams;
  docAsapIds: { npi: number; docAsapId: number }[] = [];
  sortDropdowns: SortingOption[];
  sortOptions: SortingOption[];
  showSort: boolean;
  selectedSortValue: string;
  showDocAsap = false;
  isLocationRequired: boolean;
  filtersModel: FiltersModel = new FiltersModel(this.configurationService);
  isCaptchaEnabled: boolean;
  noResultText = '';
  ratingBannerText: string;
  ratingsBannerLinkText: string;
  ratingsBannerLink: string;
  onlineSchedulingType: string;
  showStarRatingsBanner: boolean;
  showRegionalHelplineNumbers: boolean;
  searchSessionId: string;
  providerSchedulingFeature: boolean;
  helplineNumbers: any[];
  isDesktopViewport: boolean;
  displayInfo: string;
  displayHeadingInvalid: string;
  showEmergencyCare: boolean;
  showMyProviders: boolean;
  market: string;
  medicalGroupToolTip: string;
  medicalGroupHoverText: string;
  affiliationToolTip: string;
  affiliationHoverText: string;

  defaultSearchDistance: number;
  defaultSearchDistanceWithMedGroup: number;
  useLegacyAPI = true;
  showSpinnerVmfhProvider = false;
  isShowVideo = false;
  videoUrl: SafeResourceUrl;
  isFuzzySearch = false;
  fuzzySearchList = [];
  currentSearchLocation: SelectedLocation;

  showOnlineSchedulingFilter = this.configurationService.showOnlineSchedulingFilter();
  showOnlineSchedulingAllVisitFilter = this.configurationService.showOnlineSchedulingAllVisitFilter();
  showVideoVisitsFilter = this.configurationService.showVideoVisitsFilter();
  showAcceptingNewPatientsFilter = this.configurationService.showAcceptingNewPatientsFilter();
  showAvailabilityFilter = this.configurationService.showAvailabilityFilter();
  showSpecialtyFilter = this.configurationService.showSpecialtyFilter();
  showMedicalGroupFilter = this.configurationService.showMedicalGroupFilter();
  showAffiliationFilter = this.configurationService.showAffiliationFilter();
  divisionCodes = this.configurationService.getDivisionCodes();
  showGenderFilter = this.configurationService.showGenderFilter();
  showInsuranceFilter = this.configurationService.showInsuranceFilter();
  showLanguageFilter = this.configurationService.showLanguageFilter();
  showSearchRadiusFilter = this.configurationService.showSearchRadiusFilter();
  embedSource = this.configurationService.getEmbedSource();
  showLegalCopy = this.configurationService.showLegalCopy();
  legalCopy = this.configurationService.getLegalCopy();
  legalCopyToolTip = this.configurationService.getLegalCopyToolTip();
  legalCopyToolTipInfo = this.configurationService.getLegalCopyToolTipInfo();
  showLogoLegend = this.configurationService.showLogoLegend();
  multipleMedicalGroupSelection = this.configurationService.getMultipleMedicalGroupSelection();
  isFiltersLeftSidePanelRequired = this.configurationService.getIsFiltersLeftSidePanelRequired();
  showInsuranceAcceptedFilter = this.configurationService.showInsuranceAcceptedFilter();

  showBacktoLoctionSearchBtn = false;
  includeProviderRoleTypes = false;
  showProviderPrefix = this.configurationService.showProviderPrefix();
  innerWidth: any;
  topStyle: any;
  showBackButton = false;
  backSearchSiteText = 'Back to site search';
  backtoSearchSiteLink = '';
  searchFrom = '';
  isFuzzyMatchEnabled = false;
  fuzzySearchText = '';
  fuzzySearchRecommendationText = '';
  isCernerPage=false;
  isDignityHealthDivision = false;
  @ViewChildren('providerList') cards!: QueryList<ElementRef>;
  bookingBaseUrl = 'https://mocka.dignityhealth.me';
  @HostListener('window:pageshow', ['$event'])
  onPageShow(event: any): void {
    if (event.persisted) {
      window.location.reload();
    }
  }

  getCernerClass(className: string): string {
    return this.cernerModalService.getCernerClass(className);
  }

  get alignTop(): boolean {
    return this.embedSource != null && (this.embedSource === 'cerner' || this.embedSource === 'mychart') ? true : false;
  }
  searchButtonClickEvent($event: boolean) {
    this.showSpinner = false
  }
  get handleNavigateToSchedulingSpa(): boolean {
    return (
      this.configurationService.getEmbedSource() === 'cerner' ||
      this.configurationService.getEmbedSource() === 'mychart' ||
      this.fadLinkServer.isSelfHosted()
    );
  }

  get isMyChart(): boolean {
    return this.configurationService.getEmbedSource() != null && this.configurationService.getEmbedSource() === 'mychart';
  }

  get isCerner(): boolean {
    return this.configurationService.getEmbedSource() != null && this.configurationService.getEmbedSource() === 'cerner';
  }

  get resultsString(): string {
    if (this.searchFilters && this.providerResults.length > 0) {
      const endVal =
        this.searchFilters.totalResults > this.searchFilters.pageSize * this.pageNumber
          ? this.searchFilters.pageSize * this.pageNumber
          : this.searchFilters.totalResults;
      return `1 - ${endVal} of ${this.searchFilters.totalResults} results`;
    }
  }

  get hasNoResults(): boolean {
    return this.resultsLoaded && !this.showSpinner && this.providerResults?.length === 0;
  }

  get selectedLocationDisplay(): string {
    let result = '';

    if (this.selectedLocation) {
      if (this.selectedLocation.city && this.selectedLocation.state && this.selectedLocation.zip) {
        result = `${this.selectedLocation.city}, ${this.selectedLocation.state} ${this.selectedLocation.zip}`;
      } else if (this.selectedLocation.city && this.selectedLocation.state && !this.selectedLocation.zip) {
        result = `${this.selectedLocation.city}, ${this.selectedLocation.state}`;
      } else if (this.selectedLocation.city && !this.selectedLocation.state && !this.selectedLocation.zip) {
        result = `${this.selectedLocation.city}`;
      } else if (!this.selectedLocation.city && this.selectedLocation.state) {
        result = `${this.selectedLocation.state}`;
      }
    }
    return result;
  }

  get showMoreButton(): boolean {
    if (this.searchFilters && this.providerResults) {
      return this.searchFilters.totalResults > this.providerResults.length;
    }
  }

  constructor(
    private filterService: FilterService,
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private urlBuilder: FadUrlBuilder,
    private cookieService: CookieService,
    private configurationService: ConfigurationService,
    private providerService: ProviderService,
    private tealiumService: TealiumService,
    private ratingService: ProviderRatingService,
    private securityService: HierarchySecurityService,
    private eventPublisherService: EventPublisherService,
    private logService: LogService,
    private resizeService: ResizeService,
    private sanitize: DomSanitizer,
    private appInsightService: AppInsightsMonitoringService,
    private fadLinkServer: FadLinkService,
    private cernerModalService: CernerModalService,
    private titleTagHandlerService: TitleTagHandlerService
  ) {
    this.setFALUrl();
    this.isLocationRequired = configurationService.getLocationRequiredDefaultValue();
    this.runtimeConfig = configurationService.getRuntimeConfiguration();
    this.showSort = configurationService.getSortingConfig().showOptions;
    this.isCaptchaEnabled =
      document.querySelector('#captcha-enabled')?.getAttribute('value') === 'true' || configurationService.getCaptchaEnabled();
    this.market = this.configurationService.getConfigType() || '';
    this.useLegacyAPI =  this.configurationService.useLegacyAPI();
    
  }
  ngOnInit(): void {
    
    this.showSpinner = true;
    this.onlineSchedulingType = this.configurationService.getOnlineSchedulingType();
    this.selectedSortValue = this.configurationService.getDefaultSortOption().label;
    this.showRegionalHelplineNumbers = this.configurationService.showRegionalHelplineNumbers();
    this.medGroupDictionary = this.configurationService.getMedGroupCodes();

    this.medicalGroupToolTip = this.configurationService.getMedicalGroupFilterToolTipText();
    this.medicalGroupHoverText = this.configurationService.getMedicalGroupFilterHoverText();
    this.affiliationToolTip = this.configurationService.getHospitalAffiliationFilterToolTipText();
    this.affiliationHoverText = this.configurationService.getHospitalAffiliationFilterHoverText();

    this.defaultSearchDistance = this.configurationService.getDefaultSearchDistance();
    this.defaultSearchDistanceWithMedGroup = this.configurationService.getDefaultSearchDistanceWhenMedGroupFilterApplied();

    this.bannerDisabled = this.cookieService.isBannerDisabled();
    this.sortOptions = this.sortDropdowns = this.configurationService.getSortingConfig().options;
    this.providerSchedulingFeature = this.configurationService.getProviderSchedulingFeature();
    // Set Location Cookie if we don't have one.
    this.locationCookieSubscription$ = this.cookieService.createLocationCookie().subscribe(() => {
      this.urlSubscription$ = combineLatest([this.route.params, this.route.queryParams])
        .pipe(
          debounceTime(0),
          tap(() => {
            this.filtersModel = new FiltersModel(this.configurationService);
            this.getSearchResults(this.route.snapshot);
          })
        )
        .subscribe();
    });

    if (this.configurationService.getOnlineSchedulingType() === 'ThirdParty') {
      this.initDocAsap();
    }

    this.showMyProviders = this.configurationService.showMyProviders();
    this.showEmergencyCare = this.configurationService.showEmergencyCare();
    this.displayInfo = this.configurationService.getHelplineHeading();
    this.displayHeadingInvalid = this.configurationService.getHelplineHeadingInvalid();
    this.isDesktopViewport = this.resizeService.isDesktopViewport;
    if (this.configurationService.getHelplineNumbers().length > 0) {
      this.helplineNumbers = this.configurationService.getHelplineNumbers();
    } else {
      this.helplineNumbers = [
        {
          heading: 'Arizona',
          phone: '(855) 697-0781'
        },
        {
          heading: 'California',
          phone: '(866) 914-3627'
        },
        {
          heading: 'Nevada',
          phone: '(866) 984-3627'
        }
      ];
    }
    this.getHelplineNumbers();
    if (sessionStorage.getItem('sortChanged')) sessionStorage.removeItem('sortChanged');
    this.isCernerPage=this.configurationService.getCernerPage();
    this.isDignityHealthDivision = (this.divisionCodes && this.divisionCodes[0] == "dignity-health" && this.configurationService.getMarketCodes()[0] === undefined) ? true : false;
  }

  ngAfterViewChecked(): void {
    setDialogPosition();
  }

  ngOnDestroy() {
    this.locationCookieSubscription$?.unsubscribe();
    this.urlSubscription$?.unsubscribe();
  }

  getStarRatingsBanner(provider: Provider): void {
    this.ratingBannerText =
    this.securityService.getRatingsBannerText(provider) ?? this.configurationService.defaultProviderRatings.defaultRatingsBannerText;
    if (!this.useLegacyAPI) {
      this.bannerDisabled = true;
    }
    this.ratingsBannerLinkText =
      this.securityService.getRatingsBannerLinkText(provider) ??
      this.configurationService.defaultProviderRatings.defaultRatingsBannerLinkText;
    this.ratingsBannerLink =
      this.securityService.getRatingsBannerLink(provider) ?? this.configurationService.defaultProviderRatings.defaultRatingsBannerLink;
    this.showStarRatingsBanner =
      this.securityService.shouldShowStarRatingsBanner(provider) && this.securityService.shouldShowStarRatings(provider);
  }
  
  navigateWithinSearchResults(urlSegments: string[], queryParams?: Params): void {
    this.router.navigate(urlSegments, {
      relativeTo: this.route,
      queryParams,
      state: { inPlaceNav: true, filtersModel: this.filtersModel }
    });
  }

  handlelocationNameClosed(event: any){
    this.redirectToOpenSreach();
  }

  redirectToOpenSreach(){
    const fadBaseUrl = this.configurationService.getRuntimeConfiguration().fadBaseUrl;
    const marketCode = this.configurationService.getMarketCodes()[0];
    let embedSourceValue = '';
    let routeUrl = '';

    try {
      embedSourceValue = this.configurationService.getEmbedSource();
      if (!embedSourceValue) {
        const falElement = document.querySelector('fal-saas');
        if (falElement) {
          embedSourceValue = falElement.getAttribute("embedsource");
        }
      }
    }
    catch (err) {
      console.log(err);
    }

    if (embedSourceValue === 'cerner') {
      routeUrl = `${fadBaseUrl}${this.configurationService.getRedirectToFADinCerner()}/t`;
    }
    else if (embedSourceValue === 'mychart') {
      routeUrl = `${this.configurationService.getRedirectURLPathForFADEpic()}/t`;
    }
    else if(document.body.firstElementChild?.firstElementChild?.tagName === 'FAL-SAAS' || 
      document.body.firstElementChild?.tagName === 'CS-FAD' || 
      document.body.firstElementChild?.tagName === 'FAD-SAAS') {
        routeUrl = `${fadBaseUrl}${marketCode}/t`;
    }
    else {
      routeUrl = `${this.configurationService.getRedirectInAEM()}/t`;
    }
    window.open(routeUrl, '_self');
  }


  handleFilterChange(event: any) {
    this.showSpinner = true;

    const location = this.route.snapshot.paramMap.get('location');
    this.filtersModel = event;
    if(location && location.includes("location-") && this.filtersModel.locationName == ''){
      this.redirectToOpenSreach();
      return;
    }

    if (sessionStorage.getItem('selectedMedicalGroup') && !this.filtersModel.providerMedicalGroup) {
      sessionStorage.setItem('selectedMedicalGroup', 'null');
    }

    let videoVisitsKeywordTyped = false;
    let searchTerm = '';
    this.route.params.forEach((param) => {
      searchTerm = param.searchTerm?.fromUrlFriendly() ?? '';
      if (param.searchTerm && param.searchTerm.trim().toLowerCase().replace(/\s/g, '').replace(/\W/g, '') === 'videovisits') {
        videoVisitsKeywordTyped = true;
      }
    });

    const specialty = this.handleSingleSpecialty(searchTerm);
    const queryParams = this.filterService.createQueryParams(this.filtersModel);

    if (this.selectedLocation?.city && this.selectedLocation?.state && this.filtersModel.providerMedicalGroup?.length > 0) {
      queryParams.location = `${this.selectedLocation.city.toLowerCase()}-${this.selectedLocation.state.toLowerCase()}`;
    }

    if (FiltersModelPartialHelper.getDistanceForSearchRadiusKey(this.filtersModel) !== this.configurationService.defaultSearchRadius) {
      queryParams.distance = FiltersModelPartialHelper.getDistanceForSearchRadiusKey(this.filtersModel);
    }

    this.filtersModel.pageNumber = 1;
    this.searchFilters.sortBy = this.filtersModel.sortByNumber;
    this.applySortParams(queryParams);
    let newUrl = '';

    if (sessionStorage.getItem('LocationIdFromFAL') && window.location.pathname.includes('/l/location')) {
      newUrl = this.buildUrlForLocationRoute(specialty, queryParams);
    } else {
      newUrl = this.buildUrlForNormalRoute(specialty, searchTerm, videoVisitsKeywordTyped);
    }

    this.navigateWithinSearchResults([newUrl], queryParams);
    this.showSpinner = false;
  }

  get medicalGroupMultiple(): string {
    
    let medGroup = this.filtersModel.providerMedicalGroup.length > 1
    ? this.filtersModel.providerMedicalGroup.map((x) => x.name).join('|')
    : this.filtersModel.providerMedicalGroup[0].name;
    
    if(!medGroup){
      medGroup = this.filtersModel.providerMedicalGroup.length > 1
      ? this.filtersModel.providerMedicalGroup.map((x) => x["value"]).join('|')
      : this.filtersModel.providerMedicalGroup[0]["value"];
    }
    return medGroup;
  }

  buildUrlForLocationRoute(specialty: string, queryParams: Params): string {
    let newUrl = '';
    if (this.filtersModel.selectedSpecialties.length > 1) {
      specialty = '';
      this.filtersModel.selectedSpecialties.forEach((s) => {
        specialty = specialty + '|' + s.name;
      });
      specialty = specialty.substring(1);
    } else if (this.filtersModel.selectedSpecialties.length === 1) {
      specialty = this.filtersModel.selectedSpecialties[0].name;
    }

    if (this.filtersModel.providerMedicalGroup && this.filtersModel.providerMedicalGroup.length > 0) {
      queryParams.medGroup = this.medicalGroupMultiple;
    }

    this.SetQueryParams(queryParams);

    if (specialty.length > 0) {
      queryParams.specialties = specialty;
    } else {
      delete queryParams.specialties;
    }
    newUrl = this.prepareUrl();
    
    return newUrl;
  }


  prepareUrl(): string{
    let url= '';
    this.route.params.forEach((param) => {
      if (param.searchType) {
        url = '/' + `${String(param.searchType)}` + '/';
      }
      if (param.location) {
        url = url + `${String(param.location)}`;
      }
    });
    return url;
  }

  SetQueryParams(queryParams: Params): void {
    this.route.queryParams.forEach((params) => {
      if (params.FALredirecttoFAD) {
        queryParams.FALredirecttoFAD = params.FALredirecttoFAD;
      }
      if (params.isHospitalBased) {
        queryParams.isHospitalBased = params.isHospitalBased;
      }
    });
  } 


  buildUrlForNormalRoute(specialty: string, searchTerm: string, videoVisitsKeywordTyped: boolean): string {
    let newUrl = '';
    if (videoVisitsKeywordTyped) {
      if (this.filtersModel.providerMedicalGroup && this.filtersModel.providerMedicalGroup.length > 0) {
        const medGroupsMapped = this.mapMedicalGroupTitle(this.filtersModel.providerMedicalGroup);
        newUrl = this.urlBuilder.build({
          medGroup: medGroupsMapped,
          selectedLocation: this.selectedLocation,
          searchTerm: '',
          specialty: specialty
        } as UrlBuilderParams);
      } else {
        newUrl = this.urlBuilder.build({
          medGroup: '',
          selectedLocation: this.selectedLocation,
          searchTerm: '',
          specialty: specialty
        } as UrlBuilderParams);
      }
    } else {
      if (this.filtersModel.providerMedicalGroup.length > 0) {
        const medGroupsMapped = this.mapMedicalGroupTitle(this.filtersModel.providerMedicalGroup);
        newUrl = this.urlBuilder.build({
          medGroup: medGroupsMapped,
          selectedLocation: this.selectedLocation,
          searchTerm: searchTerm,
          specialty: specialty
        } as UrlBuilderParams);
      } else {
        newUrl = this.urlBuilder.build({
          medGroup: '',
          selectedLocation: this.selectedLocation,
          searchTerm: searchTerm,
          specialty: specialty
        } as UrlBuilderParams);
      }
    }
    return newUrl;
  }

  applySortParams(params: Params): void {
    const sortByValue: SortingOption = this.searchFilters?.sortBy
      ? this.configurationService.getSortingOptionByValue(this.searchFilters.sortBy)
      : null;
    if (sortByValue && this.configurationService.getDefaultSortOption().name !== sortByValue.name) {
      params.sortby = sortByValue.queryParam;
    }
  }

  handleSortChange(event: SortingOption): void {
    this.showSpinner = false;
    const sortNumber = event.value;
    // need to set the searchFilter's sortBy before issuing the route call because the search requires it to be set.
    this.searchFilters.sortBy = sortNumber;
    this.selectedSortValue = event.label;
    this.filtersModel.sortByNumber = sortNumber;
    this.filtersModel.sortBy = event.queryParam;
    const queryParams = this.filterService.createQueryParams(this.filtersModel);
    this.filtersModel.pageNumber = 1;
    this.applySortParams(queryParams);
    sessionStorage.setItem('sortChanged', 'true');
    sessionStorage.setItem('sortBy', sortNumber.toString());
    this.navigateWithinSearchResults([], queryParams);
  }

  updateSortCaption(sortValue: string): void {
    const sortingOptions = this.configurationService.getSortingConfig();
    let selectedValue: SortingOption;

    if (!isNumeric(sortValue)) {
      let sortName = '';
      switch (sortValue) {
        case 'lastname-asc': {
          sortName = 'lastname';
          break;
        }
        case 'lastname-desc': 
        case 'lastnamedescending': {
          sortName = 'lastnameinverted';
          break;
        }
        default: {
          sortName = sortValue;
          break;
        }
      }
      selectedValue = sortingOptions.options.filter((s) => s.name.toLowerCase() === sortName)[0];
    } else {
      selectedValue = sortingOptions.options.filter((s) => s.value.toString().toLowerCase() === sortValue)[0];
    }
    this.selectedSortValue = selectedValue?.label;
  }

  handleMedicalGroupChange(event: any) {
    this.filtersModel = event;
    if (
      (sessionStorage.getItem('selectedMedicalGroup') || sessionStorage.getItem('medGroupQueryParam')) &&
      this.filtersModel.providerMedicalGroup.length === 0
    ) {
      sessionStorage.setItem('selectedMedicalGroup', 'null');
      sessionStorage.setItem('medGroupQueryParam', 'null');
    }

    const specialty = this.handleSingleSpecialty(this.keyword);
    const queryParams: Params | null | undefined = this.filterService.createQueryParams(this.filtersModel);
    let newUrl = '';
    if (this.route.snapshot.queryParamMap.has('sortby')) {
      this.applySortParams(queryParams);
    }

    if (sessionStorage.getItem('LocationIdFromFAL') && window.location.pathname.includes('/l/location')) {
      this.SetQueryParams(queryParams);

      if (this.filtersModel.providerMedicalGroup.length > 0) {
        queryParams.medgroup = this.mapMedicalGroupTitle(this.filtersModel.providerMedicalGroup);
        const medGroupString = this.filtersModel.providerMedicalGroup[0].name;
        const medGroupObject = this.medGroupDictionary.filter((group: MedGroup) => {
          return (
            group.code?.toLowerCase() === medGroupString?.toLowerCase() ||
            group.identifiers
              .split('|')
              .map((identifier: string) => identifier)
              .includes(medGroupString)
          );
        })[0];
        this.setMedGroupSession(medGroupObject);
      }

     newUrl = this.prepareUrl();
    } else {
      if (this.selectedLocation?.city && this.selectedLocation?.state && this.filtersModel.providerMedicalGroup.length > 0) {
        queryParams.location = `${this.selectedLocation.city.toLowerCase()}-${this.selectedLocation.state.toLowerCase()}`;
      }

      if (this.filtersModel.providerMedicalGroup?.length > 0) {
        const medGroupsMapped = this.mapMedicalGroupTitle(this.filtersModel.providerMedicalGroup);
        newUrl = this.urlBuilder.build({
          medGroup: medGroupsMapped,
          selectedLocation: this.selectedLocation,
          searchTerm: this.keyword,
          specialty: specialty
        } as UrlBuilderParams);
      } else {
        newUrl = this.urlBuilder.build({
          medGroup: '',
          selectedLocation: this.selectedLocation,
          searchTerm: this.keyword,
          specialty: specialty
        } as UrlBuilderParams);
      }
    this.removeLocationQueryParamIfExistsInParam();
    }
    this.navigateWithinSearchResults([newUrl], queryParams);
  }

  removeLocationQueryParamIfExistsInParam() {
    const snapshot = this.route.snapshot;
    const queryParams = { ...snapshot.queryParams };
    if (window.location.pathname.includes('/l/')
      && this.route.snapshot.queryParamMap.has('location')) {
        delete queryParams.location;
        this.router.navigate([], { queryParams: queryParams });        
    }
  }
  mapMedicalGroupName(medicalGroup: string): string {
    let mappedName = '';

    const selectedGroup: MedGroup = this.medGroupDictionary.filter((s: MedGroup) => {
      return s.code === medicalGroup;
    })[0];
    mappedName = selectedGroup.title;
    return mappedName;
  }

  mapMedicalGroupTitle(selectedMedicalGroups: FacetSummary[]): string {
    let medgroupName = '';
    const mappingNeeded =
      this.medGroupDictionary.filter((s: MedGroup) => {
        return s.code !== s.title;
      }).length > 0;

    if (mappingNeeded) {
      const mappedArray: string[] = [];
      if (selectedMedicalGroups.length > 0) {
        selectedMedicalGroups.forEach((s) => {
          const mappedValue: MedGroup = this.medGroupDictionary.filter((group: MedGroup) => {
            return group.title === s.name;
          })[0];
          if (mappedValue)
            mappedArray.push(mappedValue.code);
          else
            mappedArray.push(s.name);
        });
        medgroupName = mappedArray.length > 1 ? mappedArray.join('|') : mappedArray[0];
      }
    } else {
      medgroupName = selectedMedicalGroups.length > 1 ? selectedMedicalGroups.map((m) => m.name).join('|') : selectedMedicalGroups[0].name;
    }
    return medgroupName;
  }

  addMedicalGroups(event: any) {
    this.filtersModel = event;
    this.filtersModel.medicalGroupList = FiltersModelPartialHelper.getMedGroups(this.configurationService.getMedGroupCodes());
  }

  private getSearchParamLocationOrLocationCookie(routeSnapshot: ActivatedRouteSnapshot, term = 'location'): string | SelectedLocation {
    // order of retrieval:
    // 1) query params
    // 2) route param Map
    // 3) location cookie
    const location: string = routeSnapshot.queryParams.location?.trim() ?? (routeSnapshot.paramMap.get(term) ?? '').fromUrlFriendly();
    if (!location?.trim() && this.isLocationRequired) {
      return LocationConverter.convertTo(this.cookieService.getLocationCookieFromBrowser());
    }
    return location;
  }

  getSearchType(routeSnapshot: ActivatedRouteSnapshot): string {
    return routeSnapshot.paramMap.get('searchType') ?? 'l';
  }

  applySortingFromQueryParams(params: Params): void {
    if (this.searchFilters) {
      this.searchFilters.sortBy = !params.sortby
        ? this.configurationService.getDefaultSortOption().value
        : this.configurationService.getSortingOptionByQueryParam(params.sortby).value;
    }
  }

  getSearchResults(routeSnapshot: ActivatedRouteSnapshot): void {
    this.showSpinner = true;
    this.searchSessionId = uuid.v4();
    const searchFrom = routeSnapshot.queryParams.searchFrom ? routeSnapshot.paramMap.get('searchFrom') : '';
    if(searchFrom != '') {
      window.sessionStorage.setItem(SessionObjectNames.SearchFrom, 'gs');
      this.showBackButton = true;
      this.backtoSearchSiteLink = document.referrer;
      this.searchFrom = 'gs'
    }

    if (this.router.getCurrentNavigation()) {
      this.filtersModel = this.router.getCurrentNavigation().extras.state?.filtersModel ?? this.filtersModel;
    }

    if (!this.filtersModel?.pageNumber) {
      this.pageNumber = 1;
    }

    if (this.filtersModel?.pageNumber) {
      this.pageNumber = this.filtersModel.pageNumber;
    }

    this.applySortingFromQueryParams(routeSnapshot.queryParams);
    this.includeProviderRoleTypes = routeSnapshot.queryParams.includeProviderRoleTypes === 'true' ? true : false;
    sessionStorage.setItem('includeProviderRoleTypes', String(this.includeProviderRoleTypes));

    // default to l for bad path.
    const searchType = this.getSearchType(routeSnapshot);
    this.setTileTags(routeSnapshot, searchType);

    if (searchType === 'm') {
      this.getSearchResultForMedicalGroup(routeSnapshot);
    }

    if (searchType === 't') {
      const specialty = routeSnapshot.queryParams.specialty ?? (routeSnapshot.paramMap.get('specialty') ?? '').fromUrlFriendly();
      this.getSearchDetails(routeSnapshot, specialty, searchType);
    }

    if (searchType === 's') {
      this.selectedLocation = this.cookieService.createSelectedLocationFromLocationCookie();
      const searchString = routeSnapshot.queryParams.searchterm ?? routeSnapshot.paramMap.get('searchTerm') ?? '';
      this.setKeyword(searchString);
      this.getSearchResultForSpecialty(routeSnapshot, searchType);
    }
    

    if (searchType === 'l') {
      this.getSearchResultForLocation(routeSnapshot);
    }

    if (searchType === 'r') {
      this.getSearchResultForTerm(routeSnapshot);
    }

    if (routeSnapshot.queryParams.medgroup) {
      window.sessionStorage.setItem('medGroupQueryParam', routeSnapshot.queryParams.medgroup);
    }

    if (routeSnapshot.queryParams.distance) {
      this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius = FiltersModelPartialHelper.getRadiusKeyForDistance(Number(routeSnapshot.queryParams.distance));
    }

    if (routeSnapshot.queryParams?.sortby) {
      this.filtersModel.sortBy = String(routeSnapshot.queryParams?.sortby);
    }
  }

  getSearchResultForLocation(routeSnapshot: ActivatedRouteSnapshot): void {
    if (routeSnapshot.paramMap.get('location') && routeSnapshot.paramMap.get('location').includes('location-')) {
      const qryPrms = 'FALredirecttoFAD';
      if (routeSnapshot.queryParams.isHospitalBased && routeSnapshot.queryParams.isHospitalBased === 'true') {
        this.includeProviderRoleTypes = true;
      }
      if (sessionStorage.getItem(qryPrms)) {
        sessionStorage.removeItem(qryPrms);
      }
      if (sessionStorage.getItem('LocationIdFromFAL')) {
        sessionStorage.removeItem('LocationIdFromFAL');
      }
      const locationParam = sessionStorage.getItem('falLocationDetailsParam');
      if (routeSnapshot.queryParams.FALredirecttoFAD === 'true') {
        if (locationParam !== null && locationParam.length > 0) {
          this.showBacktoLoctionSearchBtn = true;
        }
        this.redirectFromFAL = true;
        sessionStorage.setItem(qryPrms, 'true');
      }
      const arrLocationDetails = routeSnapshot.paramMap.get('location').split('-');
      sessionStorage.setItem('LocationIdFromFAL', arrLocationDetails[arrLocationDetails.length - 1]);
      this.setFiltersFromRoute(routeSnapshot);
      const locationRequest: LocationSearchRequest = this.createLocationSearchRequest(
        arrLocationDetails[arrLocationDetails.length - 1],
        routeSnapshot.queryParamMap
      );
      this.performLocationSearchByLocationId(locationRequest, false);
    } else {
      const locationString = this.getSearchParamLocationOrLocationCookie(routeSnapshot);
      this.performLocationSearch(locationString);
    }
  }

  getSearchResultForTerm(routeSnapshot: ActivatedRouteSnapshot): void {
    let searchString = routeSnapshot.queryParams.searchterm ?? routeSnapshot.paramMap.get('searchTerm') ?? '';
    if (searchString) {
      searchString = searchString.fromUrlFriendly().replaceWithDash('_');
    }
    const location = this.getSearchParamLocationOrLocationCookie(routeSnapshot);
    if (searchString) {
      this.performLocationAndTermSearch(location, searchString);
    } else {
      this.performLocationSearch(location);
    }
  }

  getSearchResultForSpecialty(routeSnapshot: ActivatedRouteSnapshot, searchType: string) {
    this.providerResults = [];
    const specialty = routeSnapshot.queryParams.specialty ?? decodeURIComponent(routeSnapshot.paramMap.get('specialty') ?? '');
    // Condition added to skip long loading when specialty is only urgent-care
    if (this.configurationService.getAutoCheckBoxSelectionSpecalities().includes(specialty?.replace('-', ' ').toTitleCase())) {
      sessionStorage.setItem('prevSpecialityisAutoTgrChkbx', String(true));
      sessionStorage.removeItem('manualSelection');
      if (routeSnapshot.queryParams.includeProviderRoleTypes !== 'true') {
        const snapshot = this.route.snapshot;
        const params = { ...snapshot.queryParams };
        params.includeProviderRoleTypes = true;
        return this.router.navigate([], { queryParams: params });
      }
      this.getSearchDetails(routeSnapshot, specialty.replace('-', ' ').toTitleCase(), searchType);
    } else {
      if(!this.useLegacyAPI) {
        const sessionItem = window.sessionStorage.getItem(SessionObjectNames.SingleSpecialityText);
        if(sessionItem === null || sessionItem === '') {
          this.getSpecialtyResult(specialty, routeSnapshot, searchType);
        } else {
          this.getSearchDetails(routeSnapshot, sessionItem, searchType);
        }
      } 
      else {
        this.getSpecialtyResult(specialty, routeSnapshot, searchType);
      }
    }
  }

  getSpecialtyResult(specialty: string, routeSnapshot: ActivatedRouteSnapshot, searchType: string) :void {
    if (specialty.indexOf('-') > -1) {
      this.filterService
        .getTypedFilterSearchResults(this.keyword, this.selectedLocation, this.filtersModel, this.getSearchFrom(), this.searchFilters, 1, this.searchSessionId)
        .pipe(
          tap((value) => {
            const specialityFilters = value?.result?.facets
              .find((facet: SearchFacet) => facet.type === 'specialties')
              ?.values.find(
                (x) =>
                  x.label
                    .toLowerCase()
                    .replace(/[^a-zA-Z0-9 &/-]/g, '')
                    .replace(/[ /]/g, '-') === specialty.toLowerCase()
              )?.label ?? specialty.toLowerCase();
            this.getSearchDetails(routeSnapshot, specialityFilters, searchType);
          }),
          catchError((error) => {
            return throwError(error);
          })
        )
        .subscribe();
     } else {
       this.getSearchDetails(routeSnapshot, specialty.toTitleCase(), searchType);
      }
  }

  getSearchResultForMedicalGroup(routeSnapshot: ActivatedRouteSnapshot): void {
    const searchString = routeSnapshot.queryParams.searchterm ?? (routeSnapshot.paramMap.get('searchTerm') ?? '').fromUrlFriendly().replaceWithDash('_');
    const medGroupString = routeSnapshot.queryParams.medgroup ?? routeSnapshot.paramMap.get('medGroup');

    let selectedMedgroupCount = 1;
    let medGroupObject: MedGroup;
    let multipleMedGroups: string[] = [];

    if (decodeURIComponent(medGroupString).includes('|')) {
      selectedMedgroupCount = decodeURIComponent(medGroupString).split('|').length;
    }

    if (selectedMedgroupCount === 1) {
      medGroupObject = this.medGroupDictionary.filter((group: MedGroup) => {
        return (
          group.code?.toLowerCase() === medGroupString?.toLowerCase() ||
          group.identifiers
            ?.split('|')
            .map((identifier: string) => identifier)
            .includes(medGroupString)
        );
      })[0];
    } else {
      multipleMedGroups = decodeURIComponent(medGroupString).split('|');
    }

    this.setMedGroupsInFiltersModel(routeSnapshot);

    let location: string | SelectedLocation;
    if (medGroupObject || multipleMedGroups.length > 1) {
      if (medGroupObject) {
        this.setMedGroupSession(medGroupObject);
      }

      if (routeSnapshot.queryParams.location) {
        location = routeSnapshot.queryParams.location;
      } else if (!this.isLocationRequired || (!!medGroupObject?.code && medGroupObject?.code !== 'enterprise')) {
        location = undefined;
      } else {
        location = LocationConverter.convertTo(this.cookieService.getLocationCookieFromBrowser()) ?? '';
      }
    }

    this.performMedGroupSearch(searchString, medGroupObject, location, multipleMedGroups);
  }

  setFiltersFromRoute(routeSnapshot: ActivatedRouteSnapshot): void {
    const genderSelected = routeSnapshot.queryParams?.gender;

    if (genderSelected !== undefined && genderSelected?.length > 0) {
      this.filtersModel.providerGender = genderSelected;
      this.filtersModel.genderSelect = genderSelected;
    }

    if (routeSnapshot.queryParams?.bookonline === 'true') {
      this.filtersModel.onlineSchedulingApplied = this.filtersModel.onlineSchedulingToggle = true;
    }

    if (routeSnapshot.queryParams?.acceptingnew === 'true') {
      this.filtersModel.newPatientsApplied = this.filtersModel.newPatientsToggle = true;
    }

    if (routeSnapshot.queryParams?.videovisits === 'true') {
      this.filtersModel.videoVisitsApplied = this.filtersModel.videoVisitsToggle = true;
    }

    if (routeSnapshot.queryParams?.allVisits === 'true') {
      this.filtersModel.onlineSchedulingAllVisitApplied = this.filtersModel.onlineSchedulingAllVisitToggle = true;
    }
  }

  setMedGroupsInFiltersModel(routeSnapshot: ActivatedRouteSnapshot): void {
    const medGroupString = routeSnapshot.queryParams.medgroup ?? routeSnapshot.paramMap.get('medGroup');

    if (medGroupString) {
      if (decodeURIComponent(medGroupString).includes('|')) {
        let medGroupItem: MedGroup = {
          code: '',
          identifiers: '',
          url: '',
          title: '',
          city: '',
          state: '',
          latitude: '',
          longitude: '',
          banner: undefined
        };
        const selectedMedgroups: FacetSummary[] = [];
        const medGroupList: string[] = decodeURIComponent(medGroupString).split('|');
        medGroupList.forEach((x: string) => {
          medGroupItem = this.medGroupDictionary.filter((group: MedGroup) => {
            return group.identifiers?.toLowerCase() === x.toLowerCase();
          })[0];
          selectedMedgroups.push({
            name: medGroupItem.title,
            count: 0,
            selected: true
          });
        });
        this.filtersModel.providerMedicalGroup = [...selectedMedgroups];
        this.filtersModel.selectedMedicalGroup = [...selectedMedgroups];
        this.filtersModel.medicalGroupTitle = selectedMedgroups.length.toString() + ' Medical groups';
      } else {
        const selectedMedgroups: FacetSummary[] = [];
        const medGroupObject: MedGroup = this.medGroupDictionary.filter((group: MedGroup) => {
          return (
            group.code?.toLowerCase() === medGroupString?.toLowerCase() ||
            group.identifiers
              .split('|')
              .map((identifier: string) => identifier)
              .includes(medGroupString)
          );
        })[0];
        selectedMedgroups.push({
          name: this.mapMedicalGroupName(medGroupObject.code),
          count: 0,
          selected: true
        });
        this.filtersModel.providerMedicalGroup = [...selectedMedgroups];
        this.filtersModel.selectedMedicalGroup = [...selectedMedgroups];
        this.filtersModel.medicalGroupTitle = selectedMedgroups[0].name;
      }
    }
  }

  private setFALUrl(): void {
    const snapshot = this.route.snapshot;
    const params = { ...snapshot.queryParams };
    if (params.FALlocation) {
      window.sessionStorage.removeItem('falLocationDetailsParam');
      window.sessionStorage.setItem('falLocationDetailsParam', params.FALlocation);
      delete params.FALlocation;
      this.router.navigate([], { queryParams: params });
    }
  }

  private getSearchDetails(routeSnapshot: ActivatedRouteSnapshot, specialty: string, searchType: string) {
    const locationString = routeSnapshot.queryParams.location ?? (routeSnapshot.paramMap.get('location') ?? '').fromUrlFriendly();
    const searchString = routeSnapshot.queryParams.searchterm ?? (routeSnapshot.paramMap.get('searchTerm') ?? '').fromUrlFriendly().replaceWithDash('_');
    // Search last location searched OR the user's location (from cookie).
    // Cookie should be set when you first come into the search results page.
    let location: string | SelectedLocation;
    if (this.isLocationRequired) {
      if(this.selectedLocation){
        location = this.selectedLocation
      } else {
      location = locationString
        ? this.getSearchParamLocationOrLocationCookie(routeSnapshot)
        : LocationConverter.convertTo(this.cookieService.getLocationCookieFromBrowser());
      }
      if (!location) {
        this.locationService.getLocationByIP().subscribe((response) => {
          location = LocationConverter.convertTo(response.result.location);
          if (specialty) {
            this.performSpecialtySearch(location, specialty);
          } else if (searchString) {
            this.performLocationAndTermSearch(location, searchString);
          } else {
            this.performLocationSearch(location);
          }
        });
      } else {
        if (specialty) {
          this.performSpecialtySearch(location, specialty);
        } else if (searchString) {
          this.performLocationAndTermSearch(location, searchString);
        } else {
          this.performLocationSearch(location);
        }
      }
    } else {
      if (searchType === 's') {
        location = this.getSearchParamLocationOrLocationCookie(routeSnapshot);
      } else {
        location = this.selectedLocation = undefined;
      }
      if (specialty) {
        this.performSpecialtySearch(location, specialty);
      } else if (searchString) {
        this.performLocationAndTermSearch(location, searchString);
      } else {
        this.performLocationSearch(location);
      }
    }
  }

  getSelectedLocation(medGroup: MedGroup, location: string | SelectedLocation): Observable<SelectedLocation> {
    if (medGroup?.code) {
      if (this.isLocationRequired && (location === '' || typeof location === 'string' || (typeof location !== 'string' && !location))) {
        const cookieLocation = LocationConverter.convertTo(this.cookieService.getLocationCookieFromBrowser()) ?? ({} as SelectedLocation);
        if (cookieLocation?.city === undefined && cookieLocation?.state === undefined) {
          this.locationService.getLocationByIP().subscribe((response) => {
            const loc = response.result.location;
            this.cookieService.addLocationCookieToBrowser(loc);
            location = LocationConverter.convertTo(loc);
          });
        } else {
          location = `${cookieLocation?.city?.toUrlFriendly()}-${cookieLocation?.state?.toUrlFriendly()}`;
        }
        const locationVal = typeof location === 'string' ? location : location?.name;
        return this.locationService.queryLocation(locationVal);
      } else return this.locationService.queryLocation(typeof location === 'string' ? location : location?.name);
    } else if (
      (!medGroup && (location === '' || typeof location === 'string')) ||
      (typeof location !== 'string' && location && !location.googlePlaceId)
    ) {
      return this.locationService.queryLocation(
        typeof location !== 'string' ? location.neighborhood || location.name || location.city || location.state || location.zip : location
      );
    }
    return of(location as SelectedLocation);
  }

  extractDynamicFilterContent(filtersModel: FiltersModel): Array<DynamicFilterContentDetails> {
    const result = new Array<DynamicFilterContentDetails>();

    this.contents = this.configurationService.showContent();

    if (this.contents) {
      if (filtersModel.onlineSchedulingApplied) {
        this.extractOnlineSchedulingDetails(result);
      }

      if (filtersModel.selectedMedicalGroup?.length > 0) {
        this.extractMedicalGroupDetails(result);
      }

      if (filtersModel.selectedSpecialties !== undefined && filtersModel.selectedSpecialties.length > 0) {
        this.extractSpecialtyDetails(filtersModel, result);
      }
    }

    return result;
  }

  private extractOnlineSchedulingDetails(result: DynamicFilterContentDetails[]) {
    const onlineScheduleContents = this.configurationService.getOnlineSchedulingContent();
    if (onlineScheduleContents.length > 0) {
      result.push(onlineScheduleContents[0]);
    }
  }

  private extractMedicalGroupDetails(result: DynamicFilterContentDetails[]) {
    const medicalGroupContents = this.configurationService.getMedicalGroupContent();
    if (medicalGroupContents.length > 0) {
      const medicalGroupContent = ProviderInterfaceHelper.dynamicContentLinkTokenHandler(medicalGroupContents[0]);
      result.push(medicalGroupContent);
    }
  }

  private extractSpecialtyDetails(filtersModel: FiltersModel, result: DynamicFilterContentDetails[]) {
    const specialtyGroupContents = this.configurationService.getSpecialtyGroupContent();

    if (specialtyGroupContents.length > 0) {
      specialtyGroupContents.forEach((eachSpecialty) => {
        // ordering based on the list in config
        if (eachSpecialty.filterKeywords.length > 0) {
          const specialtyValue = filtersModel.selectedSpecialties?.find((eachItem) =>
            eachSpecialty.filterKeywords.some((filterValue) => filterValue.trim().toLowerCase() === eachItem.name?.toLowerCase())
          );
          if (specialtyValue !== undefined) {
            // Check whether the same content is already present in the result
            // so that the content is distinct
            const isPresent = result.find((eachItem) => eachItem.paragraph === eachSpecialty.paragraph);
            if (isPresent === undefined) {
              const specialtyContent = ProviderInterfaceHelper.dynamicContentLinkTokenHandler(eachSpecialty);
              result.push(specialtyContent);
            }
          }
        }
      });
    }
  }

  performMedGroupSearch(searchString: string, medGroupObj: MedGroup, location: string | SelectedLocation, multipleMedGroups?: string[]) {
    this.performSearch(location, searchString, medGroupObj, multipleMedGroups);
  }

  performLocationSearch(location: string | SelectedLocation): void {
    this.performSearch(location, null, null, null);
  }

  performSpecialtySearch(location: string | SelectedLocation, specialty: string) {
    if (!!this.filtersModel && specialty) {
      this.filtersModel.selectedSpecialties.push({
        name: specialty,
        selected: true
      } as FacetSummary);
      this.keyword = '';
    }
    this.performSearch(location, null, null, null);
  }

  performLocationAndTermSearch(location: string | SelectedLocation, searchString: string): void {
    this.performSearch(location, searchString, null, null);
  }

  isHospitalBased(): boolean {
    return this.route.snapshot.queryParams.isHospitalBased && this.route.snapshot.queryParams.isHospitalBased === 'true';
  }

  private performSearch(location: string | SelectedLocation, searchString: string, medGroupObj?: MedGroup, multipleMedGroups?: string[]) {
    this.providerResults = []; // Clear search results so spinner shows up above the fold while loading new results.
    this.dynamicFilterContents = [];
    this.showSpinner = true;
    const isHospitalBased = this.isHospitalBased();

    let headerTitle = medGroupObj?.title;
    if (multipleMedGroups?.length > 0) {
      medGroupObj = this.medGroupDictionary.filter((group: MedGroup) => {
        return group.identifiers.toLowerCase() === multipleMedGroups[0].toLowerCase();
      })[0];
      headerTitle = '';
      this.medicalGroupLink = '';
    }

    this.getSelectedLocation(medGroupObj, location)
      .pipe(
        tap((selectedLocation) => {
          if (selectedLocation?.isLocationValid || selectedLocation?.name) {
            this.cookieService.addLocationCookieToBrowser(selectedLocation);
            this.sortDropdowns = this.sortOptions;
          } else {
            this.sortDropdowns = this.sortOptions.filter((li) => li.name.toLowerCase() !== 'distance');
          }
          this.selectedLocation = selectedLocation;
          this.removeLocationQueryParamIfExistsInParam();
          this.filtersModel = this.filterService.getFilterStates(
            this.route.snapshot.queryParams,
            this.filtersModel,
            medGroupObj?.code ?? '',
            searchString
          );

          this.setMedGroupsInFiltersModel(this.route.snapshot);
          if (this.filtersModel?.selectedSpecialties?.length === 1)
            window.sessionStorage.setItem('singleSpecialty', this.filtersModel.selectedSpecialties[0].name);
          this.setKeyword(searchString);
          this.setHeadingText(this.keyword, this.selectedLocation, headerTitle);
        }),
        catchError(() => {
          return of(null as SelectedLocation);
        }),
        concatMap(() => {
          if (
            (!this.selectedLocation && !this.isLocationRequired) ||
            this.selectedLocation?.isLocationValid || this.selectedLocation?.name
          ) {
            return this.filterService.getTypedFilterSearchResults(
              this.keyword,
              this.selectedLocation,
              this.filtersModel,
              this.getSearchFrom(),
              this.searchFilters,
              1,
              this.searchSessionId,
              this.includeProviderRoleTypes || isHospitalBased
            );
          }
          return ApiResponseCreator.createObservable<ProviderSearchResponse>(
            {
              providers: []
            } as ProviderSearchResponse,
            false
          );
        }),
        tap((providerSearchResponse: ApiResponse<ProviderSearchResponse>) => {
          if (!providerSearchResponse?.isValid) {
            this.providerResults = [];
            this.headingText = 'Something went wrong.';
            this.selectedLocation.isLocationValid = false;
            this.titleTagHandlerService.setNoFollowMetaPage();
          } else {
            if (headerTitle !== '') {
              this.medicalGroupLink = this.getMedicalGroupLink(headerTitle);
            } else {
              this.medicalGroupLink = '';
            }
            this.setHeadingText(this.keyword, this.selectedLocation, headerTitle);
            this.resultsLoaded = true;

            this.eventPublisherService.publish(mapSearchCriteriaToFadEvent(this.keyword, this.selectedLocation, medGroupObj?.title));

            this.filtersModel = this.filterService.getFacetInfo(providerSearchResponse.result, this.filtersModel);
            this.checkMedicalGroupCollection();
            this.providerResults = providerSearchResponse.result.providers;
            this.searchFilters = providerSearchResponse.result;
              this.providerResponseSwitchMapFunc(providerSearchResponse);
            this.updateSortCaption(this.searchFilters.sortByName.toString().toLowerCase());
            this.setNoResultText(this.keyword, this.selectedLocation, providerSearchResponse?.result?.recommendations);
            this.setSortByDropdownDisplayName();
            this.getNetworkProvider();
            if (this.providerResults.length > 0) {
              this.setProviderGenderFull();
              this.overwriteProviderImageUrl();
              this.getStarRatingsBanner(this.providerResults[0]);
              // Retrieves the dynamic contents from the config
              this.dynamicFilterContents = this.extractDynamicFilterContent(this.filtersModel);
              this.titleTagHandlerService.removeMetaNoIndexName();
            } else {
              this.setNoRecordsTitle(this.selectedLocation);
              this.titleTagHandlerService.setNoFollowMetaPage();
            }
            this.tealiumServiceForSearchResults();
            window.sessionStorage.setItem('fadSearchResultsUrl', window.location.href);
            const siteUrl = this.router.url;
            window.sessionStorage.setItem('cernerfadSearchUrl', siteUrl);
          }
          this.getProviderCards();
        }),
        finalize(() => (this.showSpinner = false))
      )
      .subscribe();
  }

  setNoRecordsTitle(location: SelectedLocation): void {
    this.titleTagHandlerService.setPageTitleforNoRecords(location);
  }

  checkMedicalGroupCollection(): void {
    if (this.filtersModel && (this.filtersModel.providerMedicalGroup === null || this.filtersModel.selectedMedicalGroup === null)) {
      this.filtersModel.selectedMedicalGroup = [];
      this.filtersModel.providerMedicalGroup = [];
    }

    if (this.filtersModel.providerSearchRadius === null) {
      this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius;
    }
  }

  setKeyword(searchString: string): void {
    if (searchString && searchString.trim().toLowerCase().replace(/\s/g, '') !== 'videovisits') {
      this.keyword = decodeURIComponent(searchString).toTitleCase();
    } else {
      this.keyword = '';
    }
  }

  setHeadingText(keyword: string, location: SelectedLocation, medgroup = ''): void {
    let text = '';
    if (!medgroup) {
      text = this.setLocationBasedHeading(keyword, location);
    } else {
      text = this.setMedicalGrpBasedHeading(keyword, medgroup);
    }
    this.headingText = text;
  }

  setLocationBasedHeading(keyword: string, location: SelectedLocation): string {
    let text = '';
    if (keyword && !location?.city && !location?.state) {
      text = keyword;
    } else if (!keyword && location?.state) {
      text = `Doctors in ${location.neighborhood ? location.neighborhood + ', ' : ''}${location.city}, ${location.state}${location.zip ? ' ' + location.zip : '' }`;
    } else if (keyword && location) {
      text = `${keyword} in ${location.neighborhood ? location.neighborhood + ', ' : ''}${location.city}, ${location.state}${location.zip ? ' ' + location.zip : ''}`;
    }
    return text;
  }

  setMedicalGrpBasedHeading(keyword: string, medgroup: string): string {
    return keyword ? `${keyword} at ${medgroup}` : `Doctors at ${medgroup}`;
  }

  getMedicalGroupLink(medGroup: string): any {
    if(!medGroup) {
      return '';
    }
    return {
      Text: `Learn more about ${medGroup}`,
      URL: this.filtersModel.medicalGroupList[this.filtersModel.medicalGroupList?.map((x) => x.label).indexOf(medGroup)]?.url
    };
  }

  closeBanner(): void {
    this.bannerDisabled = true;
    this.cookieService.setBannerDisabledCookie();
  }

  bannerLinkClick(searchTerm: string) {
    const url = this.urlBuilder.build({ searchTerm } as UrlBuilderParams);
    this.router.navigateByUrl(url);
  }

  overwriteProviderImageUrl(): void {
    this.providerResults.forEach((res: Provider) => {
      if (res.images) {
        const imagesCopy = res.images.slice();
        imagesCopy.sort((x: ProviderImage, y: ProviderImage) => y.width * y.height - x.width * x.height);
        const imagesBySizeDecreasing =
          res.images.length > 0
            ? imagesCopy.filter((image: ProviderImage) => {
              return image.height === 160 && image.width === 120;
            })
            : [];

        const placeholderImage: ProviderImage = {
          url:
            res.gender?.toLowerCase() === 'male' ? this.configurationService.malePlaceholder : this.configurationService.femalePlaceholder
        };

        res.images.forEach((img: ProviderImage) => {
          this.providerImage = imagesBySizeDecreasing[0] ?? placeholderImage;
          img.url = this.providerImage.url?.replace(/^http:\/\//i, 'https://');
        });
      }
    });
  }

  setProviderGenderFull(): void {
    this.providerResults.forEach((provider) => {
      if (provider.gender?.toLowerCase() === 'm') {
        provider.gender = 'Male';
      } else if (provider.gender?.toLowerCase() === 'f') {
        provider.gender = 'Female';
      } else {
        if (provider.gender?.toLowerCase() != 'male' && provider.gender?.toLowerCase() != 'female') {
          provider.gender = null;
        }
      }
    });
  }

  getMoreResults(): void {
    const consolidatedProviderResults = this.providerResults;
    this.loadingMoreResults = true;
    sessionStorage.removeItem('sortChanged');
    this.pageNumber++;

    if (sessionStorage.getItem('LocationIdFromFAL') && window.location.pathname.includes('/l/location')) {
      const locationId = sessionStorage.getItem('LocationIdFromFAL');
      const locationRequest: LocationSearchRequest = this.createLocationSearchRequest(locationId, this.route.snapshot.queryParamMap);
      this.performLocationSearchByLocationId(locationRequest, true);
    } else {
      this.filterService
        .getTypedFilterSearchResults(
          this.keyword,
          this.selectedLocation,
          this.filtersModel,
          this.getSearchFrom(),
          this.searchFilters,
          this.pageNumber,
          this.searchSessionId,
          this.includeProviderRoleTypes
        )
        .pipe(
          tap((searchResponse: ApiResponse<ProviderSearchResponse>) => {
            this.filtersModel = this.filterService.getFacetInfo(searchResponse.result, this.filtersModel);
            this.checkMedicalGroupCollection();
            this.providerResults = searchResponse.result.providers;
            this.searchFilters = searchResponse.result;
            this.providerResponseSwitchMapFunc(searchResponse)
            this.loadingMoreResults = false;

            if (this.providerResults.length > 0) {
              this.updateSortCaption(this.searchFilters.sortByName.toString().toLowerCase());
              consolidatedProviderResults.push(...this.providerResults);
              this.providerResults = consolidatedProviderResults;
              this.getNetworkProvider();
              this.setProviderGenderFull();
              this.overwriteProviderImageUrl();
              this.tealiumServiceForProvidersImpressions(this.providerResults);
              this.cards.changes.subscribe(() => {
                const index = `item-${this.providerResults.length - 21}`;
                console.log((document.getElementById(index).nextElementSibling.firstElementChild as HTMLElement).getElementsByClassName('h3')[0] as HTMLElement);
                console.log(((document.getElementById(index).nextElementSibling.firstElementChild as HTMLElement).getElementsByClassName('h3')[0] as HTMLElement).getElementsByTagName('a')[0] as HTMLElement);
                (((document.getElementById(index).nextElementSibling.firstElementChild as HTMLElement).getElementsByClassName('h3')[0] as HTMLElement).getElementsByTagName('a')[0] as HTMLElement).focus();
              });
            }
            this.getProviderCards();
          }),
          finalize(() => (this.showSpinner = false))
        )
        .subscribe();
    }
  }

  scheduleProvider(provider: Provider): void {
    if(this.isCernerPage)
      {
        //<------The below code is used in cerner Page to focus on Schedule Appointment Modal Popup----->
        this.eventPublisherService.publish(
          new FadEvent({
            type: FadEventTypes.PostApptDetailstoMyAppointment_Cerner,
            data: 'RescheduleAppointment'
          })
        );
      }
    this.guidedFlowProvider = this.providerService.normalizeProviderBeforeScheduling(provider);
    this.launchGuidedFlow = this.configurationService.showGuidedFlowButton();
    if (!this.launchGuidedFlow) {
      sessionStorage.setItem('searchResultsOrigin', 'true');
      this.router.navigateByUrl(
        `/${provider.npi}-${provider.firstName.replace(' ', '-').toLowerCase()}-${provider.lastName.replace(' ', '-').toLowerCase()}`
      );
    }
    this.cernerModalService.openModalForCerner();
  }
  closeScheduleModal() {
    this.launchGuidedFlow = false;
    this.cernerModalService.closeModalForCerner();
  }
  scheduleProviderHandler(provider: Provider): void {
    this.scheduleProvider(provider);
  }

  getProviderCards(): void {
    if (this.providerResults.length === 0) {
      this.providerCardSearchParams = {
        location: this.selectedLocation?.name,
        displaySearchButton: false,
        subHeading: '',
        medGroup: (this.filtersModel?.providerMedicalGroup?.length > 0? this.filtersModel.providerMedicalGroup[0].name : null)
      } as ProviderCardParams;
    } else {
      this.providerCardSearchParams = undefined;
    }
  }

  getNetworkProvider(): void {
    if (!this.providerResults || this.providerResults.length === 0) {
      return;
    }
    let matchingProvider = null;
    this.providerResults.forEach((p) => {
      if (!matchingProvider) {
        this.medGroupDictionary.forEach((x) => {
          if (x.banner && p.medicalGroupIds?.includes(x.code)) {
            p.firstNetworkProvider = true;
            matchingProvider = p
          }
        });
      }
    });
  }

  initDocAsap(): void {
    this.showDocAsap = true;
    if (this.providerSchedulingFeature) {
      this.docAsapIds = [];
      this.logService.error('from api:'); //Note: will be removed after testing
    } else {
      this.providerService.getDocAsapIds().subscribe((results: { npi: number; docAsapId: number }[]) => {
        this.docAsapIds = results || [];
      });
      this.logService.error('from ids.json:'); //Note: will be removed after testing
    }
  }

  setNoResultText(keyword: string, location: SelectedLocation, recommendation: string[] ): void {

    let text = `Sorry, we couldn’t find any results`;

    if (keyword) {
      text += ` for “${keyword}”`;
    }
    if (location) {
      const locationText = this.formatLocation(location);
      text += locationText.length > 0 ? ` in ${locationText}` : '';
    }

    if(recommendation?.length <= 0) {
      this.isFuzzySearch = false;
    }
    else {
      if(this.configurationService.getShowFuzzyMatch()) {
        this.isFuzzySearch = true;
        this.fuzzySearchList = recommendation;
        this.fuzzySearchRecommendationText = " Click the related keywords below to try again."
        this.fuzzySearchText = '(Note: any filters in your search will be removed)'
      }
    }
    text += ".";
    this.noResultText = text;
  }

  fuzzySearch(fuzzyString: any) {
    const url = this.urlBuilder.build({
      searchTerm: fuzzyString,
    } as UrlBuilderParams);
    this.showSpinner = true;
    this.router.navigateByUrl(url);
  }

  formatLocation(location: SelectedLocation): string {
    let l = !!location.neighborhood ? `${location.neighborhood}, ` : '';
    l += location.city ? `${location.city}` : '';
    l += location.state ? `, ${location.state}` : '';
    l += location.zip ? ` ${location.zip}` : '';
    return l;
  }

  tealiumServiceForSearchResults(): void {
    const siteUrl = this.router.url;
    const tealiumSearchFilter: TealiumSearchFilter = {
      facility: '',
      medicalGroup:
        this.filtersModel.providerMedicalGroup && this.filtersModel.providerMedicalGroup.length > 0
          ? this.filtersModel.providerMedicalGroup[0].name
          : '',
      serviceLine: '',
      searchURL: siteUrl,
      scheduleFilterDetails: siteUrl.indexOf('?') === -1 ? '' : siteUrl.split('?')[1],
      scheduleLocation: this.selectedLocationDisplay,
      scheduleKeyword: this.keyword,
      scheduleBookOnline: siteUrl.toLowerCase().indexOf('onlinescheduling') !== -1,
      totalResultsCount: this.searchFilters.totalResults,
      tealiumEventName:
        this.searchFilters.totalResults > 0
          ? siteUrl.indexOf('?') !== -1
            ? 'search_schedule:filter results'
            : 'search_schedule:results'
          : 'search_schedule:no results'
    };
    this.tealiumService.searchResults(tealiumSearchFilter);
  }

  tealiumServiceForProvidersImpressions(providerList: Provider[]): void {
    this.tealiumService.providersImpressions(providerList);
  }

  getHelplineNumbers(): void {
    this.helplineNumbers.forEach((helpline: any) => {
      const cleaned = helpline.phone.toString().replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        helpline.displayPhone = `(${match[1]}) ${match[2]}-${match[3]}`;
        helpline.phone = `+1${match[0]}`;
      } else {
        return;
      }
    });
  }

  handleSingleSpecialty(searchTerm: string): string {
    window.sessionStorage.removeItem(SessionObjectNames.SingleSpeciality);
    let specialty = '';
    if (!this.configurationService.useLegacyAPI()) {
      if (this.filtersModel?.selectedSpecialties?.length === 1 && searchTerm === '' && this.filtersModel?.selectedMedicalGroup.length === 0) {
        specialty = this.filtersModel.selectedSpecialties[0].name;
        this.filtersModel.selectedSpecialties = this.filtersModel.providerSpecialties = [];
      }
    }
    return specialty;
  }
  
  checkNonFadUrlAccessed(): void {
    if (window.sessionStorage.getItem('nonFadUrlAccessed') === 'true') {
      window.scroll({
        top: -150,
        behavior: 'smooth'
      });
      window.sessionStorage.removeItem('nonFadUrlAccessed');
    }
  }

  navigateToSchedulingSpa(event: any): void {
    window.sessionStorage.removeItem('singleSpecialty');
    if (event) {
      this.eventPublisherService.publish(
        new FadEvent({
          type: FadEventTypes.NavigateToSchedulingOnCerner,
          data: event
        })
      );
    }

      if(this.isCernerPage)
      {
        //<------The below code is used in cerner Page to get token from my-appointment-spa----->
        this.eventPublisherService.publish(
          new FadEvent({
            type: FadEventTypes.PostApptDetailstoMyAppointment_Cerner,
            data: event
          })
        );
        const tokenInfo = window.sessionStorage.getItem(
        this.runtimeConfig.Cerner_MyAppointments_OIDC_Token
        );
        if (tokenInfo) {
          
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          this.eventPublisherService.publish(
            new FadEvent({
              type: FadEventTypes.PostOidcTokentoMyAppointment_Cerner,
              data: tokenInfo
            })
          );
        }
      }
  }

  performLocationSearchByLocationId(locationRequest: LocationSearchRequest, moreResults: boolean): void {
    let consolidatedProviderResults = this.providerResults;
    if (!moreResults) {
      this.providerResults = [];
    }
    // To get locationId based provider details
    this.providerService
      .getLocationDetailsbyLocationId(locationRequest)
      .pipe(
        tap((providerSearchResponse: ApiResponse<ProviderSearchResponse>) => {
          if (!providerSearchResponse?.isValid) {
            this.providerResults = [];
            this.headingText = 'Something went wrong.';
            this.selectedLocation.isLocationValid = false;
          } else {
            const selectedMedGroupCount =
              locationRequest.medGroups && locationRequest.medGroups !== 'null' ? locationRequest.medGroups?.split('|').length : 0;

            if (selectedMedGroupCount > 0) {
              this.filtersModel.selectedMedicalGroup = [];
              this.filtersModel.providerMedicalGroup = [];
              locationRequest.medGroups.split('|').forEach((x) => {
                const tempGroup = this.medGroupDictionary.filter((s: MedGroup) => {
                  return s.code?.toLowerCase() === x.toLowerCase();
                })[0];
                this.filtersModel.providerMedicalGroup.push({
                  name: tempGroup.code,
                  count: 0,
                  selected: true
                });
                this.filtersModel.selectedMedicalGroup.push({
                  name: tempGroup.code,
                  count: 0,
                  selected: true
                });
              });
              this.filtersModel.medicalGroupTitle =
                this.filtersModel.providerMedicalGroup.length === 1
                  ? this.filtersModel.providerMedicalGroup[0].name
                  : this.filtersModel.selectedMedicalGroup.length.toString() + ' Medical groups';
            }

            const medGroupObj = this.getMedGroupObject(selectedMedGroupCount, locationRequest);
            this.showHeaderForMedgroups(medGroupObj?.title, medGroupObj?.code, selectedMedGroupCount);
            this.resultsLoaded = true;
            this.setNoResultText(this.keyword, this.selectedLocation, providerSearchResponse?.result?.recommendations);
            this.eventPublisherService.publish(mapSearchCriteriaToFadEvent(this.keyword, this.selectedLocation, medGroupObj?.title));

            this.filtersModel = this.filterService.getFacetInfo(providerSearchResponse.result, this.filtersModel);
            this.filtersModel.locationName = providerSearchResponse.result.providers[0].officeNames[0];
            this.checkMedicalGroupCollection();
            this.providerResults = providerSearchResponse.result.providers;
            this.searchFilters = providerSearchResponse.result;
            this.providerResponseSwitchMapFunc(providerSearchResponse);
            this.updateSortCaption(this.searchFilters.sortByName.toString().toLowerCase());
            this.setSortByDropdownDisplayName();
            this.getNetworkProvider();
            if (this.providerResults.length > 0) {
              if (sessionStorage.getItem('sortChanged') && sessionStorage.getItem('sortChanged') === 'true') {
                consolidatedProviderResults = [];
              }
              if (moreResults) {
                consolidatedProviderResults.push(...this.providerResults);
                this.providerResults = consolidatedProviderResults;
              } else {
                consolidatedProviderResults = this.providerResults;
              }
              this.setProviderGenderFull();
              this.overwriteProviderImageUrl();
              this.getStarRatingsBanner(this.providerResults[0]);
              // Retrieves the dynamic contents from the config
              this.dynamicFilterContents = this.extractDynamicFilterContent(this.filtersModel);
            }
            this.tealiumServiceForSearchResults();
            const siteUrl = this.router.url;
            window.sessionStorage.setItem('cernerfadSearchUrl', siteUrl);
            window.sessionStorage.setItem('fadSearchResultsUrl', window.location.href);
            this.loadingMoreResults = false;
          }
          this.getProviderCards();
        }),
        finalize(() => (this.showSpinner = false))
      )
      .subscribe();
  }

  getMedGroupObject(selectedMedGroupCount: number, locationRequest: LocationSearchRequest): MedGroup {
    let medGroupObj: MedGroup;
    if (selectedMedGroupCount === 1) {
      medGroupObj = this.medGroupDictionary.filter((group: MedGroup) => {
        return (
          group.code?.toLowerCase() === locationRequest.medGroups?.toLowerCase() ||
          group.identifiers
            .split('|')
            .map((identifier: string) => identifier)
            .includes(locationRequest.medGroups)
        );
      })[0];
    } else if (selectedMedGroupCount > 1) {
      const firstMedGroup = locationRequest.medGroups.split('|')[0];
      medGroupObj = this.medGroupDictionary.filter((group: MedGroup) => {
        return (
          group.code?.toLowerCase() === firstMedGroup.toLowerCase() ||
          group.identifiers
            .split('|')
            .map((identifier: string) => identifier)
            .includes(firstMedGroup)
        );
      })[0];
    }
    return medGroupObj;
  }

  showHeaderForMedgroups(medgroupTitle: string, medgroupCode: string, selectedMedGroupCount: number): void {
    if (medgroupTitle) {
      this.medicalGroupLink = this.getMedicalGroupLink(medgroupTitle);
    }

    if (selectedMedGroupCount === 1) {
      this.filtersModel = this.filterService.getFilterStates(this.route.snapshot.queryParams, this.filtersModel, medgroupCode ?? '', '');
      this.setHeadingText(this.keyword, this.selectedLocation, medgroupTitle);
      this.medicalGroupLink = this.getMedicalGroupLink(medgroupTitle);
    } else if (selectedMedGroupCount == 0) {
      this.setHeadingText(this.keyword, this.selectedLocation);
      this.medicalGroupLink = {};
    } else if (selectedMedGroupCount > 1) {
      this.setHeadingText(this.keyword, this.selectedLocation, `${medgroupTitle} and ${selectedMedGroupCount - 1} other(s)`);
      this.medicalGroupLink = this.getMedicalGroupLink(medgroupTitle);
    }
  }

  handleCloseClicked(): void {
    this.isShowVideo = false;
    this.videoUrl = undefined;
    this.cernerModalService.closeModalForCerner();
  }
  toggleModal(videoUrl: string): void {
    this.videoUrl = this.sanitize.bypassSecurityTrustResourceUrl(videoUrl);
    this.isShowVideo = true;
    this.cernerModalService.openModalForCerner();
  }
  providerRoleTypesEmit($event: boolean): void {
    this.filtersModel.includeProviderRoleType = $event;
  }

  setMedGroupSession(medGroupObject: any): void {
    if (medGroupObject.length > 0) {
      sessionStorage.setItem('selectedMedicalGroup', medGroupObject[0]?.code);
      sessionStorage.setItem('PreviousSelectedMediGrpPrvdRole', medGroupObject[0]?.enableProviderRoleTypes);
    } else {
      if (medGroupObject) {
        sessionStorage.setItem('selectedMedicalGroup', medGroupObject?.code);
        sessionStorage.setItem('PreviousSelectedMediGrpPrvdRole', medGroupObject?.enableProviderRoleTypes);
      }
    }
  }

  createLocationSearchRequest(locationId: string, queryParam: ParamMap): LocationSearchRequest {
    const queryParamList = queryParam;
    let sortBy = 2;

    if (sessionStorage.getItem('sortBy')) {
      sortBy = sessionStorage.getItem('sortBy') as unknown as number;
    }

    const medGroupFromSession = sessionStorage.getItem('medGroupQueryParam');

   return {
      locationId: locationId,
      searchSessionId: this.searchSessionId,
      includeProviderRoleType: this.includeProviderRoleTypes,
      sortby: sortBy,
      page: this.pageNumber,
      distance: 10,
      patients: queryParamList.get('acceptingnew') ? queryParamList.get('acceptingnew') : null,
      bookOnline: queryParamList.get('bookonline') ? queryParamList.get('bookonline') : null,
      gender: queryParamList.get('gender') ? queryParamList.get('gender').substring(0, 1) : null,
      languages: queryParamList.get('languages') ? queryParamList.get('languages') : null,
      videoCalls: queryParamList.get('videovisits') ? queryParamList.get('videovisits') : null,
      medGroups: queryParamList.get('medgroup')
        ? queryParamList.get('medgroup')
        : medGroupFromSession?.length > 0
          ? medGroupFromSession
          : null,
      specialties: queryParamList.get('specialties') ? queryParamList.get('specialties') : null,
      allVisits: queryParamList.get('allVisits') ? queryParamList.get('allVisits') : null,
    };
  }

  providerResponseSwitchMapFunc(providerSearchResponse: ApiResponse<ProviderSearchResponse>) {
    if (!providerSearchResponse?.isValid || providerSearchResponse.result.providers.length === 0) {
      return of(providerSearchResponse);
    }
    const providerNpis = providerSearchResponse.result.providers.map((p) => p.npi);
    this.ratingService
      .getProviderRatings(providerNpis)
      .subscribe((ratings) => this.providerResponseMapFunc(ratings, providerSearchResponse));
  }

  providerResponseMapFunc(ratings: ProviderRating[], providerSearchResponse: ApiResponse<ProviderSearchResponse>) {
    for (const provider of this.providerResults.filter(item => providerSearchResponse.result.providers.map((p) => p.npi).includes(item.npi))) {
      provider.rating = ratings.find((x) => x.id === provider.npi.toString());
      provider.loadingRating = false;
    }
    return this.providerResults;
  }

  setTileTags(routeSnapshot: ActivatedRouteSnapshot, searchType: string): void {
    let keyword = '';
    let selectedLocation = '';
    if(routeSnapshot) {
      if (searchType === 't') {
        keyword = routeSnapshot.paramMap.get('searchTerm');
      } else if (searchType === 'l') {
        selectedLocation = routeSnapshot.paramMap.get('location');
      } else if (searchType === 'r' || searchType === 's') {
        keyword = routeSnapshot.paramMap.get('searchTerm'); 
        selectedLocation = routeSnapshot.paramMap.get('location');
      }
    }

    this.titleTagHandlerService.setPageTitle(keyword, selectedLocation, routeSnapshot);
    this.titleTagHandlerService.setPageCanonicals(window.location.href);
  }

  setSortByDropdownDisplayName(): void {
    this.configurationService.getSortingConfig().options.forEach(option => {
      if(option.label.toLowerCase() === this.selectedSortValue.toString().toLowerCase()
      && !this.configurationService.useLegacyAPI()) {
        this.searchFilters.sortBy = option.value;
      }
    });
  }

  backSiteSearch() : void {
    window.location.href = this.backtoSearchSiteLink;
  }
   
  getSearchFrom(): string {
    return (this.searchFrom != '') 
      ? this.searchFrom 
      : '';
  }

  addClassSearchResultsWithSidePanel(): boolean {
    return (this.isFiltersLeftSidePanelRequired && this.isDesktopViewport);
  }

  showOldSortOption(): boolean {
    return  (!this.isFiltersLeftSidePanelRequired) || (this.resizeService.isDesktopViewport && this.isFiltersLeftSidePanelRequired);
  }
}
