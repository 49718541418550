<div class="credentials-container">
  <div class="provider-credentials fad-col-sm-12">
    <h2>Credentials<br /></h2>
    <div class="cred-column" *ngIf="provider.certifications.length > 0">
      <div class="certifications-container">
        <div class="certifications-header">
          <h3>Board Certifications<br /></h3>
        </div>
        <cs-expandable-list [isCredentialsSection]="true" [listItems]="provider.certifications" [useColumns]="false"></cs-expandable-list>
      </div>
    </div>
    <div class="cred-column" *ngIf="educationArray.length > 0">
      <div class="education-container">
        <div class="education-header">
          <h3>Education<br /></h3>
        </div>
        <cs-expandable-list [isCredentialsSection]="true" [listItems]="educationArray" [useColumns]="false"></cs-expandable-list>
      </div>
    </div>
    <div class="cred-column" *ngIf="memberships.length > 0">
      <div class="membership-container">
        <div class="membership-header">
          <h3>Memberships<br /></h3>
        </div>
        <cs-expandable-list [isCredentialsSection]="true" [listItems]="memberships" [useColumns]="false"></cs-expandable-list>
      </div>
    </div>
  </div>
</div>
