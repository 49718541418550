import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cs-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent {
  // Add new icons by adding a new union of string literals. Ex: 'videocam' | 'checkbox' | 'up_arrow'
  // https://exploringjs.com/tackling-ts/ch_enum-alternatives.html#unions-of-string-literal-types
  @Input() name: 'videocam';
}
