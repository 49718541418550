import { Injectable } from '@angular/core';
import { FadEvent } from '../models/fad-event';
import { LogService } from '../services/log.service';

@Injectable({
  providedIn: 'root'
})
export class EventPublisherService {
  private eventName = 'CommonSpirit:FindADoctor';

  constructor(private logService: LogService) {}

  publish(event: FadEvent) {
    this.logService.debug('Event publish started.', this.eventName, event);
    window.dispatchEvent(new CustomEvent(this.eventName, { detail: event }));
  }
}
