import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiConstants } from 'src/app/constants/api-constants.new';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Provider } from '../../models/provider';
import { BlockitSessionService } from 'src/app/services/blockit-session.service';

@Component({
  selector: 'cs-doc-asap-booking-launcher',
  templateUrl: './doc-asap-booking-launcher.component.html',
  styleUrls: ['./doc-asap-booking-launcher.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocAsapBookingLauncherComponent {
  @Input() docAsapId: string;
  @Input() launcherButtonText = 'Start here';
  @Input() buttonSize: 'small' | 'medium' | 'large' = 'large';
  @Input() srcUrl: SafeResourceUrl;
  @Input() provider: Provider;
  showDocAsapLauncher = false;
  olsDepartmentId?: string;
  docUrl: SafeResourceUrl;
  olsProviderId?: string;
  isDocAsapLaunched = false;
  olsBlockIt?: string;
  isLoading: boolean;
  blockItUrl: string;

  constructor(public sanitizer: DomSanitizer, private configurationService: ConfigurationService, private blockitSessionService: BlockitSessionService) {
  }

  showLauncher() {
    this.olsDepartmentId = typeof this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsDepartmentId) !== 'undefined' ? this.provider?.externalSystems?.find(({systemName}) => systemName.toLowerCase() == ApiConstants.olsDepartmentId).systemId : null;
    this.olsProviderId = typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsProviderId) !== 'undefined' ? this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsProviderId).systemId : null;
    this.olsBlockIt = typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsBlockIt) !== 'undefined' ? this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsBlockIt).systemId : null;
    this.lauchTealiumEvent();
    if (this.isTouchDevice()) {
      if (this.olsDepartmentId && this.olsProviderId) {
        this.isLoading = true;
        this.showDocAsapLauncher = true;
        this.docUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${this.configurationService.getRuntimeConfiguration().myChartUrl}&dept=${this.olsDepartmentId.replace(/\s+/g, ' ').trim()}&id=${this.olsProviderId.replace(/\s+/g, ' ').trim()}`);
        this.isDocAsapLaunched = true;
        this.hideSpinner();
      }
      else if (this.olsBlockIt)
      {
        this.blockItUrl = `${sessionStorage.getItem('blockItUrl')}?slug=${this.olsBlockIt}`;
        window.open(this.blockItUrl);
      }
      else {
      window.open(
        `https://docasap.com/white-label/specialty_id/0/key_reason/-1/key_doc_id/${this.docAsapId}/key_level/3/key_type/INLINE/key_partner_code/${this.provider.ehrCode}/key_mobile_inline_button/1/iframeWidth/550/iframeHeight/300`
      );
      }
    } else {
      // Showing the iframe in the modal causes the current page
      // to be added to window.history which disrupts back button functionality.
      
      if (this.olsDepartmentId && this.olsProviderId)
      {
        this.isLoading = true;
        this.showDocAsapLauncher = true;
        this.docUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.configurationService.getRuntimeConfiguration().myChartUrl}&dept=${this.olsDepartmentId.replace(/\s+/g, ' ').trim()}&id=${this.olsProviderId.replace(/\s+/g, ' ').trim()}`
        );
        this.isDocAsapLaunched = true;
        this.hideSpinner();
      }
      else if (this.olsBlockIt) {
        this.blockItUrl = `${sessionStorage.getItem('blockItUrl')}?slug=${this.olsBlockIt}`;
        window.open(this.blockItUrl);
      }
      else {
        this.showDocAsapLauncher = true;
        this.docUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://docasap.com/white-label/specialty_id/0/key_reason/-1/key_doc_id/${this.docAsapId}/key_level/3/key_type/INLINE/key_partner_code/${this.provider.ehrCode}/key_mobile_inline_button/1/iframeWidth/550/iframeHeight/300`
        );
        this.isDocAsapLaunched = true;
      }
    }
  }

  isTouchDevice(): boolean {
    return window.matchMedia && window.matchMedia('(any-pointer: coarse)').matches;
  }

  handleCloseClicked() {
    this.showDocAsapLauncher = false;
    // When the modal is closed, trigger a window.history.back() call to pop the duplicate history for the page
    // you're currently on off the stack. Although we're going "back" the current page should not change.
    if(!this.olsDepartmentId && !this.olsProviderId)
    {
      if (!this.isDocAsapLaunched) {
        window.history.back();
      }
      this.isDocAsapLaunched = false;
    }
  }
  lauchTealiumEvent(): void {
    if (window['utag'] && window['utag'].link) {
      window['utag'].link({
        tealium_event: 'search and schedule:guided booking start',
        event_category: 'search and schedule',
        event_action: 'guided booking start',
        event_doctor_name: this.provider.displayFullName || '',
        event_doctor_npi: this.provider.npi || '',
        patientEngagement: 'true'
      });
    }
  }

  hideSpinner() {
    setTimeout(() => {
      this.isLoading = false;
    }, 10000);
  }
}
