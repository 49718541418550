export enum SessionObjectNames {
    SingleSpeciality = 'singleSpecialty',
    LocationSessionId = 'location-sessionId',
    IncludeProviderRoleTypes = 'includeProviderRoleTypes',
    SelectedMedicalGroup = 'selectedMedicalGroup',
    PreviousSelectedMediGrpPrvdRole = 'PreviousSelectedMediGrpPrvdRole',
    MedGroupQueryParam = 'medGroupQueryParam',
    ManualSelection = 'manualSelection',
    SelectedMedGroupFiltersModel = 'SelectedMedGroupFiltersModel',
    SingleSpecialityText = 'singleSpecialityText',
    SearchFrom = 'searchFrom',
    SingleInsurancesText = 'singleInsurancesText'
}

export enum QueryParamNames {
  Specialties = 'specialties',
  Gender = 'gender',
  Languages = 'languages',
  BookOnline = 'bookonline',
  AcceptingNew = 'acceptingnew',
  VideoVisits = 'videovisits',
  Distance = 'distance',
  AllVisits = 'allVisits',
  Insurances = 'insurances',
  Affiliations = "affiliations",
}
