<div class="emergency-care-container">
  <a href="tel:{{ emergencyNumber }}" class="p p emergency-copy" *ngIf="showEmergencyCopy"> {{emergencyCopy}} </a>
  <h3 class="emergency-care-copy" *ngIf="showEmergencyLinkContent">
    For non-life threatening emergencies, visit one of our
    <ng-container *ngFor="let category of emergencyCareLinkContent" [ngSwitch]="category.type">
      <a class="emergency-care-linktext" *ngSwitchCase="'erUrgent'" target="{{isCernerPage?'_blank':''}}" href="{{ category.linkScheme }}">
        ER or urgent care centers
      </a>
      <a class="emergency-care-linktext" *ngSwitchCase="'er'" href="{{ category.linkScheme }}">ER</a>
      <span class="emergency-care-linktext" *ngSwitchCase="'er'">or</span>
      <a class="emergency-care-linktext" *ngSwitchCase="'express'" href="{{ category.linkScheme }}">express care centers</a>
    </ng-container>
    near you.
  </h3>
  <div class="divider"></div>
</div>
