import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { ReplaySubject, Subject } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

export enum ViewPortSize {
  Desktop,
  Tablet,
  Mobile
}

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  viewPortChanged: ReplaySubject<ViewPortSize> = new ReplaySubject<ViewPortSize>(1);

  constructor(private eventManager: EventManager) {
    this.resizeSubject = new Subject<Window>();
    this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
    this.resizeSubject
      .asObservable()
      .pipe(
        startWith(window),
        map((window: Window) => window.innerWidth),
        tap((windowWidth: number) => {
          this.setViewportFlags(windowWidth);
        })
      )
      .subscribe();
  }

  private resizeSubject: Subject<Window>;

  /*
    Make these private and only return events that tell the consumer the current viewport.
    This will minimize the Angular change detection as we will only run component change detection
    when the viewport changes from one viewport to another.
   */
  public isDesktopViewport: boolean;
  public isTabletViewport: boolean;
  public isMobileViewport: boolean;

  private setViewportFlags(windowWidth: number) {
    let viewPortSize: ViewPortSize;
    if (windowWidth > 1024) {
      if (!this.isDesktopViewport) {
        viewPortSize = ViewPortSize.Desktop;
      }
      this.isDesktopViewport = true;
      this.isMobileViewport = this.isTabletViewport = false;
    } else if (windowWidth > 767 && windowWidth < 1025) {
      if (!this.isTabletViewport) {
        viewPortSize = ViewPortSize.Tablet;
      }
      this.isTabletViewport = true;
      this.isDesktopViewport = this.isMobileViewport = false;
    } else {
      if (!this.isMobileViewport) {
        viewPortSize = ViewPortSize.Mobile;
      }
      this.isMobileViewport = true;
      this.isDesktopViewport = this.isTabletViewport = false;
    }

    if (viewPortSize !== undefined) {
      this.onViewPortChanged(viewPortSize);
    }
  }

  public onViewPortChanged(viewPortSize: ViewPortSize) {
    this.viewPortChanged.next(viewPortSize);
  }

  private onResize(event: UIEvent) {
    this.resizeSubject.next(event.target as Window);
  }
}
