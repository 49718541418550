/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { RuntimeConfiguration } from './configuration-injection.service';
import { LogService } from '../services/log.service';

@Injectable()
export class TealiumJsUtagService {
  utag: any;

  constructor(private logService: LogService) {
    const w = window as any;
    this.utag = w.utag;
  }

  view(data?: any) {
    if ((window as any).utag === undefined) {
      this.logService.warn('Tealium config not set.');
    } else {
      (window as any).utag.view(data); /* eslint-disable-line */
    }
  }

  link(data?: any): void {
    if ((window as any).utag === undefined) {
      this.logService.warn('Tealium config not set.');
    } else {
      (window as any).utag.link(data); /* eslint-disable-line */
    }
  }

  public addScriptTag(runtimeConfig: RuntimeConfiguration): void {
    if (!runtimeConfig.tealiumScriptSrc || !!window['utag']) {
      return;
    }
    const bodyElement = document.querySelector('body');
    const scriptTag: HTMLScriptElement = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.innerHTML = `(function(a,b,c,d){
      a='${runtimeConfig.tealiumScriptSrc}';
      b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
      a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
    })();`;
    bodyElement?.appendChild(scriptTag);
  }
}
