import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Params, Router } from '@angular/router';
import { FiltersModel } from '../../../models/filters';
import { LocationConverter, LocationSuggestionResult, SelectedLocation } from '../../../models/location';
import { Provider } from '../../../models/provider';
import { CookieService } from '../../../services/cookie.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { FadUrlBuilder } from '../../../utils/url/fad-url-builder';
import { UrlBuilderParams } from '../../../utils/url/url-builder-params';
import { QuickLinksCategories } from '../../../models/configuration';
import { LocationService } from '../../../services/location.service';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/utils/api-response';
import { GlobalParams } from 'src/app/global-params';
import { SessionObjectNames } from 'src/app/models/stringconst-names';

@Component({
  selector: 'cs-fad-lite-search-input',
  templateUrl: './fad-lite-search-input.component.html',
  styleUrls: ['./fad-lite-search-input.component.scss']
})
export class FadLiteSearchInputComponent implements OnInit {
  @Input() keyword = '';
  @Input() selectedLocation: SelectedLocation;
  @Input() bookOnline = false;
  @Input() showQuicklinks = true;
  @Input() filtersModel: FiltersModel;
  @Input() fadBaseUrl = '';
  @Input() includeProviderRoleTypes = false;
  configShowQuickLinks: boolean;
  isValidSearchLocation = true;
  useNewProviderDetailsRoute;
  quickLinksCategories: QuickLinksCategories[] = [];
  quickLinksButtonAlignment = false;
  isLocationRequired: boolean;
  urlPrefix = 'find-a-doctor';
  includeProviderRoleTypesCheckbox = false;
  providerSearchActive = true;
  hideProviderSuggestions = false;
  autoSuggestionLoadingMessage = '';
  specialitySelected = false;
  showSearchBtn:boolean;
  showRoundBtn:string;
  isVMFHMarket:boolean;
  @ViewChild('searchFieldsGroup') searchFieldsGroup: ElementRef;
  @ViewChild('searchInputs') searchInputs: ElementRef;

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.searchInputs.nativeElement.contains(event.target)) {
      this.searchFieldsGroup.nativeElement.style.boxShadow = 'none';
    } else {
      if (window.innerWidth > 767) {
        this.searchFieldsGroup.nativeElement.style.boxShadow = '0px 2px 8px rgba(77, 82, 90, 0.3)';
      }
      if(this.showRoundBtn){
        this.searchFieldsGroup.nativeElement.style.boxShadow = 'none';
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (event.target.innerWidth < 768) {
      this.searchFieldsGroup.nativeElement.style.boxShadow = 'none';
    }
  }

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private configurationService: ConfigurationService,
    private urlBuilder: FadUrlBuilder,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.configShowQuickLinks = this.configurationService.showquickLinks();
    this.useNewProviderDetailsRoute = this.configurationService.UseNewProviderDetailsRoute();

    this.quickLinksCategories = this.configurationService.quickLinksCategories();
    this.urlPrefix = this.configurationService.getAemFadUrlPathName();

    if (location.pathname?.includes('/fad-lite')) {
      this.urlPrefix = location.pathname.replace('/fad-lite', '');
      if (this.urlPrefix.startsWith('/')) {
        this.urlPrefix = this.urlPrefix.substring(1);
      }
    }
    this.quickLinksButtonAlignment = this.configurationService.ShowButtonsbyRows();
    this.includeProviderRoleTypesCheckbox = this.configurationService.includeProviderRoleCheckBox();
    this.autoSuggestionLoadingMessage = this.configurationService.getAutoSuggestionLoadingMessage();
    this.showSearchBtn = this.configurationService.showSearchButton();
    this.showRoundBtn= this.configurationService.getSearchButton();
    this.isVMFHMarket = this.configurationService.getMarketCodes().includes('vmfh');
  }

  getQuickLinksResults(quickLink: QuickLinksCategories): void {
    switch (quickLink.type.toLowerCase()) {
      case 'keywords':
        this.quickLinksKeywordsSearch(quickLink.value);
        break;
      case 'specialities':
        this.quickLinksSpecialitiesSearch(quickLink.value);
        break;
      case 'affiliations':
        this.quickLinksHospitalAffiliationsSearch(quickLink.value);
        break;
      default:
        this.quickLinksSearch(quickLink.type);
        break;
    }
  }

  private quickLinksKeywordsSearch(keywords: string) {
    this.resetAndSetKeywords(keywords);
    const queryParams: Params = {};

    this.buildSearchAndNavigate(queryParams);
  }

  private quickLinksSpecialitiesSearch(specialties: string) {
    this.resetAndSetKeywords('');
    const queryParams: Params = {};
    if (specialties.includes('|')) {
      queryParams.specialties = specialties;
      this.buildSearchAndNavigate(queryParams);
    } else {
      this.keyword = specialties;
      this.buildSearchAndNavigate(queryParams, true);
    }
  }

  private quickLinksHospitalAffiliationsSearch(affiliations: string) {
    this.resetAndSetKeywords('');
    const queryParams: Params = {};

    queryParams.affiliations = affiliations.replace("'", '');

    this.buildSearchAndNavigate(queryParams);
  }

  private quickLinksSearch(quickLinkType: string) {
    this.resetAndSetKeywords('');
    const queryParams: Params = {};
    if (quickLinkType === 'videovisits') {
      queryParams.videovisits = true;
    }
    if (quickLinkType === 'bookonline') {
      queryParams.bookonline = true;
    }
    if (quickLinkType === 'acceptingnew') {
      queryParams.acceptingnew = true;
    }
    this.buildSearchAndNavigate(queryParams);
  }

  /**
   * The way this is written, it's possible to double navigate. It would be better to have one thing that builds up the urls,
   * and only navigate once. Do not call navigate more than once in the method.
   */

  keyEnterHandler(value): void {
    this.specialitySelected = false;
    this.getSearchResults(value);
  }

  getSearchResults(searchTerm = ''): void {
    this.isValidSearchLocation=true;
    this.resetAndSetKeywords(searchTerm !== '' ? searchTerm : this.keyword);
    const queryParams: Params = {};

    if (this.keyword.trim().toLowerCase().replace(/\s/g, '') === 'videovisits') {
      queryParams.videovisits = true;
      this.keyword = '';
    }

    if (
      this.selectedLocation?.city &&
      this.selectedLocation?.state &&
      this.filtersModel.providerMedicalGroup &&
      this.filtersModel.providerMedicalGroup.length > 0
    ) {
      queryParams.location = `${this.selectedLocation.city.toLowerCase()}-${this.selectedLocation.state.toLowerCase()}`;
    } else if (this.selectedLocation?.name && this.filtersModel.providerMedicalGroup && this.filtersModel.providerMedicalGroup.length > 0) {
      queryParams.location = `${this.selectedLocation.name.toLowerCase()}`;
    }

    if (
      this.keyword.trim().toLowerCase().replace(/\s/g, '') === 'bookonline' ||
      this.keyword.trim().toLowerCase().replace(/\s/g, '') === 'onlinescheduling'
    ) {
      queryParams.bookonline = true;
      this.keyword = '';
    }
    if (this.keyword.trim().toLowerCase().replace(/\s/g, '') === 'acceptingnewpatients') {
      queryParams.acceptingnew = true;
      this.keyword = '';
    }
    if (this.includeProviderRoleTypes) {
      queryParams.includeProviderRoleTypes = this.includeProviderRoleTypes;
    }
    this.filtersModel.pageNumber = 1;
    if(this.specialitySelected) {
      window.sessionStorage.removeItem(SessionObjectNames.LocationSessionId);
    }
    this.buildSearchAndNavigate(queryParams, this.specialitySelected);
  }

  goToProvider($event: Provider): void {
    if (this.useNewProviderDetailsRoute) {
      this.navigateToFad(
        `/${
          this.urlPrefix
        }/${$event.primarySpecialty.makeURLFriendly()}/${$event.firstName.makeURLFriendly()}-${$event.lastName.makeURLFriendly()}-${
          $event.npi
        }`
      );
      return;
    }
    this.navigateToFad(`/${this.urlPrefix}/${$event.npi}-${$event.firstName.makeURLFriendly()}-${$event.lastName.makeURLFriendly()}`);
  }

  private resetAndSetKeywords(keywords: string) {
    window.sessionStorage.removeItem(SessionObjectNames.LocationSessionId);
    this.keyword = keywords;
    if (!this.filtersModel?.providerMedicalGroup) {
      this.filtersModel = new FiltersModel(this.configurationService);
    }
  }

  private buildSearchAndNavigate(queryParams: Params, specialtySearch = false) {
    if (!this.selectedLocation?.name) {
      this.isLocationRequired = this.configurationService.getLocationRequiredDefaultValue();
      if (this.isLocationRequired) {
        this.selectedLocation = LocationConverter.convertTo(this.cookieService.getLocationCookieFromBrowser());
        if (!this.selectedLocation) {
          this.locationService
            .getLocationByIP()
            .pipe(
              map((response) => {
                return LocationConverter.convertTo(response.result.location);
              })
            )
            .subscribe((defaultLocation) => {
              this.selectedLocation = defaultLocation;
              this.validateLocation(queryParams, this.selectedLocation, specialtySearch);
            });
        } else {
          this.validateLocation(queryParams, this.selectedLocation, specialtySearch);
        }
      } else {
        this.validateLocation(queryParams, this.selectedLocation, specialtySearch);
      }
    } else {
      this.validateLocation(queryParams, this.selectedLocation, specialtySearch);
    }
  }

  private validateLocation(queryParams: Params, location: SelectedLocation, specialtySearch = false): void {
    if (!!location && location?.name !== '') {
      this.locationService
        .getTypedLocationSuggestions(location.name)
        .subscribe((suggestionResponse: ApiResponse<LocationSuggestionResult[]>) => {
          GlobalParams.isNeighborhoodSearch = this.checkForNeighborhoodLocation(location);

          if (suggestionResponse.isValid && suggestionResponse.result.length > 0) {
            this.isValidSearchLocation = true;
            this.navigateRoute(queryParams, this.selectedLocation, specialtySearch);
          } else {
            this.isValidSearchLocation = false;
          }
        });
    } else if ((location === undefined || location?.name === '' || location == null) && !this.isLocationRequired) {
      this.isValidSearchLocation = true;
      this.navigateRoute(queryParams, this.selectedLocation, specialtySearch);
    } else {
      this.isValidSearchLocation = false;
    }
  }

  private navigateRoute(queryParams: Params, location: SelectedLocation, specialtySearch = false): void {
    this.cookieService.addLocationCookieToBrowser(location);
    const url = this.urlBuilder.build(
      {
        medGroup: '',
        searchTerm: specialtySearch ? null : this.keyword,
        selectedLocation: location,
        specialty: specialtySearch ? this.keyword : null
      } as UrlBuilderParams,
      this.fadBaseUrl
    );

    if (specialtySearch) {
      this.keyword = '';
    }
    if (url.startsWith('/')) {
      this.navigateToFad(this.urlPrefix + url, queryParams);
    } else {
      this.navigateToFad(this.urlPrefix + '/' + url, queryParams);
    }
  }

  navigateToFad(url: string, queryParams: Params = null): void {
    const paramString = queryParams
      ? Object.entries(queryParams)
          .map((pair) => pair.map(encodeURIComponent).join('='))
          .join('&')
      : null;
    const finalUrl = url + (paramString ? '?' + paramString : '');
    window.location.href = finalUrl;
  }

  goToFilters($event): void {
    this.filtersModel = new FiltersModel(this.configurationService);
    this.keyword = `${$event}`;
    this.specialitySelected = true;
  }

  checkForNeighborhoodLocation(location: SelectedLocation): boolean {
    if (location.neighborhood || (location.name && location.name.split(',').length >= 3)) {
      return true;
    } else {
      return false;
    }
  }

  providerRoleTypesSelected($event: boolean): void {
    this.includeProviderRoleTypes = $event;
    sessionStorage.setItem('includeProviderRoleTypes', String($event));
    this.getSearchResults();
  }

  setProviderSearchActive(): void {
    this.providerSearchActive = true;
    this.hideProviderSuggestions = false;
  }

  closeProviderAutoSuggestionContainer(): void {
    if(this.providerSearchActive) {
      this.hideProviderSuggestions = true;
    }
  }
}
