import { Component, OnInit } from '@angular/core';
import { LogoConstants } from 'src/app/constants/logo-constants';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FadLinkService } from 'src/app/services/fad-link.service';

@Component({
  selector: 'cs-medical-copy',
  templateUrl: './medical-copy.component.html',
  styleUrls: ['./medical-copy.component.scss']
})
export class MedicalCopyComponent implements OnInit {
  constructor(private configurationService: ConfigurationService,  private fadUrl: FadLinkService) {}
  logoLegendHeader:string;
  LogoConstantsObj = LogoConstants;
  medGroupList: unknown[];

  ngOnInit(): void {    
    this.logoLegendHeader = this.configurationService.getLogoLegendHeader();
    this.setMedicalGroupList();
  }

  provideMedicalGroupList(uniqueList):any[]{
    let list = [];
    if (
      !window.location.host.toLowerCase().includes(this.LogoConstantsObj.fad) &&
      !window.location.host.toLowerCase().includes(this.LogoConstantsObj.mycareteam) &&
      !window.location.host.toLowerCase().includes(this.LogoConstantsObj.chart) &&
      !this.fadUrl.isSelfHosted()
    ) {      
      list = uniqueList?.map(item => ({logoUrl: item.logoPathAEM, logoDescription: item.logoDescription}));
    } else {
      const fadBaseUrl = this.configurationService.getRuntimeConfiguration().fadBaseUrl;
      const domain = (new URL(fadBaseUrl));
      list = uniqueList?.map(item => ({logoUrl: `${domain.origin}${item.logoPathFAD}`, logoDescription: item.logoDescription}));
    } 
    return list;
  }

  setMedicalGroupList() : void {
    const medicalGroups = this.configurationService.getMedGroupCodes();

    const list = new Set();
    const uniqueList = [...new Set(medicalGroups?.filter(item => {
      if(!list.has(item.logoPathFAD)){
        list.add(item.logoPathFAD);
        return item;
      }
    }))];       
    this.medGroupList = this.provideMedicalGroupList(uniqueList);
  }
}
