import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'cs-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {
  showTooltip: boolean;
  @Input() bottom;
  @Input() left;
  @Input() toolTipInfo = '';
  @ViewChild('tooltipContainer') tooltipContainer: ElementRef;
  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.tooltipContainer.nativeElement.contains(event.target)) {
      this.showTooltip = false;
    }
  } 
  onFocusLost(event: any) {
      this.showTooltip = false;
  }
}
