declare global {
  interface String {
    equalsIgnoreDash(other: string, ignoreCase?: boolean): boolean;
    stripDash(replaceWith?: string): string;
    toUrlFriendly(): string;
    fromUrlFriendly(): string;
    toTitleCase(): string;
    trimTrailingForwardSlash(): string;
    trimForwardSlashes(): string;
    stripPeriod(): string;
    sanitizeSpaces(): string;
    sanitizeHyphens(): string;
    stripParentheses(): string;
    trimForwardSlash(): string;
    replaceAmpersandwithHyphen(): string;
    makeURLFriendly(): string;
    removeSpecialCharacters(): string;
    replaceWithDash(searchWith?: string): string;
  }
}

String.prototype.equalsIgnoreDash = function (this: string, other: string, ignoreCase = true) {
  if (this === other) {
    return true;
  }
  let thisString = this.stripDash();
  let otherString = other.stripDash();
  if (ignoreCase) {
    thisString = thisString.toLowerCase();
    otherString = otherString.toLowerCase();
  }
  return thisString === otherString;
};

String.prototype.stripDash = function (this: string, replaceWith = '') {
  return this.replace(/-/g, replaceWith);
};

String.prototype.replaceWithDash = function (this: string, searchWith = '') {
  const searchExp = new RegExp(searchWith,'g');
  return this.length > 0 ? this.replace(searchExp, '-') : this;
};


String.prototype.toUrlFriendly = function (this: string) {
  return this.toLowerCase().replace(/ /g, '-');
};

String.prototype.fromUrlFriendly = function (this: string) {
  return this.toLowerCase().replace(/-/g, ' ');
};

String.prototype.toTitleCase = function (this: string) {
  return this.split(' ')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
    .join(' ');
};

String.prototype.trimTrailingForwardSlash = function (this: string) {
  return this.replace(/\/+$/g, '');
};

String.prototype.trimForwardSlashes = function (this: string) {
  return this.replace(/^\/+|\/+$/g, '');
};

String.prototype.stripPeriod = function (this: string) {
  return this.replace(/\./g, '');
};

String.prototype.sanitizeSpaces = function (this: string) {
  return this.replace(/  +/g, ' ');
};

String.prototype.sanitizeHyphens = function (this: string) {
  return this.replace(/--+/g, '-');
};

String.prototype.stripParentheses = function (this: string) {
  return this.replace(/\(|\)/g, '');
};

String.prototype.trimForwardSlash = function (this: string) {
  return this.replace(/\//g, '-');
};

String.prototype.replaceAmpersandwithHyphen = function (this: string) {
  return this.replace(/&/g, '-');
};

String.prototype.removeSpecialCharacters = function (this: string) {
  return this.replace(/[^a-zA-Z0-9-]/g, '');
};

String.prototype.makeURLFriendly = function (this: string) {
  return this.trim()
    .sanitizeSpaces()
    .stripParentheses()
    .stripPeriod()
    .trimForwardSlash()
    .replaceAmpersandwithHyphen()
    .toUrlFriendly()
    .sanitizeHyphens();
};
export {};
