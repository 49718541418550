import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FadLiteSearchLandingComponent } from './fad-lite-search-landing.component';
import { SharedModule } from 'src/app/components/shared.module';
import { FadLiteLocationSearchComponent } from 'src/app/components/fad-lite/fad-lite-location-search/fad-lite-location-search.component';
import { FadLiteProviderSearchComponent } from 'src/app/components/fad-lite/fad-lite-provider-search/fad-lite-provider-search.component';
import { FadLiteQuicklinksSearchComponent } from 'src/app/components/fad-lite/fad-lite-quicklinks-search/fad-lite-quicklinks-search.component';
import { FadLiteSearchInputComponent } from 'src/app/components/fad-lite/fad-lite-search-input/fad-lite-search-input.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [FadLiteSearchLandingComponent,
    FadLiteLocationSearchComponent,
    FadLiteProviderSearchComponent,
    FadLiteQuicklinksSearchComponent,
    FadLiteSearchInputComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: FadLiteSearchLandingComponent
      }
    ])
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FadLiteSearchLandingModule {}
