<div class="cs-tooltip-container" #tooltipContainer>
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    [attr.aria-label]="toolTipInfo"
    tabindex="0"
    (click)="showTooltip = !showTooltip; $event.stopPropagation()"
    (keyup.enter)="showTooltip = !showTooltip; $event.stopPropagation()"
    (blur)="onFocusLost($event)"
    role="button"
    aria-describedby="tooltip-content"
  >
    <path
      d="M10 0C4.47754 0 0 4.47915 0 10C0 15.5241 4.47754 20 10 20C15.5225 20 20 15.5241 20 10C20 4.47915 15.5225 0 10 0ZM10 4.43548C10.9353 4.43548 11.6935 5.19371 11.6935 6.12903C11.6935 7.06435 10.9353 7.82258 10 7.82258C9.06468 7.82258 8.30645 7.06435 8.30645 6.12903C8.30645 5.19371 9.06468 4.43548 10 4.43548ZM12.2581 14.6774C12.2581 14.9446 12.0414 15.1613 11.7742 15.1613H8.22581C7.95859 15.1613 7.74194 14.9446 7.74194 14.6774V13.7097C7.74194 13.4425 7.95859 13.2258 8.22581 13.2258H8.70968V10.6452H8.22581C7.95859 10.6452 7.74194 10.4285 7.74194 10.1613V9.19355C7.74194 8.92633 7.95859 8.70968 8.22581 8.70968H10.8065C11.0737 8.70968 11.2903 8.92633 11.2903 9.19355V13.2258H11.7742C12.0414 13.2258 12.2581 13.4425 12.2581 13.7097V14.6774Z"
    />
  </svg>
  <span
    [ngStyle]="{ bottom: bottom != null ? bottom : '15px', left: left != null ? left : '-20px' }"
    class="cs-tooltip-content"
    *ngIf="showTooltip"
    (click)="$event.stopPropagation()"
    role="tooltip"
    aria-live="polite"
    id="tooltip-content"
  >
    <ng-content></ng-content>
  </span>
</div>
