import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FacetSummary, FiltersModel, FiltersModelPartialHelper } from '../../models/filters';
import { AvailabilitySelect } from '../../models/availability-select.enum';
import { ResizeService } from '../../services/resize.service';
import { SelectedLocation } from '../../models/location';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FilterService } from 'src/app/services/filter.service';
import { clearAllSessionVariables } from 'src/app/utils/utils';

@Component({
  selector: 'cs-filters-left-panel',
  templateUrl: './filters-left-panel.component.html',
  styleUrls: ['./filters-left-panel.component.scss']
})
export class FiltersLeftPanelComponent implements OnInit {
  @Input() selectedLocation: SelectedLocation;
  @Input() filtersModel: FiltersModel;

  @Input() medicalGroupToolTip: string;
  @Input() medicalGroupHoverText: string;
  @Input() affiliationToolTip: string;
  @Input() affiliationHoverText: string;
  @Input() defaultSearchDistance: number;
  @Input() defaultSearchDistanceWithMedGroup: number;

  @Input() showOnlineSchedulingFilter: boolean;
  @Input() showVideoVisitsFilter: boolean;
  @Input() showAcceptingNewPatientsFilter: boolean;
  @Input() showAvailabilityFilter: boolean;
  @Input() showSpecialtyFilter: boolean;
  @Input() showMedicalGroupFilter: boolean;
  @Input() showAffiliationFilter: boolean;
  @Input() showProceduresPerformedFilter: boolean;
  @Input() showConditionsTreatedFilter: boolean;

  @Input() showGenderFilter: boolean;
  @Input() showInsuranceFilter: boolean;
  @Input() showLanguageFilter: boolean;
  @Input() showSearchRadiusFilter: boolean;

  @Input() embedSource: string;
  @Input() multipleMedicalGroupSelection: boolean;

  @Output() filtersModelChange = new EventEmitter<FiltersModel>();
  @Output() medicalGroupChange = new EventEmitter<FiltersModel>();
  @Output() addMedicalGroupItems = new EventEmitter<FiltersModel>();
  @Output() locationNameClosed = new EventEmitter<FiltersModel>();

  FiltersModelPartialHelperLocal = FiltersModelPartialHelper;
  isHoveringOnMedGroup = false;
  isHoveringOnAffiliation = false;
  showModal = false;
  closeOnOverlay = true;
  AvailabilitySelect = AvailabilitySelect;
  LastVideoVisitsProvidersCount = 0;
  isDesktopFilterSelected = false;
  useLegacyAPI = false;
  searchRadiusList = [{
    label: "5 miles",
    value: 5,
  },{
    label: "10 miles",
    value: 10,
  },{
    label: "25 miles",
    value: 25,
  },{
    label: "50 miles",
    value: 50,
  },{
    label: "100 miles",
    value: 100
  }];
  medicalGroupList: FacetSummary[] = [];
  searchRadiusSelectedValue: number;
  onlineSchedulingToggle: boolean;
  videoVisitsToggle: boolean;
  newPatientsToggle: boolean;
  showLocationName = true;
  isProviderAvailabilityCollapsed = true;
  

  constructor(private resizeService: ResizeService
    , private configurationService: ConfigurationService
    , private filterService: FilterService) {
      this.useLegacyAPI =  this.configurationService.useLegacyAPI()
  }

  get isDesktopViewport(): boolean {
    return this.resizeService.isDesktopViewport;
  }

  ngOnInit(): void {
    if (this.filtersModel) {
      this.filtersModel.medicalGroupList = FiltersModelPartialHelper.getMedGroups(this.configurationService.getMedGroupCodes());
      this.searchRadiusSelectedValue = FiltersModelPartialHelper.getDistanceForProviderSearchRadiusKey(this.filtersModel) || this.defaultSearchDistance;
      this.convertMedicalGroupToFacetList();
      if (this.filtersModel.selectedMedicalGroup == null) {
        this.filtersModel.selectedMedicalGroup = [];
        this.filtersModel.providerMedicalGroup = [];
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filtersModel.currentValue.searchDone) {
      this.LastVideoVisitsProvidersCount = 0;
    }
    if (changes.filtersModel.currentValue.medicalGroupList) {
      this.convertMedicalGroupToFacetList();
    }
    if (changes.filtersModel.currentValue.providerSearchRadius) {
      this.searchRadiusSelectedValue = FiltersModelPartialHelper.getDistanceForProviderSearchRadiusKey(this.filtersModel) || this.defaultSearchDistance;
    }
    if (changes.filtersModel.currentValue.onlineSchedulingToggle) {
      this.onlineSchedulingToggle = this.filtersModel.onlineSchedulingToggle;
    }
    if (changes.filtersModel.currentValue.videoVisitsToggle) {
      this.videoVisitsToggle = this.filtersModel.videoVisitsToggle;
    }
    if (changes.filtersModel.currentValue.newPatientsToggle) {
      this.newPatientsToggle = this.filtersModel.newPatientsToggle;
    }
    if (changes.filtersModel.currentValue.availabilitySelect) {
      this.isProviderAvailabilityCollapsed = false;
    }
  }

  filterOnlineScheduling(): void {
    if(this.filtersModel.onlineSchedulingProvidersCount != 0){
      this.onlineSchedulingToggle = !this.onlineSchedulingToggle;
      this.filtersModel.onlineSchedulingApplied = this.filtersModel.onlineSchedulingToggle = this.onlineSchedulingToggle;
      this.filtersModelChange.emit(this.filtersModel);
    }
  }

  filterVideoVisits(): void {
    if(this.filtersModel.videoVisitsProvidersCount != 0){
      this.videoVisitsToggle = !this.videoVisitsToggle;
      this.LastVideoVisitsProvidersCount = this.filtersModel.videoVisitsProvidersCount;
      this.filtersModel.videoVisitsApplied = this.filtersModel.videoVisitsToggle = this.videoVisitsToggle;
      this.filtersModelChange.emit(this.filtersModel);
    }
  }

  filterNewPatients(): void {
    if(this.filtersModel.newPatientsProvidersCount != 0){
      this.newPatientsToggle = !this.newPatientsToggle;
      this.filtersModel.newPatientsApplied = this.filtersModel.newPatientsToggle = this.newPatientsToggle;
      this.filtersModelChange.emit(this.filtersModel);
    }
  }

  filterLocationName(): void {
    this.showLocationName = false;
    this.filtersModel.locationName = "";
    this.locationNameClosed.emit(this.filtersModel);
  }

  filterAvailability(filteringActive: boolean): void {
    if (filteringActive) {
      this.filtersModel.providerAvailability = this.filtersModel.availabilitySelect;
      this.filtersModel.availabilityTitle = this.filtersModel.providerAvailability;
      if (this.filtersModel.availabilityTitle === '') {
        this.filtersModel.availabilityTitle = 'Availability';
      }
    } else {
      this.filtersModel.providerAvailability = AvailabilitySelect.None;
      this.filtersModel.availabilitySelect = AvailabilitySelect.None;
      this.filtersModel.availabilityTitle = 'Availability';
    }
    this.filtersModelChange.emit(this.filtersModel);
  }

  clearMedGroupFilters(): void {
    this.filtersModel.selectedMedicalGroup = [];
    this.filtersModel.providerMedicalGroup = [];
    this.addMedicalGroupItems.emit(this.filtersModel);
    this.medicalGroupChange.emit(this.filtersModel);
  }

  filterGender(gender: string): void {
    FiltersModelPartialHelper.genderSelect(this.filtersModel, gender);
    this.filtersModel.providerGender = this.filtersModel.genderSelect;
    this.filtersModelChange.emit(this.filtersModel);
  }

  filterSearchRadius(event): void {
    if (event) {
      this.searchRadiusSelectedValue = event.value;
      this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius = FiltersModelPartialHelper.getRadiusKeyForDistance(event.value);
      this.filtersModelChange.emit(this.filtersModel);
    }
  }

  clearDesktopFilters(): void {
    clearAllSessionVariables();
    this.clearMedGroupFilters();
    FiltersModelPartialHelper.clearDesktopFilters(this.filtersModel, this.defaultSearchDistance);
    this.filtersModel.selectedHospitals = [];
    this.filtersModel.providerHospitals = [];
    this.onlineSchedulingToggle = false;
    this.videoVisitsToggle = false;
    this.newPatientsToggle = false;
    this.searchRadiusSelectedValue = this.defaultSearchDistance;
    this.filtersModelChange.emit(this.filtersModel);
  }

  selectedSpecialityValues(items: FacetSummary[]): void {
    this.filtersModel.selectedSpecialties = [...items];
    this.filtersModel.providerSpecialties = [...items];
    if (this.filtersModel.selectedSpecialties.length === 1 && (window.sessionStorage.getItem('singleSpecialty')?.toLowerCase() === this.filtersModel.selectedSpecialties?.[0].name.toLowerCase())) {
      window.sessionStorage.removeItem('singleSpecialty');
    }
    this.filtersModelChange.emit(this.filtersModel);
  }

  selectedHospitalAffliations(items: FacetSummary[]): void {
    this.filtersModel.selectedHospitals = [...items];
    this.filtersModel.providerHospitals = [...items];
    this.filtersModelChange.emit(this.filtersModel);
  }

  selectedLanguages(items: FacetSummary[]): void {
    this.filtersModel.selectedLanguages = [...items];
    this.filtersModel.providerLanguages = [...items];
    this.filtersModelChange.emit(this.filtersModel);
  }

  selectedInsurances(items: FacetSummary[]): void {
    this.filtersModel.selectedInsurances = [...items];
    this.filtersModel.providerInsurances = [...items];
    this.filtersModelChange.emit(this.filtersModel);
  }

  selectedMedicalValues(items: FacetSummary[]): void {
    this.filtersModel.selectedMedicalGroup = [...items];
    this.filterService.handleManualProviderRoleSelectionProcess(this.filtersModel);
    this.filtersModel.providerMedicalGroup = [...items];
    this.medicalGroupChange.emit(this.filtersModel);
    this.markSelectedItemsInList();
  }

  convertMedicalGroupToFacetList(): void {
    this.medicalGroupList = this.filtersModel.medicalGroupList.map(medGroup => ({
      name: medGroup.label,
      count: 0,
      selected: false,
      code: medGroup.value
    }));
    this.markSelectedItemsInList();
  }

  markSelectedItemsInList(): void {
    if (this.filtersModel.selectedMedicalGroup) {
      this.filtersModel.selectedMedicalGroup.forEach(item => {
        const index = this.medicalGroupList.findIndex(t => t.code === item.name);
        if (index !== -1 ) { 
          this.medicalGroupList[index].selected = item.selected;
        }
      });
    }
  }
  
}
