import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cs-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent {
  @Input() title: string;
  @Input() tooltip: string;
  @Input() left;
  @Input() tooltipInfo = '';
  @Input() isCollapsed = true;

}
