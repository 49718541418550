<div *ngIf="!isPrint && !isUpdatedProviderDetails" class="badges" [class.badges-new]="!showImprovedUX">
  <div class="badge-offers-video-visits appt-badge" *ngIf="!showImprovedUX && provider.acceptsVideoCalls">
    <svg viewBox="0 0 23 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4247 0H1.90868C0.854514 0 0 0.835938 0 1.86719V13.1328C0 14.1641 0.854514 15 1.90868 15H13.4247C14.4788 15 15.3333 14.1641 15.3333 13.1328V1.86719C15.3333 0.835938 14.4788 0 13.4247 0ZM20.9875 1.47266L16.6111 4.42578V10.5742L20.9875 13.5234C21.834 14.0938 23 13.5117 23 12.5156V2.48047C23 1.48828 21.838 0.902344 20.9875 1.47266Z"
      />
    </svg>
    <span class="p p2">Offers video visits</span>
  </div>
  <div class="badge-offers-video-visits-new appt-badge" *ngIf="showImprovedUX &&  provider.acceptsVideoCalls">
    <svg viewBox="0 0 23 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4247 0H1.90868C0.854514 0 0 0.835938 0 1.86719V13.1328C0 14.1641 0.854514 15 1.90868 15H13.4247C14.4788 15 15.3333 14.1641 15.3333 13.1328V1.86719C15.3333 0.835938 14.4788 0 13.4247 0ZM20.9875 1.47266L16.6111 4.42578V10.5742L20.9875 13.5234C21.834 14.0938 23 13.5117 23 12.5156V2.48047C23 1.48828 21.838 0.902344 20.9875 1.47266Z"
      />
    </svg>
    <span class="p p2">Offers video visits</span>
  </div>
  <div class="badge-online-scheduling appt-badge p p2" *ngIf="setOnlineScheduleBadge()">
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.42188 11.7031C5.73438 12.0156 6.26563 12.0156 6.57813 11.7031L15.7656 2.51562C16.0781 2.20312 16.0781 1.67188 15.7656 1.35938L14.6406 0.234375C14.3281 -0.078125 13.8281 -0.078125 13.5156 0.234375L6.01563 7.73438L2.48438 4.23438C2.17188 3.92188 1.67188 3.92188 1.35938 4.23438L0.234375 5.35938C-0.078125 5.67188 -0.078125 6.20313 0.234375 6.51563L5.42188 11.7031Z"
      />
    </svg>
    Online scheduling
  </div>
  <div class="badge-accepts-new-patients appt-badge p p2" *ngIf="provider.acceptsNewPatients || setAcceptNewPatientBadge()">
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.42188 11.7031C5.73438 12.0156 6.26563 12.0156 6.57813 11.7031L15.7656 2.51562C16.0781 2.20312 16.0781 1.67188 15.7656 1.35938L14.6406 0.234375C14.3281 -0.078125 13.8281 -0.078125 13.5156 0.234375L6.01563 7.73438L2.48438 4.23438C2.17188 3.92188 1.67188 3.92188 1.35938 4.23438L0.234375 5.35938C-0.078125 5.67188 -0.078125 6.20313 0.234375 6.51563L5.42188 11.7031Z"
      />
    </svg>
    Accepting new patients
  </div>
  <div class="badge-no-new-patients appt-badge p p2" *ngIf="!provider.acceptsNewPatients && !setAcceptNewPatientBadge()">
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.91028 5.62943L7.55848 5.98122L7.90852 6.33477L11.3128 9.77307L11.3207 9.78105L11.3289 9.78868C11.557 9.99921 11.557 10.3412 11.3289 10.5517L11.3216 10.5585L11.3145 10.5656L10.5656 11.3145L10.5585 11.3216L10.5517 11.3289C10.3412 11.557 9.99921 11.557 9.78868 11.3289L9.7819 11.3216L9.77483 11.3145L6.37057 7.91028L6.01878 7.55848L5.66523 7.90852L2.22693 11.3128L2.21895 11.3207L2.21132 11.3289C2.00079 11.557 1.65878 11.557 1.44825 11.3289L1.44147 11.3216L1.4344 11.3145L0.685468 10.5656L0.6784 10.5585L0.671055 10.5517C0.442982 10.3412 0.442981 9.99921 0.671055 9.78868L0.679314 9.78105L0.687223 9.77307L4.09148 6.33477L4.44152 5.98122L4.08972 5.62943L0.685468 2.22517L0.6784 2.2181L0.671055 2.21132C0.442982 2.00079 0.442982 1.65878 0.671055 1.44825L0.6784 1.44147L0.685468 1.4344L1.4344 0.685468L1.44147 0.6784L1.44825 0.671055C1.65878 0.442982 2.00079 0.442982 2.21132 0.671055L2.21895 0.679314L2.22693 0.687223L5.66523 4.09148L6.01878 4.44152L6.37057 4.08972L9.77483 0.685468L9.7819 0.6784L9.78868 0.671055C9.99921 0.442981 10.3412 0.442982 10.5517 0.671055L10.5585 0.6784L10.5656 0.685468L11.3145 1.4344L11.3216 1.44147L11.3289 1.44825C11.557 1.65878 11.557 2.00079 11.3289 2.21132L11.3216 2.2181L11.3145 2.22517L7.91028 5.62943Z"
      />
    </svg>
    Not accepting new patients
  </div>
</div>
<div *ngIf="isPrint && !isUpdatedProviderDetails" class="badges">
  <div class="badge-accepts-new-patients appt-badge p p2" *ngIf="provider.acceptsNewPatients || setAcceptNewPatientBadge()">
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.42188 11.7031C5.73438 12.0156 6.26563 12.0156 6.57813 11.7031L15.7656 2.51562C16.0781 2.20312 16.0781 1.67188 15.7656 1.35938L14.6406 0.234375C14.3281 -0.078125 13.8281 -0.078125 13.5156 0.234375L6.01563 7.73438L2.48438 4.23438C2.17188 3.92188 1.67188 3.92188 1.35938 4.23438L0.234375 5.35938C-0.078125 5.67188 -0.078125 6.20313 0.234375 6.51563L5.42188 11.7031Z"
      />
    </svg>
    Accepting new patients
  </div>
  <div class="badge-no-new-patients appt-badge p p2" *ngIf="!provider.acceptsNewPatients && !setAcceptNewPatientBadge()">
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.91028 5.62943L7.55848 5.98122L7.90852 6.33477L11.3128 9.77307L11.3207 9.78105L11.3289 9.78868C11.557 9.99921 11.557 10.3412 11.3289 10.5517L11.3216 10.5585L11.3145 10.5656L10.5656 11.3145L10.5585 11.3216L10.5517 11.3289C10.3412 11.557 9.99921 11.557 9.78868 11.3289L9.7819 11.3216L9.77483 11.3145L6.37057 7.91028L6.01878 7.55848L5.66523 7.90852L2.22693 11.3128L2.21895 11.3207L2.21132 11.3289C2.00079 11.557 1.65878 11.557 1.44825 11.3289L1.44147 11.3216L1.4344 11.3145L0.685468 10.5656L0.6784 10.5585L0.671055 10.5517C0.442982 10.3412 0.442981 9.99921 0.671055 9.78868L0.679314 9.78105L0.687223 9.77307L4.09148 6.33477L4.44152 5.98122L4.08972 5.62943L0.685468 2.22517L0.6784 2.2181L0.671055 2.21132C0.442982 2.00079 0.442982 1.65878 0.671055 1.44825L0.6784 1.44147L0.685468 1.4344L1.4344 0.685468L1.44147 0.6784L1.44825 0.671055C1.65878 0.442982 2.00079 0.442982 2.21132 0.671055L2.21895 0.679314L2.22693 0.687223L5.66523 4.09148L6.01878 4.44152L6.37057 4.08972L9.77483 0.685468L9.7819 0.6784L9.78868 0.671055C9.99921 0.442981 10.3412 0.442982 10.5517 0.671055L10.5585 0.6784L10.5656 0.685468L11.3145 1.4344L11.3216 1.44147L11.3289 1.44825C11.557 1.65878 11.557 2.00079 11.3289 2.21132L11.3216 2.2181L11.3145 2.22517L7.91028 5.62943Z"
      />
    </svg>
    Not accepting new patients
  </div>
  <div class="badge-offers-video-visits-print appt-badge">
    <svg viewBox="0 0 23 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4247 0H1.90868C0.854514 0 0 0.835938 0 1.86719V13.1328C0 14.1641 0.854514 15 1.90868 15H13.4247C14.4788 15 15.3333 14.1641 15.3333 13.1328V1.86719C15.3333 0.835938 14.4788 0 13.4247 0ZM20.9875 1.47266L16.6111 4.42578V10.5742L20.9875 13.5234C21.834 14.0938 23 13.5117 23 12.5156V2.48047C23 1.48828 21.838 0.902344 20.9875 1.47266Z"
      />
    </svg>
    <span class="p p2">Offers video visits</span>
  </div>
  <div class="badge-online-scheduling appt-badge p p2" *ngIf="setOnlineScheduleBadge()">
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.42188 11.7031C5.73438 12.0156 6.26563 12.0156 6.57813 11.7031L15.7656 2.51562C16.0781 2.20312 16.0781 1.67188 15.7656 1.35938L14.6406 0.234375C14.3281 -0.078125 13.8281 -0.078125 13.5156 0.234375L6.01563 7.73438L2.48438 4.23438C2.17188 3.92188 1.67188 3.92188 1.35938 4.23438L0.234375 5.35938C-0.078125 5.67188 -0.078125 6.20313 0.234375 6.51563L5.42188 11.7031Z"
      />
    </svg>
    Online scheduling
  </div>
</div>
<div *ngIf="isUpdatedProviderDetails && !isPrint" class="badges-details" role="list">
  <div class="badge-offers-video-visits-details appt-badge" *ngIf="provider.acceptsVideoCalls" role="listitem">
    <svg viewBox="0 0 23 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4247 0H1.90868C0.854514 0 0 0.835938 0 1.86719V13.1328C0 14.1641 0.854514 15 1.90868 15H13.4247C14.4788 15 15.3333 14.1641 15.3333 13.1328V1.86719C15.3333 0.835938 14.4788 0 13.4247 0ZM20.9875 1.47266L16.6111 4.42578V10.5742L20.9875 13.5234C21.834 14.0938 23 13.5117 23 12.5156V2.48047C23 1.48828 21.838 0.902344 20.9875 1.47266Z"
      />
    </svg>
    <span class="p p2">Offers video visits</span>
  </div>
  <div
    class="badge-accepts-new-patients-details appt-badge p p2"
    *ngIf="provider.acceptsNewPatients || setAcceptNewPatientBadge()"
    role="listitem"
  >
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="#55a63a"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    Accepting new patients
  </div>
  <div
    class="badge-no-new-patients-details appt-badge p p2"
    *ngIf="!provider.acceptsNewPatients && !setAcceptNewPatientBadge()"
    role="listitem"
  >
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.91028 5.62943L7.55848 5.98122L7.90852 6.33477L11.3128 9.77307L11.3207 9.78105L11.3289 9.78868C11.557 9.99921 11.557 10.3412 11.3289 10.5517L11.3216 10.5585L11.3145 10.5656L10.5656 11.3145L10.5585 11.3216L10.5517 11.3289C10.3412 11.557 9.99921 11.557 9.78868 11.3289L9.7819 11.3216L9.77483 11.3145L6.37057 7.91028L6.01878 7.55848L5.66523 7.90852L2.22693 11.3128L2.21895 11.3207L2.21132 11.3289C2.00079 11.557 1.65878 11.557 1.44825 11.3289L1.44147 11.3216L1.4344 11.3145L0.685468 10.5656L0.6784 10.5585L0.671055 10.5517C0.442982 10.3412 0.442981 9.99921 0.671055 9.78868L0.679314 9.78105L0.687223 9.77307L4.09148 6.33477L4.44152 5.98122L4.08972 5.62943L0.685468 2.22517L0.6784 2.2181L0.671055 2.21132C0.442982 2.00079 0.442982 1.65878 0.671055 1.44825L0.6784 1.44147L0.685468 1.4344L1.4344 0.685468L1.44147 0.6784L1.44825 0.671055C1.65878 0.442982 2.00079 0.442982 2.21132 0.671055L2.21895 0.679314L2.22693 0.687223L5.66523 4.09148L6.01878 4.44152L6.37057 4.08972L9.77483 0.685468L9.7819 0.6784L9.78868 0.671055C9.99921 0.442981 10.3412 0.442982 10.5517 0.671055L10.5585 0.6784L10.5656 0.685468L11.3145 1.4344L11.3216 1.44147L11.3289 1.44825C11.557 1.65878 11.557 2.00079 11.3289 2.21132L11.3216 2.2181L11.3145 2.22517L7.91028 5.62943Z"
      />
    </svg>
    Not accepting new patients
  </div>
  <div class="badge-online-scheduling-details appt-badge p p2" *ngIf="setOnlineScheduleBadge()" role="listitem">
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.42188 11.7031C5.73438 12.0156 6.26563 12.0156 6.57813 11.7031L15.7656 2.51562C16.0781 2.20312 16.0781 1.67188 15.7656 1.35938L14.6406 0.234375C14.3281 -0.078125 13.8281 -0.078125 13.5156 0.234375L6.01563 7.73438L2.48438 4.23438C2.17188 3.92188 1.67188 3.92188 1.35938 4.23438L0.234375 5.35938C-0.078125 5.67188 -0.078125 6.20313 0.234375 6.51563L5.42188 11.7031Z"
      />
    </svg>
    Online scheduling
  </div>
</div>
