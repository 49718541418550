import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { FacetSummary, FiltersModel, FiltersModelPartialHelper } from '../../models/filters';
import { Provider } from '../../models/provider';
import { SelectedLocation } from '../../models/location';
import { FilterService } from '../../services/filter.service';
import { LocationService } from '../../services/location.service';
import { ProviderService } from '../../services/provider.service';
import { ResizeService } from '../../services/resize.service';
import { UrlBuilderParams } from '../../utils/url/url-builder-params';
import { FadUrlBuilder } from '../../utils/url/fad-url-builder';
import { ConfigurationService } from '../../services/configuration.service';
import { RuntimeConfiguration } from '../../services/configuration-injection.service';
import { ROOT_ELEMENT } from 'src/app/utils/utils';
import { MedgroupDictionary } from 'src/app/models/configuration';
import { EventPublisherService } from '../../services/event-publisher.service';
import { FadEvent } from '../../models/fad-event';
import { FadEventTypes } from 'src/app/models/fad-event-types';

export interface ProviderCardParams {
  radius: number;
  location: string;
  searchTerm: string;
  heading: string;
  subHeading: string;
  numberOfItems: number;
  medGroup: string;
  displaySearchButton: boolean;
  specialties: string;
}

@Component({
  selector: 'cs-provider-card',
  templateUrl: './provider-card.component.html',
  styleUrls: ['./provider-card.component.scss']
})
export class ProviderCardComponent implements OnChanges, OnInit {
  @Input() configurationUrl = './fad-configuration.json';
  @Input() inputParams: ProviderCardParams;
  @Input() fadBaseUrl = '';
  @Input() bookingBaseUrl = '';
  @Input() handleNavigateToSchedulingSpa: boolean;
  @Input() market = '';
  @Output() scheduleProvider: EventEmitter<Provider> = new EventEmitter<Provider>();
  @Output() navigateToSchedulingSpa: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('container') container: ElementRef;
  isCernerPage=false;

  readonly defaultParams = {
    radius: 25,
    heading: 'Find a Provider near you',
    subHeading: 'This list has been handpicked just for you. You may also search our networks by clicking the button below.',
    numberOfItems: 12,
    displaySearchButton: true,
    searchTerm: null,
    medGroup: null,
    specialties: null
  } as ProviderCardParams;

  searchParams: ProviderCardParams = { ...this.defaultParams };

  headingText: string;
  subHeadingText: string;
  launchGuidedFlow = false;
  guidedFlowProvider: Provider;
  runtimeConfig: RuntimeConfiguration;
  onlineSchedulingType: string;

  searchButtonLink: string;

  providerCards: Provider[] = [];
  suggestedProviderCards: Provider[] = [];
  selectedLocation: SelectedLocation;
  pageSize: number;
  sliceValue = 0;
  pageIndex = 0;
  isCaptchaEnabled: boolean;
  docAsapIds: { npi: number; docAsapId: number }[] = [];
  showDocAsap = false;
  public showCards: boolean;
  providerSchedulingFeature: boolean;
  width: number;
  embedSource: string;
  showProviderPrefix = this.configurationService.showProviderPrefix();
  medGroupDictionary: MedgroupDictionary[];
  useLegacyAPI: boolean;
  bookingBaseUrl_Cerner = 'https://mocka.dignityhealth.me';
  get totalPages() {
    return (this.providerCards.length / this.pageSize).toFixed(0);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateSuggestedProviderCards();
  }
  constructor(
    @Inject(ROOT_ELEMENT) public rootEl: HTMLElement,
    private resizeService: ResizeService,
    public providerService: ProviderService,
    private locationService: LocationService,
    private filterService: FilterService,
    private configurationService: ConfigurationService,
    private router: Router,
    private urlBuilder: FadUrlBuilder,
    private eventPublisherService: EventPublisherService,

  ) {
    this.showCards = true;
    this.runtimeConfig = configurationService.getRuntimeConfiguration();
    this.useLegacyAPI = this.configurationService?.useLegacyAPI();
    this.isCaptchaEnabled =
      document.querySelector('#captcha-enabled')?.getAttribute('value') === 'true' || configurationService.getCaptchaEnabled();
  }

  private updateSuggestedProviderCards() {
    this.suggestedProviderCards = this.getSuggestedProviderCards();
  }

  ngOnInit(): void {
    this.onlineSchedulingType = this.configurationService.getOnlineSchedulingType();
    this.providerSchedulingFeature = this.configurationService.getProviderSchedulingFeature();
    this.embedSource = this.configurationService.getEmbedSource();
    if (this.onlineSchedulingType === 'ThirdParty') {
      this.initDocAsap();
    }
    this.isCernerPage=this.configurationService.getCernerPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    const inputParamsChanges = changes.inputParams;
    if (!inputParamsChanges?.currentValue) {
      return;
    }
    if (inputParamsChanges.currentValue.toString().startsWith('{')) {
      try {
        const parsedJson = JSON.parse(inputParamsChanges.currentValue);
        this.updateProviders(parsedJson);
      } catch (Exception) {
        if (console) {
          console.error('Could not parse Json. Check for well formed Json.');
        }
        this.updateProviders(this.defaultParams);
      }
    } else {
      this.updateProviders(inputParamsChanges.currentValue);
    }
  }

  private updateProviders(currentParams: ProviderCardParams): void {
    if (!currentParams) {
      return;
    }
    // start with defaults, and override with whatever was passed in.
    this.searchParams = { ...this.defaultParams, ...currentParams };
    this.headingText = this.searchParams.heading;
    this.subHeadingText = this.searchParams.subHeading;

    this.locationService
      .queryLocation(this.searchParams.location)
      .pipe(
        tap((location) => (this.selectedLocation = location)),
        concatMap((location) => this.getProviderCards(location, this.searchParams)),
        tap((providers) => {
          this.providerCards = providers;
          this.setProviderGenderFull();
          this.updateSuggestedProviderCards();
          this.searchButtonLink = this.getSearchButtonLink();
          /**
           * This is here to force 'change detection' to run without
           * calling cdr.detectChange() which would also cause ngOnChange to fire.
           * This is only needed when updating the list of provider cards when changing parameters.
           * Without this, the card list in *ngFor is not getting updated even though we have updated
           * the property it is bound to.
           */
          this.showCards = false;
          setTimeout(() => (this.showCards = true));
        })
      )
      .subscribe();
  }

  getPaginatorData(event: number) {
    this.pageIndex = event;
    this.sliceValue = this.pageIndex * this.pageSize;
    this.updateSuggestedProviderCards();
  }

  scheduleProviderHandler(provider: Provider) {
    this.guidedFlowProvider = this.providerService.normalizeProviderBeforeScheduling(provider);
    this.launchGuidedFlow = this.configurationService.showGuidedFlowButton();
    if (!this.launchGuidedFlow) {
      this.router.navigateByUrl(
        `/${provider.npi}-${provider.firstName.replace(/ /g, '-').toLowerCase()}-${provider.lastName.replace(/ /g, '-').toLowerCase()}`
      );
    }
  }

  private getSuggestedProviderCards(): Provider[] {
    const itemsToDisplay = this.searchParams.numberOfItems;
    this.providerCards = this.providerCards.slice(0, itemsToDisplay);
    let desiredPageSize;
    if (this.rootEl.tagName.toLowerCase() !== 'fad-provider-cards') {
      if (this.resizeService.isMobileViewport) {
        desiredPageSize = 1;
      } else {
        desiredPageSize = this.resizeService.isTabletViewport ? 2 : 3;
      }
    } else {
      const cardsContainer = this.container.nativeElement;
      this.width = cardsContainer?.offsetWidth;
      if (this.width < 766){
      desiredPageSize = 1
       } else { 
        desiredPageSize = this.width > 767 && this.width < 992 ? 2 : 3;
       }
    } // Reset the user back to the first Page when changing ViewPort
    if (this.pageSize !== desiredPageSize) {
      this.pageIndex = 0;
      this.sliceValue = 0;
    }
    this.pageSize = desiredPageSize;
    return this.providerCards.slice(this.sliceValue, this.sliceValue + this.pageSize);
  }

  private getProviderCards(location: SelectedLocation, params: ProviderCardParams): Observable<Provider[]> {
    const filtersModel = new FiltersModel(this.configurationService);
    this.medGroupDictionary = this.configurationService.getMedGroupCodes();
    this.processMedicalGroup(params, filtersModel);
    this.processSpecialties(params, filtersModel);
    FiltersModelPartialHelper.setRadiusKeyForDistance(filtersModel, params.radius);
    return this.filterService.getTypedFilterSearchResults(params.searchTerm, location, filtersModel, '').pipe(
      map((searchResponse) => {
        if (!searchResponse || !searchResponse.isValid) {
          return [] as Provider[];
        } else {
          return searchResponse.result.providers;
        }
      })
    );
  }

  private processMedicalGroup(params: ProviderCardParams, filtersModel: FiltersModel) {
    if(params.medGroup)
    {
      if (params.medGroup.includes('|'))    {
      const selectedMedgroups: FacetSummary[] = [];
      const medGroupList: string[] = params.medGroup.split('|');
      let medGroupItem: MedgroupDictionary = {
        code: '',
        identifiers: '',
        url: '',
        title: '',
        city: '',
        state: '',
        latitude: '',
        longitude: '',
        banner: undefined
      };
      medGroupList.forEach((x: string) => {
        medGroupItem = this.medGroupDictionary.filter((group: MedgroupDictionary) => {
          return group.identifiers?.toLowerCase() === x.toLowerCase();
        })[0];
        selectedMedgroups.push({
          name: medGroupItem.title,
          selected: true,
          count: 0
        });
      });
      filtersModel.providerMedicalGroup = [...selectedMedgroups];
      filtersModel.selectedMedicalGroup = [...selectedMedgroups];
      }
      else {
      filtersModel.providerMedicalGroup.push({ name: params.medGroup } as FacetSummary);
      }
    }
  }

  private processSpecialties(params: ProviderCardParams, filtersModel: FiltersModel) {
    if(params.specialties)
    {
      if (params.specialties.includes('|'))    {
      const selectedSpecialties: FacetSummary[] = [];
      const speciltyList: string[] = params.specialties.split('|');
      speciltyList.forEach((x: string) => {
        selectedSpecialties.push({
          name: x,
          selected: true,
          count: 0
        });
      });
      filtersModel.providerSpecialties = [...selectedSpecialties];
      filtersModel.selectedSpecialties = [...selectedSpecialties];
      }
      else {
      filtersModel.providerSpecialties.push({ name: params.specialties } as FacetSummary);
      filtersModel.selectedSpecialties.push({ name: params.specialties } as FacetSummary);
      }
    }
  }

  private getSearchButtonLink() {
    let url = this.urlBuilder.build(
      {
        medGroup: this.searchParams.medGroup != '' ? this.searchParams.medGroup : '',
        searchTerm: this.searchParams.searchTerm,
        selectedLocation: this.selectedLocation,
        specialty: this.searchParams.specialties != '' ? this.searchParams.specialties : '', 
      } as UrlBuilderParams,
      this.fadBaseUrl
    ); 

    if (this.searchParams.medGroup && this.selectedLocation?.city && this.selectedLocation?.state) {
      url = `${url}?location=${this.selectedLocation.city.toUrlFriendly()}-${this.selectedLocation.state.toUrlFriendly()}`;
    }
    
    if(this.searchParams.medGroup && (this.searchParams.specialties != null && this.searchParams.specialties != '')) {
      url =  `${url}?specialties=${this.searchParams.specialties.toUrlFriendly()}`
    }

    return url;
  }

  initDocAsap(): void {
    this.showDocAsap = true;
    if (this.providerSchedulingFeature) {
      this.docAsapIds = [];
    } else {
      this.providerService.getDocAsapIds().subscribe((results: { npi: number; docAsapId: number }[]) => {
        this.docAsapIds = results || [];
      });
    }
  }

  setProviderGenderFull() {
    this.providerCards.forEach((provider) => {
      if (provider.gender?.toLowerCase() === 'm') {
        provider.gender = 'Male';
      } else if (provider.gender?.toLowerCase() === 'f') {
        provider.gender = 'Female';
      } else {
        provider.gender = null;
      }
    });
  }

  navigateToCernerSchedulingSpa(event: any): void {
    //<------The below code is used in cerner Page to get token from my-appointment-spa----->

      window.sessionStorage.removeItem('singleSpecialty');
        if (event) {
          this.eventPublisherService.publish(
            new FadEvent({
              type: FadEventTypes.PostApptDetailstoMyAppointment_Cerner,
              data: event
            })
          );
        }      
      const tokenInfo = window.sessionStorage.getItem(
        this.runtimeConfig.Cerner_MyAppointments_OIDC_Token
        );
        if (tokenInfo) {
          
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          this.eventPublisherService.publish(
            new FadEvent({
              type: FadEventTypes.PostOidcTokentoMyAppointment_Cerner,
              data: tokenInfo
            })
          );
        }
      
    }
}
