import { ComponentRef, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { ROOT_ELEMENT } from '../utils/utils';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { FadConfigurationData } from '../models/configuration';
import { CovidVaccinationLocation } from '../models/covid-vaccination-location';
import { TealiumService } from './tealium.service';
import { ConfigurationService } from './configuration.service';
import { FadLinkService } from './fad-link.service';
import { LogService } from './log.service';
import { AppInsightsMonitoringService } from './app-insights-monitoring.service';
import { OidcConfig as OpenIdConfiguration } from 'identity-manager-client-library';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationInjectionService {
  private runtimeConfig: RuntimeConfiguration;

  private readonly RuntimeConfigJson = 'runtime.config.json';
  private embedSource: string;
  private isCernerPage=false;

  private readonly noCacheHeader = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    })
  };

  constructor(
    private http: HttpClient,
    private tealiumService: TealiumService,
    private configurationService: ConfigurationService,
    private fadLinkServer: FadLinkService,
    private logService: LogService,
    private appInsights: AppInsightsMonitoringService,
    @Inject(ROOT_ELEMENT) private rootElement: HTMLElement
  ) {}

  public load(): Promise<any> {
    this.setRootElementCssClass();
    this.embedSource = this.rootElement.hasAttribute('embedSource') ? this.rootElement.getAttribute('embedSource') : null;
    if(this.rootElement.hasAttribute('isCernerPage'))
    {
    this.isCernerPage = this.rootElement.getAttribute('isCernerPage') == 'true' ? true : false ;
    }

    return new Promise<void>((resolve) => {
      this.loadInitialConfiguration()
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.logService.error(`Loading configuration failed: ${error.message}`);
            return;
          }
          this.logService.error(`Loading configuration failed: ${error.message}`);
        });
    });
  }

  private setRootElementCssClass() {
    // This dynamically adds a css class to the root element
    // in order to apply css at the entire FAD component level.
    this.rootElement.classList.add('fad-root-element');
  }

  private loadInitialConfiguration(): Observable<any> {
    let jsonConfigUrl = './fad-configuration.json';
    if (!this.rootElement.hasAttribute('configuration-url') && !this.rootElement.hasAttribute('configurationUrl')) {
      this.logService.warn(
        `Could not find attribute 'configuration-url' on root element ${this.rootElement.tagName}. Falling back to ${jsonConfigUrl}`
      );
    } else {
      jsonConfigUrl = this.rootElement.getAttribute('configuration-url') ?? this.rootElement.getAttribute('configurationUrl');
    }

    // Config Tealium after loading Runtime config
    const configAndTealium$: Observable<RuntimeConfiguration> = this.loadRuntimeConfig().pipe(
      tap(() => {
        this.setupTealium();
        this.setupAppInsights();
      })
    );

    return forkJoin([this.setConfigurationElements(jsonConfigUrl), configAndTealium$]).pipe(
      tap(([config, runtimeConfig]: [FadConfigurationData, RuntimeConfiguration]) => {
        // Check missing keys in fad-configuration.json file.
        this.reportMissingKeys(config);
        this.configurationService.init(config, runtimeConfig, this.embedSource,this.isCernerPage);
      })
    );
  }

  private getUrlOfFadScript(): URL {
    let baseUrl = document.location.href;
    if (!this.fadLinkServer.isSelfHosted()) {
      let scriptElement = document.getElementsByTagName('script')?.namedItem('fad-saas-script');
      if (scriptElement) {
        this.logService.info(`scriptElement with namedItem fad-saas src: ${scriptElement.src}`);
      }
      if (!scriptElement) {
        const scriptElements: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName('script');
        for (let i = 0; i < scriptElements.length; i++) {
          const el = scriptElements.item(i);
          if (el.src.includes('fad-saas.js')) {
            scriptElement = el;
            break;
          }
        }
      }
      if (!scriptElement) {
        throw new Error('Could not find the FAD Saas script element');
      }
      baseUrl = scriptElement.src;
    }
    return new URL(baseUrl);
  }

  private loadRuntimeConfig(): Observable<RuntimeConfiguration> {
    const url = this.getUrlOfFadScript();
    let environment = `${url.protocol}//${url.hostname}${url.port ? ':' + url.port : ''}`;
    if (!this.fadLinkServer.isSelfHosted()) {
      environment = url.href.replace('/fad-saas.js', '');
    }
    // The no-cache properties help protect the runtime config against CORS issues
    return this.http
      .get<RuntimeConfiguration>(`${environment}/${this.RuntimeConfigJson}`, this.noCacheHeader)
      .pipe(tap((result: RuntimeConfiguration) => (this.runtimeConfig = result)));
  }

  private setConfigurationElements(configurationUrl: string): Observable<FadConfigurationData> {
    if (configurationUrl) {
      // The no-cache properties help protect the fad config against CORS issues
      return this.http.get<any>(configurationUrl, this.noCacheHeader)?.pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.logService.error(err.message);
            return throwError(err);
          }
          this.logService.error(err.message);
          return throwError(err);
        }),
        tap((config: FadConfigurationData) => {
          this.mapConfiguration(config);
        })
      );
    } else {
      of(null);
    }
  }

  mapConfiguration(config: FadConfigurationData): void {
    if (!config) {
      return;
    }
    if (config.theme && this.rootElement) {
      if (config.theme.colorPalette) {
        const htmlTag = document.querySelector('html');
        htmlTag.classList.add(config.theme.colorPalette);
      }

      if (config.theme.colors) {
        this.rootElement.style.setProperty('--location-pin-color', config.theme.colors.locationPinColor ?? '#ed711c');
        this.rootElement.style.setProperty('--location-pin-outline', config.theme.colors.locationPinOutline ?? '#c85200');
        this.rootElement.style.setProperty('--location-pin-label', config.theme.colors.locationPinLabel ?? '#fff');
      }
      this.setFonts(config, this.rootElement);
    }
  }

  private setFonts(config: FadConfigurationData, rootElement: HTMLElement): void {
    if (!config.theme?.fonts) {
      return;
    }

    const headElement = document.querySelector('head');
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.href = `${config.theme.fonts.fontCssUrl}?display=swap`;
    link.type = 'text/css';
    headElement.appendChild(link);
  }

  private setupTealium(): void {
    this.tealiumService.init(this.runtimeConfig);
  }

  private setupAppInsights(): void {
    this.appInsights.init(this.runtimeConfig);
  }

  listener(componentRef: ComponentRef<any>): void {
    this.logService.log(componentRef.componentType?.name);
    this.logService.log(componentRef.location?.nativeElement?.name);
  }

  reportMissingKeys(fadConfig: FadConfigurationData): void {
    const requiredSearchKeys = this.configurationService.requiredSearchKeys;
    const warningSearchKeys = this.configurationService.warningSearchKeys;
    const requiredMissingKeys = this.configurationService.findMissingKeys(requiredSearchKeys, fadConfig);
    const warningMissingKeys = this.configurationService.findMissingKeys(warningSearchKeys, fadConfig);
    if (requiredMissingKeys.length > 0) {
      this.logService.error('Required missing key(s) in fad-configuration.json file.', requiredMissingKeys);
    }

    if (warningMissingKeys.length > 0) {
      this.logService.warn('Warning missing key(s) in fad-configuration.json file.', warningMissingKeys, fadConfig);
    }
  }
}

export interface RuntimeConfiguration {
  apiBaseUrl: string;
  newApiBaseUrl?: string;
  schedulingApiBaseUrl: string;
  apimSubscriptionKey?: string;
  notableTokenValidationUrl?: string;
  envName: string;
  covidVaccinationLocations?: CovidVaccinationLocation[];
  cshValidationUrl?: string;
  tealiumScriptSrc?: string;
  marketLocations?: string;
  pressGaneyRatingsUrl: string;
  covidAppointmentTypes?: string;
  v5OpenSlotsEndpoint?: string;
  instrumentationKey?: string;
  googleMapUrl?: string;
  falBaseUrl?: string;
  fadBaseUrl?: string;
  myChartUrl?: string;
  slotUrl?:string;
  Cerner_MyAppointments_OIDC_Token?: string;
  blockItUrl?: string;
  blockItToken?: string;
  oidcConfig?: OpenIdConfiguration;
  appointmentDomainAPI?: string;
  ocpApimSubscriptionKey?: string;
}
