import { FadUrlBuilderStrategy } from './fad-url-builder-strategy';
import { UrlBuilderParams } from './url-builder-params';

export class LocationUrlBuilderStrategy extends FadUrlBuilderStrategy {
  shouldBuild(urlBuilderParams: UrlBuilderParams): boolean {
    if (!!urlBuilderParams.medGroup || !!urlBuilderParams.searchTerm) {
      return false;
    }
    return this.hasLocationData(urlBuilderParams);
  }

  createUrlSegments(urlBuilderParams: UrlBuilderParams): void {
    this.addSegments('', 'l');
    this.addCityAndStateSegment(urlBuilderParams.selectedLocation);
  }
}
