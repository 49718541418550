import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiConstants } from 'src/app/constants/api-constants.new';
import { RuntimeConfiguration } from '../configuration-injection.service';
import { ConfigurationService } from '../configuration.service';
import { ProviderService } from '../provider.service';
import { FilterLegacyService } from './filter.legacy.service';

@Injectable({
  providedIn: 'root'
})
export class FilterNewService extends FilterLegacyService {
  public showGuidedFlow = false;
  public runtimeConfig: RuntimeConfiguration;

  constructor(
    protected providerService: ProviderService,
    protected configurationService: ConfigurationService,
    protected route: ActivatedRoute
  ) {
    super(providerService, configurationService, route);
    this.apiBaseUrl = this.runtimeConfig.newApiBaseUrl;
    this.apiConstants = ApiConstants;
  }
}
