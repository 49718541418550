import { UrlBuilderParams } from './url-builder-params';
import { FadUrlBuilderStrategy } from './fad-url-builder-strategy';

export class MedGroupUrlBuilderStrategy extends FadUrlBuilderStrategy {
  shouldBuild(urlBuilderParams: UrlBuilderParams): boolean {
    return !!urlBuilderParams.medGroup;
  }

  createUrlSegments(urlBuilderParams: UrlBuilderParams): void {
    this.addSegments('', 'm', urlBuilderParams.medGroup);

    if (urlBuilderParams.searchTerm) {
      this.addSegments(urlBuilderParams.searchTerm.stripDash('_'));
    }
  }
}
