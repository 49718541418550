<div *ngIf="showUpdatedProviderDetailPageUX">
  <div class="cs-provider-nonprintable">
    <cs-scroll-to-top *ngIf="!isMyChart && !isCerner" [windowScrolled]="false"></cs-scroll-to-top>
    <div class="provider-details-content">
      <dhcl-loading-spinner [showSpinner]="!apiResponse" id="dhclLoadingSpinner"></dhcl-loading-spinner>
      <div class="provider-details-container" *ngIf="provider">
        <div class="title-container">
          <div class="detail-nav-btn">
            <cs-provider-details-navigation
              [referringUrl]="referringUrl"
              [redirectFromFAL]="redirectFromFAL"
              *ngIf="searchResultsOrigin || referringUrl || showBacktoLoctionSearchBtn"
            >
            </cs-provider-details-navigation>
          </div>
          <div class="detail-nav-header">
            <h1>{{ provider.displayFullName }}</h1>
          </div>
        </div>
        <div class="provider-panel">
          <div class="left-panel shadow-break">
            <ul class="nav-bar">
              <li *ngFor="let name of navList">
                <a
                  tabindex="0"
                  role="button"
                  (keydown.enter)="navigateToSection(name.routeName)"
                  (keydown.space)="navigateToSection(name.routeName)"
                  (click)="navigateToSection(name.routeName)"
                  >{{name.routeName}}</a
                >
              </li>
            </ul>
          </div>
          <div class="right-panel">
            <cs-provider-details-card
              [provider]="provider"
              [providerImage]="providerImage"
              [logoUrls]="logoUrls"
              [providerSpecialities]="providerSpecialities"
              [nonSEOSecondarySpecialities]="nonSEOSecondarySpecialities"
              [ratingTooltipText]="ratingTooltipText"
              [showNumReviews]="showNumReviews"
              [showRatingText]="showRatingText"
              [officeAddresses]="officeAddresses"
              [showRating]="showStarRatingsForProviderHero"
              [isSchedulable]="enableScheduleToAppointmentButton"
              (scheduleProvider)="scheduleProviderHandler($event)"
            ></cs-provider-details-card>
          </div>
        </div>
        <div class="provider-panel">
          <div class="left-panel">
            <cs-schedule-an-appointment
              [onlineSchedulingType]="onlineSchedulingType"
              [srcUrl]="srcUrl"
              [provider]="provider"
              [selectedOfficeAddress]="selectedOfficeAddress"
              [showDocAsap]="showDocAsap"
              [showGuidedFlow]="showGuidedFlow"
              [showNewSchedulingApp]="showNewSchedulingApp"
              [enableScheduleToAppointmentButton]="enableScheduleToAppointmentButton"
              [facets]="facets"
              (selectedOfficeChanged)="handleSelectedOfficeChanged($event)"
              [docAsapId]="docAsapId"
              [olsDepartmentId]="olsDepartmentId"
              [olsProviderId]="olsProviderId"
              [olsBlockIt]="olsBlockIt"
              *ngIf="(provider.offices?.length > 0 || (docAsapId && showDocAsap) || (olsDepartmentId && olsProviderId && showDocAsap) || (olsBlockIt  && showDocAsap)) && isSchedulingVisible"
            >
            </cs-schedule-an-appointment>
          </div>
          <div class="right-panel">
            <div class="badges-container" *ngIf="provider">
              <div>
                <cs-badges [provider]="provider" [isUpdatedProviderDetails]="showUpdatedProviderDetailPageUX"></cs-badges>
              </div>
              <div class="print" *ngIf="showPrintablePdf" (click)="printPage()">
                <a
                  ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.2545 8.31169H16.9498V5.81818C16.9498 5.61039 16.8777 5.4026 16.7337 5.22078L12.1001 0.233766C11.9561 0.077922 11.764 0 11.5479 0H3.8413C3.40916 0 3.07304 0.363636 3.07304 0.831169V8.33766H0.768261C0.336114 8.33766 0 8.7013 0 9.16883V15.8442C0 16.3117 0.336114 16.6753 0.768261 16.6753H3.07304V19.1688C3.07304 19.6364 3.40916 20 3.8413 20H16.1575C16.5896 20 16.9257 19.6364 16.9257 19.1688V16.6753H19.2305C19.6627 16.6753 19.9988 16.3117 19.9988 15.8442V9.14286C20.0228 8.67532 19.6867 8.31169 19.2545 8.31169ZM12.3162 2.83117L14.3089 4.98701H12.3162V2.83117ZM4.60957 1.63636H9.21913C10.0594 1.63636 10.7557 2.38961 10.7557 3.2987V5.81818C10.7557 6.28571 11.0918 6.64935 11.5239 6.64935H15.3652V8.31169H4.60957V1.63636ZM15.4132 18.3117H4.60957V15.8961H15.3892V18.3117H15.4132Z"
                      fill="#0071C2"
                    />
                  </svg>
                  <span>Print</span></a
                >
              </div>
            </div>
            <div class="info-line-break"></div>
            <cs-get-to-know
              [provider]="provider"
              [videoId]="videoId"
              (callTealiumService)="onStateChange(provider)"
              *ngIf="displayGetToKnowSection()"
              [getToKnowContent]="getToKnowContent"
              [isShowProviderVideo]="isShowProviderVideo"
              [showHospitalAffiliations]="showHospitalAffiliations"
              [inMyOwnWordsTitle]="inMyOwnWordsTitle"
              [hideExtraPhilosophyContent]="hideExtraPhilosophyContent"
              [showMoreLessButtonForPhilosophy]="showMoreLessButtonForPhilosophy"
              [hideExtraOwnwordsContent]="hideExtraOwnwordsContent"
              [showMoreLessButtonForOwnwords]="showMoreLessButtonForOwnwords"
              [isUpdatedProviderDetails]="showUpdatedProviderDetailPageUX"
              [routId]="'About'"
            >
            </cs-get-to-know>
            <cs-expandable-list
              *ngIf="provider.insuranceCarriers.length > 0"
              [sectionTitle]="'Insurance carriers accepted'"
              [listItems]="provider.insuranceCarriers"
              [isInsuranceSection]="true"
              [routId]="'Insurance'"
            ></cs-expandable-list>
            <cs-credentials
              [provider]="provider"
              [educationArray]="educationArray"
              [memberships]="memberships"
              *ngIf="displayCredentialsSection()"
            ></cs-credentials>
            <cs-expandable-list
              *ngIf="provider.awards.length > 0"
              [sectionTitle]="'Awards and recognitions'"
              [listItems]="provider.awards"
              [isAwardsSection]="true"
            ></cs-expandable-list>
            <cs-locations-list
              [provider]="provider"
              [centerPoint]="selectedOfficeAddress"
              [officeAddresses]="officeAddresses"
              [showMoreLessButton]="showMoreLessButtonForLocationList"
              [hideExtraContent]="hideExtraContentForLocationList"
              [isDesktopViewport]="isDesktopViewportForLocationList"
              [hasMoreThanOneOffice]="hasMoreThanOneOffice"
              [divisionCodes]="divisionCodes"
              (selectedOfficeChanged)="handleSelectedOfficeChanged($event)"
              *ngIf="officeAddresses?.length > 0"
              [isUpdatedProviderDetails]="showUpdatedProviderDetailPageUX"
              [routId]="'Locations'"
            >
            </cs-locations-list>
            <div class="patients-saying-section">
              <cs-provider-comments
                [provider]="provider"
                [ratingFooterText]="ratingFooterText"
                [ratingsFooterLinkText]="ratingsFooterLinkText"
                [ratingsFooterLink]="ratingsFooterLink"
                [isShowProviderReviews]="isShowProviderReviews"
                [commentsArrayLength]="commentsArrayLength"
                [showMoreLessButton]="showMoreLessButton"
                [isMobileView]="resizeService.isMobileViewport"
                *ngIf="hasRatingsData(provider)"
                [isUpdatedProviderDetails]="showUpdatedProviderDetailPageUX"
                [routId]="'Reviews'"
              >
              </cs-provider-comments>
            </div>
            <div class="speciality" id="Specialties">
              <cs-expandable-list *ngIf="provider.specialties.length > 0" [sectionTitle]="'Specialties'" [listItems]="provider.specialties">
              </cs-expandable-list>
              <cs-expandable-list
                *ngIf="provider.procedures.length > 0"
                [sectionTitle]="'Procedures performed'"
                [listItems]="provider.procedures"
              ></cs-expandable-list>
              <cs-expandable-list
                *ngIf="provider.conditions.length > 0"
                [sectionTitle]="'Conditions treated'"
                [listItems]="provider.conditions"
              ></cs-expandable-list>
            </div>
          </div>
        </div>
      </div>
      <cs-error-page *ngIf="errorMessage !=''" [errorMessage]="errorMessage"></cs-error-page>
    </div>
    <div class="disclaimer-container fad-col-sm-12" *ngIf="!searchResultsOrigin && configurationUrl.includes('media')">
      <cs-disclaimer [disclaimer]="disclaimer"></cs-disclaimer>
    </div>
  </div>
  <dhcl-modal
    *ngIf="onlineSchedulingType === 'GuidedFlow' && launchGuidedFlow && guidedFlowProvider"
    [alignTop]="true"
    [customClass]="getCernerClass('guided-flow-modal')"
    [closeOnOverlay]="false"
    [show]="true"
    (closeClicked)="closeScheduleModal()"
    role="dialog"
    aria-labelledby="stepTitle"
  >
    <div *ngIf="isCernerPage">
      <dhgf-guided-flow
        *ngIf="runtimeConfig.envName"
        [environment]="runtimeConfig.envName"
        [provider]="guidedFlowProvider"
        [facets]="[]"
        [npi]="guidedFlowProvider.npi"
        [showNewSchedulingApp]="true"
        [isCaptchaEnabled]="isCaptchaEnabled"
        [baseUrl]="bookingApptBaseUrl_Cerner"
        [handleNavigateToSchedulingSpa]="true"
        (navigateToSchedulingSpa)="navigateToSchedulingSpa($event)"
        [market]="market"
        [useLegacyAPI]="useLegacyAPI"
        [embedSource]="embedSource"
        [showProviderPrefix]="showProviderPrefix"
        (closeClicked)="closeScheduleModal()"
      >
      </dhgf-guided-flow>
    </div>
    <div *ngIf="!isCernerPage">
      <dhgf-guided-flow
        *ngIf="runtimeConfig.envName"
        [environment]="runtimeConfig.envName"
        [provider]="guidedFlowProvider"
        [facets]="[]"
        [npi]="guidedFlowProvider.npi"
        [showNewSchedulingApp]="true"
        [isCaptchaEnabled]="isCaptchaEnabled"
        [handleNavigateToSchedulingSpa]="handleNavigateToSchedulingSpa"
        (navigateToSchedulingSpa)="navigateToSchedulingSpa($event)"
        [market]="market"
        [useLegacyAPI]="useLegacyAPI"
        [embedSource]="embedSource"
        [showProviderPrefix]="showProviderPrefix"
        (closeClicked)="closeScheduleModal()"
      >
      </dhgf-guided-flow>
    </div>
  </dhcl-modal>
</div>
<div *ngIf="!showUpdatedProviderDetailPageUX">
  <div class="cs-provider-nonprintable">
    <cs-scroll-to-top *ngIf="!isMyChart && !isCerner" [windowScrolled]="false"></cs-scroll-to-top>
    <div class="provider-details-content">
      <dhcl-loading-spinner [showSpinner]="!apiResponse" id="dhclLoadingSpinner"></dhcl-loading-spinner>
      <div class="provider-details-container old-container" *ngIf="provider">
        <cs-provider-details-navigation
          [referringUrl]="referringUrl"
          [redirectFromFAL]="redirectFromFAL"
          *ngIf="searchResultsOrigin || referringUrl || showBacktoLoctionSearchBtn"
        >
        </cs-provider-details-navigation>
        <cs-provider-hero
          [provider]="provider"
          [referringUrl]="referringUrl"
          [providerImage]="providerImage"
          [showSuggestAnEditLink]="showSuggestAnEditLink"
          [isMobileViewport]="resizeService.isMobileViewport"
          [logoUrls]="logoUrls"
          [ratingTooltipText]="ratingTooltipText"
          [showRatingText]="showRatingText"
          [showNumReviews]="showNumReviews"
          [showStarRatings]="showStarRatingsForProviderHero"
          [showStarRatingsBanner]="showStarRatingsBanner"
          [providerSpecialities]="providerSpecialities"
          [suggestAnEditLinkContent]="suggestAnEditLinkContent"
          [malePlaceholder]="malePlaceholder"
          [femalePlaceholder]="femalePlaceholder"
          [nonSEOSecondarySpecialities]="nonSEOSecondarySpecialities"
          *ngIf="provider.displayFullName || provider.primarySpecialty"
          [showPrintablePdf]="showPrintablePdf"
        >
        </cs-provider-hero>
        <div *ngIf="!showDocAsap || (showDocAsap && docAsapIsChecked)" class="schedule-app-container">
          <cs-schedule-an-appointment
            [onlineSchedulingType]="onlineSchedulingType"
            [srcUrl]="srcUrl"
            [provider]="provider"
            [selectedOfficeAddress]="selectedOfficeAddress"
            [showDocAsap]="showDocAsap"
            [showGuidedFlow]="showGuidedFlow"
            [showNewSchedulingApp]="showNewSchedulingApp"
            [enableScheduleToAppointmentButton]="enableScheduleToAppointmentButton"
            [facets]="facets"
            (selectedOfficeChanged)="handleSelectedOfficeChanged($event)"
            [docAsapId]="docAsapId"
            [olsDepartmentId]="olsDepartmentId"
            [olsProviderId]="olsProviderId"
            [olsBlockIt]="olsBlockIt"
            *ngIf="(provider.offices?.length > 0 || (docAsapId && showDocAsap) || (olsDepartmentId && olsProviderId && showDocAsap) || (olsBlockIt && showDocAsap)) && isSchedulingVisible"
          >
          </cs-schedule-an-appointment>
          <cs-locations-list
            [provider]="provider"
            [centerPoint]="selectedOfficeAddress"
            [officeAddresses]="officeAddresses"
            [showMoreLessButton]="showMoreLessButtonForLocationList"
            [hideExtraContent]="hideExtraContentForLocationList"
            [isDesktopViewport]="isDesktopViewportForLocationList"
            [hasMoreThanOneOffice]="hasMoreThanOneOffice"
            [divisionCodes]="divisionCodes"
            (selectedOfficeChanged)="handleSelectedOfficeChanged($event)"
            *ngIf="officeAddresses?.length > 0"
          >
          </cs-locations-list>
        </div>
        <div class="get-to-know-section">
          <cs-get-to-know
            [provider]="provider"
            [videoId]="videoId"
            (callTealiumService)="onStateChange(provider)"
            *ngIf="displayGetToKnowSection()"
            [getToKnowContent]="getToKnowContent"
            [isShowProviderVideo]="isShowProviderVideo"
            [showHospitalAffiliations]="showHospitalAffiliations"
            [inMyOwnWordsTitle]="inMyOwnWordsTitle"
            [hideExtraPhilosophyContent]="hideExtraPhilosophyContent"
            [showMoreLessButtonForPhilosophy]="showMoreLessButtonForPhilosophy"
            [hideExtraOwnwordsContent]="hideExtraOwnwordsContent"
            [showMoreLessButtonForOwnwords]="showMoreLessButtonForOwnwords"
          >
          </cs-get-to-know>
        </div>
        <div class="patients-saying-section">
          <cs-provider-comments
            [provider]="provider"
            [ratingFooterText]="ratingFooterText"
            [ratingsFooterLinkText]="ratingsFooterLinkText"
            [ratingsFooterLink]="ratingsFooterLink"
            [isShowProviderReviews]="isShowProviderReviews"
            [commentsArrayLength]="commentsArrayLength"
            [showMoreLessButton]="showMoreLessButton"
            *ngIf="hasRatingsData(provider)"
          >
          </cs-provider-comments>
        </div>

        <cs-expandable-list
          *ngIf="provider.insuranceCarriers.length > 0"
          [sectionTitle]="'Insurance carriers accepted'"
          [listItems]="provider.insuranceCarriers"
          [isInsuranceSection]="true"
        ></cs-expandable-list>
        <cs-expandable-list
          style="display: none"
          *ngIf="provider.specialties.length > 0"
          [sectionTitle]="'Specialties'"
          [listItems]="provider.specialties"
        >
        </cs-expandable-list>
        <cs-expandable-list
          *ngIf="provider.procedures.length > 0"
          [sectionTitle]="'Procedures performed'"
          [listItems]="provider.procedures"
        ></cs-expandable-list>
        <cs-expandable-list
          *ngIf="provider.conditions.length > 0"
          [sectionTitle]="'Conditions treated'"
          [listItems]="provider.conditions"
        ></cs-expandable-list>
        <cs-credentials
          [provider]="provider"
          [educationArray]="educationArray"
          [memberships]="memberships"
          *ngIf="displayCredentialsSection()"
        ></cs-credentials>
        <cs-expandable-list
          *ngIf="provider.awards.length > 0"
          [sectionTitle]="'Awards and recognitions'"
          [listItems]="provider.awards"
          [isAwardsSection]="true"
        ></cs-expandable-list>
      </div>
      <cs-error-page *ngIf="errorMessage !=''" [errorMessage]="errorMessage"></cs-error-page>
    </div>
    <div class="disclaimer-container fad-col-sm-12" *ngIf="!searchResultsOrigin && configurationUrl.includes('media')">
      <cs-disclaimer [disclaimer]="disclaimer"></cs-disclaimer>
    </div>
  </div>
</div>
<div class="cs-provider-printable" *ngIf="provider">
  <cs-print-page
    [provider]="provider"
    [malePlaceholder]="malePlaceholder"
    [femalePlaceholder]="femalePlaceholder"
    [providerImage]="providerImage"
    [logoUrls]="logoUrls"
    [providerSpecialities]="providerSpecialities"
    [divisionCodes]="divisionCodes"
    [centerPoint]="selectedOfficeAddress"
    [officeAddresses]="officeAddresses"
    [getToKnowContent]="getToKnowContent"
    [nonSEOSecondarySpecialities]="nonSEOSecondarySpecialities"
    *ngIf="(provider.displayFullName || provider.primarySpecialty) && displayGetToKnowSection()"
  ></cs-print-page>
</div>
