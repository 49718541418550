import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LogLevel, OidcConfigService } from 'identity-manager-client-library';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StsServerResponse } from '../interfaces/StsServerResponse';
import { RuntimeConfiguration } from './configuration-injection.service';

@Injectable({
  providedIn: 'root'
})
export class AuthHelperService {
  private runtimeConfig: RuntimeConfiguration;
  stsServerResponse: StsServerResponse;
  stsServer?: string;
  constructor(
    private configurationService: ConfigurationService,
    @Inject(APP_BASE_HREF) private basePath: string,
    private document: Document
  ) {}

  async configureAuth(oidcConfigService: OidcConfigService): Promise<() => any> {
    this.runtimeConfig = this.configurationService.getRuntimeConfiguration();
    const oidcResult = await oidcConfigService
      .withConfig({
        stsServerInfoLookupUrl: this.runtimeConfig.oidcConfig.stsServerInfoLookupUrl,
        redirectUrl: this.runtimeConfig.oidcConfig.redirectUrl.replace('{location}', window.location.origin),
        postLogoutRedirectUri: this.buildUrl('unauthorized'),
        clientId: this.runtimeConfig.oidcConfig.clientId,
        scope: this.runtimeConfig.oidcConfig.scope,
        responseType: this.runtimeConfig.oidcConfig.responseType,
        silentRenew: this.runtimeConfig.oidcConfig.silentRenew,
        silentRenewUrl: window.location.origin + '/silent-renew.html',
        logLevel: LogLevel.Debug,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        customParams: this.runtimeConfig.oidcConfig.customParams
      })
      .then(() => {
        this.stsServer = oidcConfigService.configuration.stsServer;
      });

    window.sessionStorage.setItem('okta-token-checked', 'true');
    return () => oidcResult;
  }

  public buildUrl(route?: string): string {
    let url = this.document.location.origin;
    [this.basePath, route].forEach(path => {
      if (path !== '/' && path?.length) {
        url += `${url.endsWith('/') || path?.startsWith('/') ? '' : '/'}${path}`;
      }
    });

    return url;
  }
}
