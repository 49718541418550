import { Component, Input } from '@angular/core';
import { Provider } from '../../models/provider';

@Component({
  selector: 'cs-provider-comments',
  templateUrl: './provider-comments.component.html',
  styleUrls: ['./provider-comments.component.scss']
})
export class ProviderCommentsComponent {
  @Input() provider: Provider;
  moreLessExpanded = false;
  commentsSliceIndex = 5;
  @Input() showMoreLessButton: boolean;
  @Input() commentsArrayLength: number;
  @Input() ratingFooterText: string;
  @Input() ratingsFooterLinkText: string;
  @Input() ratingsFooterLink: string;
  @Input() isShowProviderReviews = false;
  @Input() isUpdatedProviderDetails = false;
  @Input() routId: '';
  @Input() isMobileView: false;

  goToRatingFooterLink() {
    window.open(this.ratingsFooterLink, '_blank');
  }

  toggleReviewComments() {
    this.moreLessExpanded = !this.moreLessExpanded;
    this.commentsSliceIndex = this.moreLessExpanded ? this.commentsArrayLength : 5;
    if (!this.moreLessExpanded) {
      const comments = document.querySelector('div.provider-comments');
      comments?.scrollIntoView();
    }
  }
}
