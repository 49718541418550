import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { RuntimeConfiguration } from '../../services/configuration-injection.service';
import { SchedulingService } from '../../services/scheduling.service';
import { ProviderSchedule } from '../../models/provider-schedules';
import { CovidLocation } from '../../models/covid-location.model';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '../../services/location.service';
import { LogService } from '../../services/log.service';
import { ConfigurationService } from '../../services/configuration.service';
import { MarketLocation } from '../../models/market-location.model';
import { Provider } from '../../models/provider';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ROOT_ELEMENT } from '../../utils/utils';
import { LogoConstants } from '../../constants/logo-constants';
import { CovidPatientInfo } from '../../models/covid-patient-info';

@Component({
  selector: 'cs-vaccination-location',
  templateUrl: './vaccination-location.component.html',
  styleUrls: ['./vaccination-location.component.scss']
})
export class VaccinationLocationComponent implements OnInit, AfterViewInit {
  showModal = false;
  apptTypes = '';
  locationIds: string;
  showGuidedFlow = true;
  runtimeConfig: RuntimeConfiguration;
  isCaptchaEnabled = document.querySelector('#captcha-enabled')?.getAttribute('value') === 'true' || this.configService.getCaptchaEnabled();
  locations: CovidLocation[] = [];
  showSpinner = false;
  phoneNo = '(844) 274-8497';
  notableToken: string;
  cshToken: string;
  showInvitationOnlyMessage = false;
  authCallsFinished = false;
  mrn = '';
  city: string;
  state: string;
  zip: string;
  market: string;
  marketLocations: MarketLocation[] = [];
  isMyTurnPatient = false;
  showMyTurnError = false;
  myTurnErrorPhoneNumber: string;
  v5AppointmentTypes = [];
  v5Locations = [];
  v5Provider: Provider;
  imgUrl: SafeResourceUrl;
  myTurnV5Location = {};
  covidPatientInfo: CovidPatientInfo;
  covidSupportUseV5: boolean;
  filterLocationBy50Miles = false;
  isBookAgain = false;
  appointmentSchedules: any;
  selectedAppointmentSchedule?: any;
  showProviderPrefix = this.configService.showProviderPrefix();
  useLegacyAPI: boolean;

  constructor(
    private configService: ConfigurationService,
    private schedulingService: SchedulingService,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private sanitizer: DomSanitizer,
    private logService: LogService,
    @Inject(ROOT_ELEMENT) private rootElement: HTMLElement
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.location) {
        this.locationIds = params.location;
      }

      if (params.ref) {
        if (params.ref === 'mt') {
          this.isMyTurnPatient = true;
        } else if (params.ref === 'book_again') {
          this.isBookAgain = true;
        }
      } else {
        this.setAuthToken(params);
      }
    });
    this.runtimeConfig = this.configService.getRuntimeConfiguration();
    this.useLegacyAPI = this.configService.useLegacyAPI();
  }

  ngOnInit(): void {
    this.initializeRunTimeConfigs();
    this.link('covid landing page', '', '');
    this.initCovidFlow();
    this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.environmentBaseUrl + LogoConstants.default}`);
    this.covidSupportUseV5 = this.configService.getCovidSupportUseV5();
  }

  ngAfterViewInit(): void {
    
    const fadContainer = document.querySelector('.fad-app-container');

    if (fadContainer) {
      fadContainer.classList.remove('fad-app-container');
    }
  }

  initializeRunTimeConfigs(): void {
    this.apptTypes = this.runtimeConfig?.covidVaccinationLocations[0]?.appointmentType;
  }

  initCovidFlow(): void {
    if (this.isMyTurnPatient || this.isBookAgain) {
      this.initMyTurnFlow();
    } else {
      this.authenticateUser();
    }
  }

  setAuthToken(params: any): void {
    if (params['token']) {
      this.notableToken = params['token'];
    }

    if (params['cshToken']) {
      this.cshToken = params['cshToken'];
    }
  }

  authenticateUser(): void {
    this.showSpinner = true;

    if (this.cshToken) {
      this.schedulingService.validateCSHToken(this.cshToken).subscribe(
        (data: any) => {
          this.showSpinner = false;
          if (data.mrn) {
            this.mrn = data.mrn;
            this.city = data.city;
            this.state = data.state;
            this.zip = data.postalCd;
            this.market = (data.market || '').toLowerCase();
            this.covidPatientInfo = {
              firstName: data?.firstName,
              lastName: data?.lastName,
              eMail: data?.emailAddress,
              dateOfBirth: data?.dateOfBirth?.replace(/\//g, '-'),
              phoneNumber: data?.phoneNumber,
              MRN: data?.mrn
            };
            this.getMarketLocations();
            this.link('covid landing page - user authenticated', this.mrn, 'csh token');
          } else {
            this.showInvitationOnlyMessage = true;
            this.authCallsFinished = true;
            this.link('covid landing page - auth failed', this.cshToken, 'csh token');
          }
        },
        (error) => {
          this.showInvitationOnlyMessage = true;
          this.authCallsFinished = true;
          this.showSpinner = false;
          this.link('covid landing page - auth failed', this.cshToken, 'csh token');
        }
      );
    } else {
      this.validateNotableToken();
    }
  }

  validateNotableToken(): void {
    if (this.notableToken) {
      this.showSpinner = true;
      this.schedulingService.validateNotableToken(this.notableToken).subscribe(
        (result: any) => {
          this.showSpinner = false;

          if (result.MRN) {
            this.mrn = result.MRN;
            this.city = result.City;
            this.state = result.State;
            this.zip = result.PostalCd;
            this.market = (result.market || result.Market || '').toLowerCase();
            this.covidPatientInfo = {
              firstName: result?.FirstName,
              lastName: result?.LastName,
              eMail: result?.EmailAddress,
              dateOfBirth: result?.DateOfBirth?.replace(/\//g, '-'),
              phoneNumber: result?.PhoneNumber,
              MRN: result?.MRN
            };
            this.getMarketLocations();
            this.link('covid landing page - user authenticated', this.mrn, 'notable token');
          } else {
            this.showInvitationOnlyMessage = true;
            this.authCallsFinished = true;
            this.link('covid landing page - auth failed', this.notableToken, 'notable token');
          }
        },
        (error) => {
          this.logService.error(error);
          this.showInvitationOnlyMessage = true;
          this.authCallsFinished = true;
          this.showSpinner = false;
          this.link('covid landing page - auth failed', this.notableToken, 'notable token');
        }
      );
    } else {
      this.showInvitationOnlyMessage = true;
      this.authCallsFinished = true;
      this.showSpinner = false;
      this.link('covid landing page - auth failed', '', 'no token');
    }
  }

  getMarketLocations(): void {
    this.schedulingService.getMarketLocations().subscribe(
      (markets: any) => {
        if (markets && markets[this.market]) {
          this.marketLocations = markets;
          const locationIds: string = this.marketLocations[this.market]
            .map((marketLocation: MarketLocation) => {
              return marketLocation.id;
            })
            .toString();

          this.getHeaderDHMGLogo();
          this.getLocationsByAppointmentType(locationIds);
        } else {
          this.getLocationsByAppointmentType();
        }
      },
      (error) => {
        this.getLocationsByAppointmentType();
      }
    );
  }

  getLocationsByAppointmentType(locationIds?: string) {
    if (this.covidSupportUseV5) {
      this.getV5Schedule();
    } else {
      this.showSpinner = true;

      const eventStartTime = new Date().getTime();
      this.schedulingService.getLocationsByApptointmentType(this.apptTypes, locationIds).subscribe(
        (result: any) => {
          this.link(
            'get covid locations',
            '8',
            'end',
            'search and schedule',
            new Date().getTime().toString(),
            (new Date().getTime() - eventStartTime).toString()
          );
          this.setLocations(result);
          if(this.appointmentSchedules?.data?.length > 1)
          {
            this.showSpinner = false;
            this.authCallsFinished = true;
          }
          else{ 
            this.clickCovidLauncherBtn();
          }
          
          
        },
        (err) => {
          this.noLocationsFound();
        }
      );
    }
  }

  get environmentBaseUrl(): string {
    const el = document.createElement('a');
    if (this.rootElement) {
      // This only works if the sibling is the script tag. This is not guaranteed!
      el.href = this.rootElement.nextElementSibling.getAttribute('src');
    }
    return `${el.protocol}//${el.hostname}${el.port ? ':' + el.port : ''}`;
  }

  setLocations(response: any): void {
    this.appointmentSchedules = response;

    response.data.forEach((providerSchedule: ProviderSchedule) => {
      const location: CovidLocation = providerSchedule.attributes['location'];
      const marketLocation = (this.marketLocations[this.market] || []).find((mlocation: MarketLocation) => {
        return mlocation.id === location.id;
      });

      if (marketLocation) {
        location.name = marketLocation.name;
        location.address = marketLocation.address.street;
        location.city = marketLocation.address.city;
        location.state = marketLocation.address.state;
        location.zip = marketLocation.address.zip;
        location.phone = marketLocation.phone;
      } else {
        this.filterLocationBy50Miles = true;
      }
      location.scheduleId = providerSchedule.id;
      location.providerName =  this.appointmentSchedules.data.length > 1 ? providerSchedule.attributes['provider'].name : null;
      location.availableTimes = providerSchedule.attributes['available-times'];
      location.phone = this.formatPhone(location.phone);
      if ((location.availableTimes || []).length > 0) {
        this.locations.push(location);
      }
    });

    if (this.locations.length > 0) {
      this.sortLocationListByUserLocation();
    } else {
      this.link('covid landing page - no locations found', this.mrn);
    }
  }

  clickCovidLauncherBtn(): void {
    this.showSpinner = false;
    this.authCallsFinished = true;

    if (this.isMyTurnPatient || this.isBookAgain) {
      window.setTimeout(() => {
        const covidLauncherBtn: HTMLButtonElement = document.querySelector('.covid-vacination-cards button');
        covidLauncherBtn?.click();
      }, 100);
    }
  }

  noLocationsFound(): void {
    const eventStartTime = new Date().getTime();
    this.link(
      'get covid locations',
      '8',
      'end',
      'search and schedule',
      new Date().getTime().toString(),
      (new Date().getTime() - eventStartTime).toString()
    );
    this.locations = [];
    this.showSpinner = false;
    this.authCallsFinished = true;
    this.link('covid landing page - no locations found', this.mrn);
  }

  formatPhone(phone: string): string {
    const phoneNumber = phone || '';

    if (phoneNumber.length === 11) {
      return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '($2) $3-$4');
    } else if (phoneNumber.length === 10) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return phoneNumber;
  }

  updateLocation(locationInfo: string[]): void {
    this.locationIds = locationInfo[0];
    if (this.covidSupportUseV5) {
      this.setV5Provider(locationInfo[0]);
    }
    //filter by schedule id
    if (this.appointmentSchedules.data.length > 1)
      this.selectedAppointmentSchedule = this.appointmentSchedules.data.filter(s => s.id == locationInfo[1]);
    else this.selectedAppointmentSchedule = null;
    this.showModal = true;
  }

  

  sortLocationListByUserLocation(): void {
    if ((this.city && this.state) || this.zip) {
      const query = `${this.city || ''} ${this.state || ''} ${this.zip || ''}`;
      this.locationService.getLocationSuggestions(query).subscribe((location: any) => {
        if (location.result?.length > 0) {
          this.locationService.getTypedLocationDetail(location.result[0].googlePlaceId).subscribe((result) => {
            if (result?.result) {
              const position = result.result;
              this.sortLocationsWithDistance(position.latitude, position.longitude);
            }
          });
        }
      });
    }
  }

  sortLocationsWithDistance(lat: number, lng: number) {
    this.locations.forEach((location: CovidLocation) => {
      location.distanceInMiles = this.calculateDistanceInMiles(location.latitude, location.longitude, lat, lng);
    });
    if (this.filterLocationBy50Miles) {
      this.locations = this.locations.filter((x) => x.distanceInMiles <= 50);
    }
    if (this.locations.length > 0) {
      this.locations.sort((x: CovidLocation, y: CovidLocation) => x.distanceInMiles - y.distanceInMiles);
    } else {
      this.link('covid landing page - no locations found', this.mrn);
    }
  }

  calculateDistanceInMiles(lat1: number, lon1: number, lat2: number, lon2: number) {
    const p = 0.017453292519943295;
    const c = Math.cos;
    const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

    return 12742 * Math.asin(Math.sqrt(a)) * 0.621371;
  }

  link(action: string, mrn = '', label = '', category = 'search and schedule', eventTimestamp = '', eventTimeElapsed = ''): void {
    if (window['utag'] && window['utag'].link) {
      window['utag'].link({
        search_schedule_mrn: `${mrn}`,
        mrn: `${mrn}`,
        event_mrn: `${mrn}`,
        tealium_event: eventTimestamp ? `${category}:${action} - ${label}` : `${category}:${action}`,
        event_category: category,
        event_action: action,
        event_label: label,
        event_service_line: '',
        search_schedule_book_online: 'true',
        search_schedule_keyword: '',
        patientEngagement: 'true',
        event_timestamp: `${eventTimestamp}`,
        event_time_elapsed: `${eventTimeElapsed}`,
        patient_fname: this.covidPatientInfo ? this.covidPatientInfo.firstName || '' : '',
        patient_lname: this.covidPatientInfo ? this.covidPatientInfo.lastName || '' : '',
        patient_dob: this.covidPatientInfo ? this.covidPatientInfo.dateOfBirth || '' : '',
        patient_email: this.covidPatientInfo ? this.covidPatientInfo.eMail || '' : '',
        patient_phone: this.covidPatientInfo ? this.covidPatientInfo.phoneNumber || '' : '',
        event_appointment_booking_type: ''
      });
    }
  }
  mapLocationIdForCovidBookAgain(): void {
    Object.keys(this.marketLocations).forEach((market: string) => {
      const marketLocation: MarketLocation = this.marketLocations[market].find((marketLoc: MarketLocation) => {
        return marketLoc.name === this.locationIds;
      });
      if (marketLocation) {
        this.locationIds = marketLocation.id;
      }
    });
  }
  initMyTurnFlow(): void {
    let logoValue: string = null;
    this.schedulingService.getMarketLocations().subscribe(
      (markets: MarketLocation[]) => {
        this.marketLocations = markets;
        if (this.isBookAgain) {
          this.mapLocationIdForCovidBookAgain();
        }
        if (this.covidSupportUseV5) {
          Object.keys(this.marketLocations).forEach((market: string) => {
            this.marketLocations[market].forEach((marketLocation: MarketLocation) => {
              if (marketLocation.id === this.locationIds) {
                this.locationIds = marketLocation.idx_id;
                this.myTurnV5Location = marketLocation;
                logoValue = marketLocation.logo;
              }
            });
          });
        } else {
          //Added this else to handle useV5 = false flow to change logo as per market-locations.json file
          Object.keys(this.marketLocations).forEach((market: string) => {
            const matchingLogo: MarketLocation = this.marketLocations[market].find((marketLocation: MarketLocation) => {
              return marketLocation.id === this.locationIds;
            });

            if (matchingLogo) {
              logoValue = matchingLogo.logo;
            }
          });
        }
        this.getLocationsByAppointmentType(this.locationIds);
        this.getHeaderDHMGLogo(logoValue);
      },
      (error) => {
        this.authCallsFinished = true;
      }
    );
  }

  //Keep this to handle header logo changes occourding to the logo condition on market-location.json file
  getHeaderDHMGLogo(logoValue: string = null): void {
    let logoPath = null;
    if (logoValue) {
      logoPath = LogoConstants[logoValue.toLowerCase()];
    } else {
      //Covid flow with notableToken and cshToken
      if (this.marketLocations && (this.market || this.market === '')) {
        const distinctLogoList = this.marketLocations[this.market].filter(
          (item, i, arr) => arr.findIndex((t) => t.logo === item.logo) === i
        );
        if (distinctLogoList.length === 1 && distinctLogoList[0].logo) {
          logoPath = LogoConstants[distinctLogoList[0].logo.toLowerCase()];
        }
      }
    }
    if (!logoPath) {
      logoPath = LogoConstants.default;
    }
    this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.environmentBaseUrl}${logoPath}`);
  }

  returnToMyTurn(): void {
    window.location.href = 'https://myturn.ca.gov';
  }

  /* V5 API Replacement */
  getV5Schedule(): void {
    const eventStartTime = new Date().getTime();
    this.showSpinner = true;
    this.schedulingService.getV5AuthToken().subscribe(
      (authResults: any) => {
        const authToken = authResults.data.attributes['access-token'];

        let idxIds;
        if (!this.locationIds) {
          idxIds = this.marketLocations[this.market].map((marketLocations: MarketLocation) => {
            return marketLocations.idx_id;
          });
        } else {
          idxIds = [this.locationIds];
        }

        this.schedulingService.getV5AppointmentTypes().subscribe((appointmentTypes) => {
          this.v5AppointmentTypes = appointmentTypes;

          this.schedulingService.getLocationsByApptointmentTypeV5(this.apptTypes.split(','), idxIds, authToken, 'geSac').subscribe(
            (results) => {
              this.link(
                'get covid locations',
                '8',
                'end',
                'search and schedule',
                new Date().getTime().toString(),
                (new Date().getTime() - eventStartTime).toString()
              );

              this.v5Locations = this.combineDuplicateLocations(results.model);

              const mappedResults = this.v5Locations.map((v5Locations) => {
                let v5Location;
                if (this.isMyTurnPatient) {
                  v5Location = this.myTurnV5Location;

                  return {
                    attributes: {
                      location: {
                        address: v5Location.address.street,
                        city: v5Location.address.city,
                        latitude: null,
                        longitude: null,
                        name: v5Location.name,
                        phone: v5Location.phone,
                        state: v5Location.address.state,
                        zip: v5Location.address.zip,
                        id: v5Location.idx_id
                      },
                      'available-times': v5Locations.openTimes
                    }
                  };
                } else {
                    v5Location = (this.marketLocations[this.market] || []).find((mlocation: MarketLocation) => {
                    return mlocation.idx_id === v5Locations.location.sourceId;
                  });

                  return {
                    attributes: {
                      location: {
                        address: v5Location.address.street,
                        city: v5Location.address.city,
                        latitude: null,
                        longitude: null,
                        name: v5Location.name,
                        phone: v5Location.phone,
                        state: v5Location.address.state,
                        zip: v5Location.address.zip,
                        id: v5Locations.location.sourceId
                      },
                      'available-times': v5Locations.openTimes
                    }
                  };
                }
              });

              this.setLocations({ data: mappedResults });
              this.clickCovidLauncherBtn();
            },
            (err) => {
              this.noLocationsFound();
            }
          );
        });
      },
      (err) => {
        this.noLocationsFound();
      }
    );
  }

  setV5Provider(locationId): void {
    const selectedLocation = this.v5Locations.find((v5Location) => {
      return v5Location.location.sourceId === locationId;
    });

    let selectedMarketLocation;

    Object.keys(this.marketLocations).forEach((key: string) => {
      const marketLocation = this.marketLocations[key].find((mk) => {
        return mk.idx_id === locationId;
      });

      if (marketLocation) {
        selectedMarketLocation = marketLocation;
      }
    });

    const appointmentTimes = this.getV5AppointmentTimes(selectedLocation.openTimes);
    const nextAvailable = this.getNextV5AppointmentTimes(appointmentTimes);

    this.v5Provider = {
      displayFullName: selectedMarketLocation.name,
      acceptsNewPatients: true,
      images: null,
      primarySpecialty: 'Covid Vaccination',
      onlineBookingAvailability: [
        {
          appointmentTypes: this.v5AppointmentTypes,
          supportsOnlineBooking: true,
          providerSchedulingId: selectedLocation.provider.id,
          scheduleId: selectedLocation.location.id,
          officeAddressId: selectedLocation.location.id,
          facilitySchedulingId: selectedMarketLocation.id,
          availableTimes: appointmentTimes,
          nextAvailableTimes: nextAvailable
        }
      ],
      isBookOnline: true,
      offices: [
        {
          supportsOnlineBooking: true,
          id: selectedMarketLocation.id,
          name: selectedMarketLocation.name,
          addresses: [
            {
              name: selectedMarketLocation.name,
              id: selectedLocation.location.id,
              address: selectedMarketLocation.address.street,
              state: selectedMarketLocation.address.state,
              city: selectedMarketLocation.address.city,
              zip: selectedMarketLocation.address.zip,
              phone: selectedMarketLocation.phone,
              services: [],
              supportsOnlineBooking: true
            }
          ]
        }
      ]
    } as any;
  }

  getV5AppointmentTimes(v5Timeslots: { appointmentType: string; date: string; time: string }[]): any[] {
    const onlineBookingAvailability = [];
    const onlineBookingDays = {};

    v5Timeslots.forEach((timeSlot) => {
      const splitDate = timeSlot.date.split('/');
      const splitTime = timeSlot.time.split(':');
      const minutes = splitTime[1].substring(0, 2);
      const hourOffset = splitTime[1].substring(2, 4) === 'PM' ? 12 : 0;

      let hours = 12;
      if (splitTime[0] !== '12') {
        hours = Number.parseInt(splitTime[0], 10) + hourOffset;
      }

      const formattedDate = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
      const formattedTime = `${hours > 9 ? hours : '0' + hours.toString()}:${minutes}:00-07:00`;

      if (onlineBookingDays[formattedDate]) {
        onlineBookingDays[formattedDate].times.push({
          time: `${formattedDate}T${formattedTime}`,
          appointmentTypes: [
            {
              id: this.v5AppointmentTypes.find((at) => {
                return at.remoteId === timeSlot.appointmentType;
              }).id
            }
          ]
        });
      } else {
        onlineBookingDays[formattedDate] = {
          times: [
            {
              time: `${formattedDate}T${formattedTime}`,
              appointmentTypes: [
                {
                  id: this.v5AppointmentTypes.find((at) => {
                    return at.remoteId === timeSlot.appointmentType;
                  }).id
                }
              ]
            }
          ]
        };
      }
    });

    Object.keys(onlineBookingDays).forEach((key: string) => {
      onlineBookingAvailability.push({
        date: key,
        times: onlineBookingDays[key].times
      });
    });

    return onlineBookingAvailability || [];
  }

  getNextV5AppointmentTimes(onlineBookingAvailability): any[] {
    return this.v5AppointmentTypes.map((apptTypes) => {
      return {
        appointmentType: apptTypes.id,
        time: this.getNextV5AvailableTimeSlotsByApptTypeId(onlineBookingAvailability, apptTypes.id)
      };
    });
  }
  getNextV5AvailableTimeSlotsByApptTypeId(onlineBookingAvailability, apptTypeId: string): string {
    let returnValue = '';
    onlineBookingAvailability.some((dateSchedule) => {
      const appointmentTimes = dateSchedule.times.filter(
        (timeSchedule) => timeSchedule.appointmentTypes.findIndex((aType) => aType.id === apptTypeId) > -1
      );
      if (appointmentTimes.length > 0) {
        returnValue = appointmentTimes[0].time;
        return returnValue;
      }
    });
    return returnValue;
  }
  combineDuplicateLocations(v5Locations): any[] {
    const clonedLocation = [];

    v5Locations.forEach((v5Location) => {
      const existingIndex = clonedLocation.findIndex((cl) => {
        return cl.location?.sourceId === v5Location.location.sourceId;
      });

      if (existingIndex < 0) {
        clonedLocation.push(v5Location);
      } else {
        clonedLocation[existingIndex].openTimes = clonedLocation[existingIndex].openTimes.concat(v5Location.openTimes);
      }
    });

    return clonedLocation;
  }
}
