import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ApiConstants } from 'src/app/constants/api-constants.legacy';
import { GlobalParams } from 'src/app/global-params';
import { ApiResponse } from 'src/app/utils/api-response';
import { RuntimeConfiguration } from '../configuration-injection.service';
import { ConfigurationService } from '../configuration.service';
import { LocationByIpResult, LocationDetailResult, LocationSuggestionResult, SelectedLocation } from '../../models/location';
import { LocationService } from '../location.service';
import { SessionObjectNames } from 'src/app/models/stringconst-names';

@Injectable({
  providedIn: 'root'
})
export class LocationLegacyService extends LocationService {
  apiOptions: { headers: HttpHeaders };
  runtimeConfig: RuntimeConfiguration;
  public apiConstants: { [key: string]: string };
  public apiBaseUrl: string;
  constructor(public http: HttpClient, public configurationService: ConfigurationService) {
    super();
    this.runtimeConfig = this.configurationService.getRuntimeConfiguration();
    this.apiBaseUrl = this.runtimeConfig.apiBaseUrl;
    this.apiConstants = ApiConstants;
    this.apiOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-tenant': this.configurationService.getTenantCode(),
        'ocp-apim-subscription-key': this.runtimeConfig.apimSubscriptionKey
      })
    };
  }

  getTypedLocationSuggestions(location: string, sessionToken?: string): Observable<ApiResponse<LocationSuggestionResult[]>> {
    const zipPattern = /\d+/g;
    if (location?.match(zipPattern) && location?.split(',').length < 3 && !GlobalParams.isNeighborhoodSearch) {
      location = location.replace(/^\D+/g, '').trim();
    }
    const token = sessionToken ?? window.sessionStorage.getItem(SessionObjectNames.LocationSessionId) ?? '';
    let httpParams = new HttpParams();
    httpParams = httpParams.append('latitude', null);
    httpParams = httpParams.append('longitude', null);
    httpParams = httpParams.append('api-version', '2');
    httpParams = httpParams.append('sessionToken', token);
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}${encodeURIComponent(location)}/locations`;

    return this.http.get<ApiResponse<LocationSuggestionResult[]>>(url, {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  getLocationSuggestions(query: string, sessionToken = '') {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('latitude', null);
    httpParams = httpParams.append('longitude', null);
    httpParams = httpParams.append('api-version', '2');
    httpParams = httpParams.append('sessionToken', sessionToken);
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}${query}/locations`;
    return this.http.get(url.toString(), {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  getLocationByIP(): Observable<ApiResponse<LocationByIpResult>> {
    const LOCATION_DETAILS_API = `${this.apiBaseUrl}${this.apiConstants.locationDetailsApi}`;
    return this.http.get<ApiResponse<LocationByIpResult>>(`${LOCATION_DETAILS_API}`, this.apiOptions);
  }

  getTypedLocationDetail(placeId: string): Observable<ApiResponse<LocationDetailResult>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', '2');
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}${placeId}/location-detail`;

    return this.http
      .get<ApiResponse<LocationDetailResult>>(url, {
        headers: this.apiOptions.headers,
        params: httpParams
      })
      .pipe(
        tap((response) => {
          if (response.result) {
            window.sessionStorage.setItem(SessionObjectNames.LocationSessionId, response.result.sessionToken);
          }
        })
      );
  }

  getLocationDetail(placeId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', '2');
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}${placeId}/location-detail`;
    return this.http.get<ApiResponse<LocationDetailResult>>(url.toString(), {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  public queryLocation(location: string): Observable<SelectedLocation> {
    if (!location) {
      return of(null);
    }
    return this.getTypedLocationSuggestions(location).pipe(
      catchError(() => of(null)),
      concatMap((suggestionResponse: ApiResponse<LocationSuggestionResult[]>) => {
        if (!suggestionResponse.isValid || suggestionResponse.result.length === 0) {
          return of({ isLocationValid: false } as SelectedLocation);
        }
        const zipPattern = /\d+/g;

        let placeId = '';
        const isZipExist = location.match(zipPattern);
        if (isZipExist) {
          placeId = suggestionResponse.result[0].googlePlaceId;
        } else {
          placeId = suggestionResponse.result.find((l) =>
            l.locationName
              .replace(/[,.]|-|\s/g, '')
              .toLowerCase()
              .trim()
              .includes(
                location
                  .replace(/[,.]|-|\s/g, '')
                  .toLowerCase()
                  .trim()
              )
          ).googlePlaceId;
        }
        return this.getTypedLocationDetail(placeId).pipe(
          map((detailResponse) => {
            GlobalParams.isNeighborhoodSearch = false;
            return {
              googlePlaceId: placeId,
              name: detailResponse.result.name + (isZipExist && detailResponse.result.zip ? ' ' + detailResponse.result.zip : ''),
              neighborhood: detailResponse.result.neighborhood ? detailResponse.result.neighborhood : '',
              city: detailResponse.result.city,
              state: detailResponse.result.state,
              latitude: detailResponse.result.latitude,
              longitude: detailResponse.result.longitude,
              zip: isZipExist ? detailResponse.result.zip : '',
              isLocationValid: true
            } as SelectedLocation;
          }),
          catchError(() => of(null))
        );
      })
    );
  }
}
