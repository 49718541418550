import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cs-care-philosophy',
  templateUrl: './care-philosophy.component.html',
  styleUrls: ['./care-philosophy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarePhilosophyComponent {
  @Input() philosophy: string;
  @Input() hideExtraContent: boolean;
  moreLessExpanded = false;
  @Input() showMoreLessButton: boolean;
}
