<div class="provider-hero-container" *ngIf="provider">
  <cs-suggest-an-edit
    [suggestAnEditLinkContent]="suggestAnEditLinkContent"
    *ngIf="showSuggestAnEditLink && !isDesktopViewport; else notShowSuggestEdit"
  >
  </cs-suggest-an-edit>

  <div class="basic-info-mobile" *ngIf="isMobileViewport">
    <div class="provider-image-container-m mobile">
      <div class="print" *ngIf="showPrintablePdf">
        <a (click)="printPage()"
          ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.2545 8.31169H16.9498V5.81818C16.9498 5.61039 16.8777 5.4026 16.7337 5.22078L12.1001 0.233766C11.9561 0.077922 11.764 0 11.5479 0H3.8413C3.40916 0 3.07304 0.363636 3.07304 0.831169V8.33766H0.768261C0.336114 8.33766 0 8.7013 0 9.16883V15.8442C0 16.3117 0.336114 16.6753 0.768261 16.6753H3.07304V19.1688C3.07304 19.6364 3.40916 20 3.8413 20H16.1575C16.5896 20 16.9257 19.6364 16.9257 19.1688V16.6753H19.2305C19.6627 16.6753 19.9988 16.3117 19.9988 15.8442V9.14286C20.0228 8.67532 19.6867 8.31169 19.2545 8.31169ZM12.3162 2.83117L14.3089 4.98701H12.3162V2.83117ZM4.60957 1.63636H9.21913C10.0594 1.63636 10.7557 2.38961 10.7557 3.2987V5.81818C10.7557 6.28571 11.0918 6.64935 11.5239 6.64935H15.3652V8.31169H4.60957V1.63636ZM15.4132 18.3117H4.60957V15.8961H15.3892V18.3117H15.4132Z"
              fill="#0071C2"
            />
          </svg>
          <span>Print</span></a
        >
      </div>
      <img
        class="provider-image-mobile"
        src="{{ providerImage.url }}"
        alt="{{ provider.displayFullName }}"
        (error)="getAltImageSrc($event)"
      />
    </div>
    <div class="provider-heading-mobile">
      <div class="provider-name">
        <h1>
          {{ provider.displayFullName }}
          <img *ngFor="let imageUrl of logoUrls" src="{{ imageUrl }}" alt="" class="provider-logo" />
          <div class="provider-specialty-m">
            <p *ngFor="let speciality of providerSpecialities" class="p1">{{ speciality }}</p>
          </div>
        </h1>
      </div>
      <div class="provider-specialty-m">
        <p *ngFor="let nonSEOSpeciality of nonSEOSecondarySpecialities" class="p1">{{ nonSEOSpeciality }}</p>
      </div>
      <div *ngIf="showStarRatings && showStarRatingsBanner">
        <div *ngIf="provider.rating" class="provider-rating-m" [ngClass]="{ 'has-rating': provider.rating?.totalRatingCount > 0 }">
          <dhcl-star-rating
            [reviews]="provider.rating?.totalRatingCount"
            [rating]="provider.rating?.overallRating.value"
            [showRatingText]="showRatingText"
            [showNumReviews]="showNumReviews"
            (click)="provider.rating?.totalRatingCount !== 0 ? goToComments() : false"
          ></dhcl-star-rating>
          <cs-tooltip *ngIf="provider.rating?.totalRatingCount === 0 || provider.rating?.overallRating.value === 0" class="rating-tooltip">
            {{ ratingTooltipText }}
          </cs-tooltip>
        </div>
        <div *ngIf="provider.loadingRating" class="stars-container-mobile">
          <div class="stars">
            <svg
              *ngFor="let star of stars; let i = index"
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  xmlns="http://www.w3.org/2000/svg"
                  y1="100%"
                  x2="0"
                  y2="100%"
                  id="grad107b5e38-b154-4ba7-931d-08d12ec9961f-4"
                  x1="1%"
                >
                  <stop stop-color="#727779" offset="0%"></stop>
                  <stop stop-color="var(--dhcl-color-yellow)" offset="0%"></stop>
                </linearGradient>
              </defs>
              <path
                d="M8.67027 0.871503C8.85549 0.376165 9.55613 0.376166 9.74135 0.871502L11.1349 4.59828C11.3558 5.18902 11.9084 5.59051 12.5385 5.61804L16.5135 5.79174C17.0418 5.81482 17.2583 6.48117 16.8444 6.81039L13.7307 9.28736C13.2371 9.67999 13.0261 10.3296 13.1946 10.9374L14.2577 14.7715C14.399 15.2811 13.8322 15.6929 13.3912 15.4011L10.0733 13.2051C9.54734 12.8571 8.86428 12.8571 8.33834 13.2051L5.02041 15.4011C4.57941 15.6929 4.01258 15.2811 4.15389 14.7715L5.21704 10.9374C5.38556 10.3296 5.17448 9.67999 4.68092 9.28736L1.56717 6.81039C1.15331 6.48117 1.36982 5.81482 1.89815 5.79174L5.87315 5.61804C6.50323 5.59051 7.05584 5.18902 7.27674 4.59828L8.67027 0.871503Z"
                fill="url(#grad107b5e38-b154-4ba7-931d-08d12ec9961f-4)"
                stroke="#DE7925"
              ></path>
            </svg>
          </div>
          <em class="loading-text">Loading ratings...</em>
        </div>
      </div>
      <div class="provider-bagdes-m">
        <cs-badges [provider]="provider"></cs-badges>
      </div>
    </div>
  </div>
  <div class="basic-info" *ngIf="!isMobileViewport">
    <div class="provider-image-container">
      <img class="provider-image" src="{{ providerImage.url }}" alt="{{ provider.displayFullName }}" (error)="getAltImageSrc($event)" />
    </div>
    <div class="provider-heading">
      <div class="provider-name">
        <div>
          <h1 style="width: 100%">
            {{ provider.displayFullName }}
            <img *ngFor="let imageUrl of logoUrls" src="{{ imageUrl?.url }}" alt="{{ imageUrl?.altName }}" class="provider-logo" />
            <div class="provider-specialty">
              <p *ngFor="let speciality of providerSpecialities" class="p1">{{ speciality }}</p>
            </div>
          </h1>
          <div class="provider-specialty">
            <p *ngFor="let nonSEOSpeciality of nonSEOSecondarySpecialities" class="p1">{{ nonSEOSpeciality }}</p>
          </div>
        </div>
        <div class="print" *ngIf="showPrintablePdf">
          <a (click)="printPage()"
            ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.2545 8.31169H16.9498V5.81818C16.9498 5.61039 16.8777 5.4026 16.7337 5.22078L12.1001 0.233766C11.9561 0.077922 11.764 0 11.5479 0H3.8413C3.40916 0 3.07304 0.363636 3.07304 0.831169V8.33766H0.768261C0.336114 8.33766 0 8.7013 0 9.16883V15.8442C0 16.3117 0.336114 16.6753 0.768261 16.6753H3.07304V19.1688C3.07304 19.6364 3.40916 20 3.8413 20H16.1575C16.5896 20 16.9257 19.6364 16.9257 19.1688V16.6753H19.2305C19.6627 16.6753 19.9988 16.3117 19.9988 15.8442V9.14286C20.0228 8.67532 19.6867 8.31169 19.2545 8.31169ZM12.3162 2.83117L14.3089 4.98701H12.3162V2.83117ZM4.60957 1.63636H9.21913C10.0594 1.63636 10.7557 2.38961 10.7557 3.2987V5.81818C10.7557 6.28571 11.0918 6.64935 11.5239 6.64935H15.3652V8.31169H4.60957V1.63636ZM15.4132 18.3117H4.60957V15.8961H15.3892V18.3117H15.4132Z"
                fill="#0071C2"
              />
            </svg>
            <span>Print</span></a
          >
        </div>
        <cs-suggest-an-edit
          [suggestAnEditLinkContent]="suggestAnEditLinkContent"
          *ngIf="showSuggestAnEditLink && isDesktopViewport; else notShowSuggestEdit"
        >
        </cs-suggest-an-edit>
      </div>
      <div *ngIf="showStarRatings">
        <div *ngIf="provider.rating" class="provider-rating" [ngClass]="{ 'has-rating': provider.rating?.totalRatingCount > 0 }">
          <dhcl-star-rating
            [reviews]="provider.rating?.totalRatingCount"
            [rating]="provider.rating?.overallRating.value"
            [showRatingText]="showRatingText"
            [showNumReviews]="showNumReviews"
            (click)="provider.rating?.totalRatingCount !== 0 ? goToComments() : false"
          ></dhcl-star-rating>
          <cs-tooltip *ngIf="provider.rating?.totalRatingCount === 0 || provider.rating?.overallRating.value === 0" class="rating-tooltip">
            {{ ratingTooltipText }}
          </cs-tooltip>
        </div>
        <div *ngIf="provider.loadingRating" class="stars-container">
          <div class="stars">
            <svg
              *ngFor="let star of stars; let i = index"
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  xmlns="http://www.w3.org/2000/svg"
                  y1="100%"
                  x2="0"
                  y2="100%"
                  id="grad107b5e38-b154-4ba7-931d-08d12ec9961f-4"
                  x1="1%"
                >
                  <stop stop-color="#727779" offset="0%"></stop>
                  <stop stop-color="var(--dhcl-color-yellow)" offset="0%"></stop>
                </linearGradient>
              </defs>
              <path
                d="M8.67027 0.871503C8.85549 0.376165 9.55613 0.376166 9.74135 0.871502L11.1349 4.59828C11.3558 5.18902 11.9084 5.59051 12.5385 5.61804L16.5135 5.79174C17.0418 5.81482 17.2583 6.48117 16.8444 6.81039L13.7307 9.28736C13.2371 9.67999 13.0261 10.3296 13.1946 10.9374L14.2577 14.7715C14.399 15.2811 13.8322 15.6929 13.3912 15.4011L10.0733 13.2051C9.54734 12.8571 8.86428 12.8571 8.33834 13.2051L5.02041 15.4011C4.57941 15.6929 4.01258 15.2811 4.15389 14.7715L5.21704 10.9374C5.38556 10.3296 5.17448 9.67999 4.68092 9.28736L1.56717 6.81039C1.15331 6.48117 1.36982 5.81482 1.89815 5.79174L5.87315 5.61804C6.50323 5.59051 7.05584 5.18902 7.27674 4.59828L8.67027 0.871503Z"
                fill="url(#grad107b5e38-b154-4ba7-931d-08d12ec9961f-4)"
                stroke="#DE7925"
              ></path>
            </svg>
          </div>
          <em class="loading-text">Loading ratings...</em>
        </div>
      </div>
      <div>
        <cs-badges [provider]="provider"></cs-badges>
      </div>
    </div>
  </div>
  <ng-template #notShowSuggestEdit>
    <div class="no-show-suggest-edit"></div>
  </ng-template>
</div>
