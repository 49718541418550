import { Injectable } from '@angular/core';
import { FadEvent } from '../models/fad-event';
import { FadEventTypes } from '../models/fad-event-types';
import { ConfigurationService } from './configuration.service';
import { EventPublisherService } from './event-publisher.service';

@Injectable({
  providedIn: 'root'
})
export class CernerModalService {
  constructor(private eventPublisherService: EventPublisherService, private configurationService: ConfigurationService) {}

  // css class for cerner modal
  cernerModalClass = 'modal-cerner-content';

  modalOpened = 'Modal Opened';
  modalClosed = 'Modal Closed';

  embedSource = this.configurationService.getEmbedSource();

  publishVideoClickedEvent(event: any) {
    this.eventPublisherService.publish(
      new FadEvent({
        type: FadEventTypes.ViewVideoClicked,
        data: event
      })
    );
  }

  openModalForCerner() {
    if (this.embedSource === 'cerner' || this.embedSource === 'mychart') this.publishVideoClickedEvent(this.modalOpened);
  }

  closeModalForCerner() {
    if (this.embedSource === 'cerner' || this.embedSource === 'mychart') this.publishVideoClickedEvent(this.modalClosed);
  }

  getCernerClass(className){
    return (this.embedSource === 'cerner' || this.embedSource === 'mychart') ? `${className} ${this.cernerModalClass}` : className;
  }
}
