<div
  tabindex="0"
  role="switch"
  [attr.aria-label]="'Slider toggle'"
  class="bar"
  (keydown.enter)="switchToggle()"
  (keydown.space)="switchToggle()"
  (click)="switchToggle()"
  [attr.aria-checked]="checked"
  [attr.aria-disabled]="disabled"
  [ngClass]="{ 'slider-disable': disabled }"
  [ngStyle]="{ 'background-color': checked ? 'var(--dhcl-color-secondary)' : barUncheckedBgColor || '#727779' }"
  (blur)="onFocusLost($event)"
>
  <span
    class="slider"
    [ngClass]="{ toggle: checked, 'disabled':disabled }"
    [ngStyle]="{ 'background-color': checked ? sliderCheckedColor || '#fff' : sliderUncheckedColor || '#fff' }"
  ></span>
</div>
