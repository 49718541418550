import { UrlMatchResult, UrlSegment } from '@angular/router';

/**
 * FAD needs to match any of the routes here and use the SearchResultsComponent
 * https://dignityhealth.atlassian.net/wiki/spaces/OOD/pages/340951041/Marketing
 * This requires a custom route matcher to match all these specific routes
 * and reuse the same SearchResultsComponent.
 * Without that, new instances of SearchResultsComponent will be created, and
 * a custom route reuse strategy won't work easily.
 *
 * @param url Url to match on
 */
export function fadSearchResultsVanityUrlMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length === 0) {
    return null;
  }
  if (url[0].path.match(/^t$/s)) {
    return {
      consumed: url,
      posParams: {
        searchType: url[0],
        searchTerm: getUrlSegmentOrEmptyUrlSegment(url, 1)
      }
    };
  } else if (url[0].path.match(/^l$/s)) {
    return {
      consumed: url,
      posParams: {
        searchType: url[0],
        location: getUrlSegmentOrEmptyUrlSegment(url, 1)
      }
    };
  } else if (url[0].path.match(/^m$/s)) {
    return {
      consumed: url,
      posParams: {
        searchType: url[0],
        medGroup: getUrlSegmentOrEmptyUrlSegment(url, 1),
        searchTerm: getUrlSegmentOrEmptyUrlSegment(url, 2)
      }
    };
  } else if (url[0].path.match(/^r$/s)) {
    return {
      consumed: url,
      posParams: {
        searchType: url[0],
        location: getUrlSegmentOrEmptyUrlSegment(url, 1),
        searchTerm: getUrlSegmentOrEmptyUrlSegment(url, 2)
      }
    };
  } else if (url[0].path.match(/^s$/s)) {
    return {
      consumed: url,
      posParams: {
        searchType: url[0],
        specialty: getUrlSegmentOrEmptyUrlSegment(url, 1),
        location: getUrlSegmentOrEmptyUrlSegment(url, 2)
      }
    };
  }
  return null;
}

export function fadProviderDetailsUrlMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length === 0) {
    return null;
  }

  if (url.length > 1) {
    return {
      consumed: url,
      posParams: {
        specialty: url[0],
        providerId: url[1]
      }
    };
  } else {
    return {
      consumed: url,
      posParams: {
        providerId: url[0]
      }
    };
  }
}

function getUrlSegmentOrEmptyUrlSegment(urlSegments: UrlSegment[], index: number): UrlSegment {
  return urlSegments.length > index ? urlSegments[index] : new UrlSegment('', {});
}
