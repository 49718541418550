<div>
  <a href="javascript:void(0)" (click)="returnToResults()">
    <button #bckSearchbtn class="search-results-return" aria-hidden="true" tabindex="-1">
      <svg viewBox="0 0 9 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 1L1 8L8 15" />
      </svg>
      {{btnContent}}
    </button>
  </a>
</div>
