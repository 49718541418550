import { Component, SimpleChanges, 
  Input, Output, OnChanges, OnInit,
  ViewChild, ElementRef, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { FacetSummary } from '../../../models/filters';


@Component({
selector: "cs-multiple-checkbox-searchable-dropdown",
templateUrl: './multiple-checkbox-searchable-dropdown.component.html',
styleUrls: ['./multiple-checkbox-searchable-dropdown.component.scss'],
changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleCheckboxSearchableDropdownComponent implements OnInit, OnChanges {
  @Input() dropdowns: FacetSummary[] = [];
  @Input() showCount = true;
  @Input() placeholder: string;

  @Output() selectedDropDownValues: EventEmitter<FacetSummary[]> = new EventEmitter<FacetSummary[]>();
  @ViewChild('searchinput') searchinput: ElementRef;

  filterDropdownList: FacetSummary[] = [];
  selectedItems: FacetSummary[] = [];
  oldSelectedItems: FacetSummary[] = [];
  placeholderInput = '';
  noResultsFound = 'No results found';
  showDropDown = false;

  ngOnInit(): void {
    this.placeholderInput = this.placeholder || '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dropdowns) {
      this.filterDropdownList = [...this.dropdowns];
      if (this.filterDropdownList.length === 0 && this.selectedItems.length === 0) {
        this.placeholderInput = this.noResultsFound;
        this.showDropDown = false;
      } else {
        this.filterDropdownList.sort((a, b) => Number(b.selected) - Number(a.selected));
        this.selectedItems = this.oldSelectedItems = this.getSelectedDropdowns();
        this.placeholderInput = this.placeholder;
        this.showDropDown = true;
      }
    }
  }

  itemClickHandler(dropdown: FacetSummary): void {
    this.filterDropdownList = [...this.dropdowns];
    this.filterDropdownList = this.markSelected(this.filterDropdownList, dropdown);
    this.shiftSelectedEntries(this.filterDropdownList);
    this.selectedItems = this.getSelectedDropdowns();
  }

  markSelected = (dropdowns: FacetSummary[], dropdown: FacetSummary): FacetSummary[] => {
    const index = dropdowns.findIndex(t => t.name === dropdown.name);
    dropdowns[index].selected = !dropdown.selected;
    return dropdowns;
  }

  shiftSelectedEntries = (dropdowns: FacetSummary[]): void => {
    for(const [i, dropdown] of dropdowns.entries()) {
      if(dropdown.selected) {
        dropdowns.splice(i, 1);
        dropdowns.unshift(dropdown);
      }
    }
  }

  inputSearch(): void {
    this.filterDropdownList = [...this.dropdowns];
    const searchTerm = this.searchinput?.nativeElement?.value;
    if (searchTerm) {
      this.filterDropdownList = this.filterDropdownList.filter(dropdown => dropdown.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  getSelectedDropdowns(): FacetSummary [] {
    return this.filterDropdownList.filter(dropdown => dropdown.selected);
  }

  trackByFn(index, item): string {
    return item.name;
  }

  onSelectedItemClick(event, dropdown: FacetSummary): void {
    this.itemClickHandler(dropdown);
  }

  applyFilters(): void {
    if (!this.objectsAreSame(this.oldSelectedItems, this.selectedItems)) {
      this.showDropDown = false;
      this.selectedDropDownValues.emit(this.selectedItems);
      this.selectedItems = [];
    }
  }

  clearFilters(): void {
    this.filterDropdownList = this.filterDropdownList.map(dropdown => {
      return { ...dropdown, selected: false } as FacetSummary;
    });
    this.showDropDown = true;
    this.selectedItems = [];
  }

  objectsAreSame(obj1: FacetSummary[], obj2: FacetSummary[]): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
 }
}