import { APP_BASE_HREF, Location, DOCUMENT } from '@angular/common';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import {
  ApplicationRef, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA,
  DoBootstrap, ErrorHandler, Injector,
  LOCALE_ID,
  NgModule,
  Type
} from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute, NavigationEnd, Router, RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DignityHealthComponentsModule } from './dignity-health-components.module';
import { SchedulingComponent } from './pages/scheduling/scheduling.component';
import { ConfigurationInjectionService } from './services/configuration-injection.service';
import { TealiumJsUtagService } from './services/tealium-js-utag.service';

import { filter } from 'rxjs/operators';
import { SharedModule } from './components/shared.module';
import { CoreModule } from './core/core.module';
import { mapRouterEventToFadEvent } from './models/fad-event';
import { PipesModule } from './pipes/pipes.module';
import { ConfigurationService } from './services/configuration.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { EventPublisherService } from './services/event-publisher.service';
import { FadLinkService } from './services/fad-link.service';
import { FilterService } from './services/filter.service';
import { FilterServiceFactory } from './services/filter/filter.service.factory';
import { LocationService } from './services/location.service';
import { LocationServiceFactory } from './services/location/location.service.factory';
import { ProviderService } from './services/provider.service';
import { ProviderServiceFactory } from './services/provider/provider.service.factory';
import { CustomRouteReuseStrategy } from './utils/custom-route-reuse-strategy';
import { FadAngularElements, getBaseLocation, getRootElement } from './utils/root-element';
import { ROOT_ELEMENT } from './utils/utils';
import { FindDoctorWidgetModule } from './pages/find-doctor-widget/find-doctor-widget.module';
import { VaccinationLocationModule } from './pages/vaccination-location/vaccination-location.module';
import { FadLiteSearchLandingModule } from './pages/fad-lite-search-landing/fad-lite-search-landing.module';
import { SearchLandingModule } from './pages/search-landing/search-landing.module';
import { SearchResultsModule } from './pages/search-results/search-results.module';
import { ProviderDetailsModule } from './pages/provider-details/provider-details.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { OverrideUrlSerializer } from './services/override-url-serializer.service';
import { OidcConfigService, OidcSecurityService, resolveOidcConfigService, resolveOidcSecurityService } from 'identity-manager-client-library';
import { AuthHelperService } from './services/auth-helper.service';

const appInitializer = (
  configInjService: ConfigurationInjectionService,
  oidcConfigService: OidcConfigService,
  authHelper: AuthHelperService
): (() => Promise<any>) => {
  return () =>
    configInjService
      .load()
      .then(async () => {
        await authHelper.configureAuth(oidcConfigService);
      })
      .catch(error => {
        console.log(error.message);
      });
};

@NgModule({
  declarations: [AppComponent, SchedulingComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    DignityHealthComponentsModule,
    GoogleMapsModule,
    PipesModule,
    CoreModule,
    SharedModule,
    FadLiteSearchLandingModule,
    VaccinationLocationModule,
    FindDoctorWidgetModule,
    SearchLandingModule,
    SearchResultsModule,
    ProviderDetailsModule
  ],

  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: ROOT_ELEMENT, useFactory: getRootElement },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation,
      deps: [ROOT_ELEMENT]
    },
    { provide: Document, useExisting: DOCUMENT },
    ConfigurationInjectionService,
    AuthHelperService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [ConfigurationInjectionService, OidcConfigService, AuthHelperService]
    },
    {
      provide: ProviderService,
      useFactory: ProviderServiceFactory,
      deps: [HttpClient, ConfigurationService]
    },
    {
      provide: LocationService,
      useFactory: LocationServiceFactory,
      deps: [HttpClient, ConfigurationService]
    },
    {
      provide: FilterService,
      useFactory: FilterServiceFactory,
      deps: [ProviderService, ConfigurationService, ActivatedRoute]
    },
    TealiumJsUtagService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    {
      provide: UrlSerializer,
      useClass: OverrideUrlSerializer
    },
    { 
      provide: OidcConfigService, 
      useValue: resolveOidcConfigService() 
    },
    {
      provide: OidcSecurityService,
      useFactory: resolveOidcSecurityService
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule implements DoBootstrap {
  constructor(
    private injector: Injector,
    private router: Router,
    private location: Location,
    private fadLinkService: FadLinkService,
    private eventPublisherService: EventPublisherService
  ) {
    // This router subscription was initially in the AppComponent, but there was an inconsistency.
    // Running locally with `ng serve`, the first `RouteChanged` event would be captured and logged
    // to the browser console. After concatenating our js files together and running on a web server,
    // the very first `RouteChanged` event stopped being fired. Moving the subscription to the AppModule
    // made it so that we fire the `RouteChanged` event every time.
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => this.eventPublisherService.publish(mapRouterEventToFadEvent(event)));

    if (!this.fadLinkService.isSelfHosted()) {
      // Without this, the FAD SPA as an Angular element will not load the initial route, or do routing.
      // This seems to be following this article to make it work
      // eslint-disable-next-line
      // http://chatwithme.io/angularjs%20upgrade/web%20components/angular%20elements/angular/angularjs/ngupgrade/2019/04/25/shipping-an-angular-application-as-a-web-component.html
      // Other possible resources to consider:
      // https://medium.com/@timon.grassl/how-to-use-routing-in-angular-web-components-c6a76449cdb
      // https://github.com/fboeller/ngx-elements-router
      this.router.navigateByUrl(this.location.path(true)).then();
      this.location.subscribe((data) => {
        this.router.navigateByUrl(data.url).then();
      });
    }
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    if (document.body.querySelector('cs-fad')) {
      appRef.bootstrap(AppComponent);
    }
    FadAngularElements.forEach((e) => this.registerCustomElement(e[1], e[0]));
  }

  /**
   * Registers a Component as a custom element if it's not already registered.
   */
  registerCustomElement(componentType: Type<any>, name: string): void {
    if (!customElements.get(name)) {
      const component = createCustomElement(componentType, { injector: this.injector });
      customElements.define(name, component);
    }
  }
}
