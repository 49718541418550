import { InjectionToken } from '@angular/core';
import { SessionObjectNames } from '../models/stringconst-names';
import { getRootElement } from './root-element';

export function hiddenContentAvailable(parentContainerSelector: string, contentLineHeightSelector: string): boolean {
  if (document.getElementsByClassName(parentContainerSelector)[0]) {
    return (
      document.getElementsByClassName(parentContainerSelector)[0].scrollHeight /
        parseInt(window.getComputedStyle(document.querySelectorAll(contentLineHeightSelector)[0]).lineHeight.slice(0, 2), 10) >
      6
    );
  }

  return false;
}

export function checkDesktopViewport(width: number): boolean {
  return width > 767;
}

export const ROOT_ELEMENT = new InjectionToken<HTMLElement>('root-element');

export function setDialogPosition(): void {
  const dialogs = Array.from(document.getElementsByClassName('filter-dialog'));
  dialogs.forEach((element: any) => {
    element.style.left = 'initial';
    element.style.right = 'initial';

    if (element.getBoundingClientRect().right > document.body.clientWidth) {
      element.style.right = 0;
      element.style.left = 'initial';
    } else if (element.getBoundingClientRect().left < 0) {
      element.style.right = 'initial';
    }
  });
}

export function inFadLiteMode(): boolean {
  let fadLiteSetting = false;
  const root = getRootElement();
  // parse enable fad lite attribute
  const enableFadLite = (
    root?.hasAttribute('enable-fad-lite')
      ? root?.getAttribute('enable-fad-lite')
      : root?.hasAttribute('enableFadLite')
      ? root?.getAttribute('enableFadLite')
      : root?.hasAttribute('enablefadlite')
      ? root?.getAttribute('enablefadlite')
      : 'false'
  )?.replace(/^\/+|\/+$/g, '');
  if (
    enableFadLite != undefined &&
    enableFadLite != null &&
    enableFadLite.trim() != '' &&
    (enableFadLite.toLowerCase() === 'true' || enableFadLite.toLowerCase() === 'false')
  ) {
    fadLiteSetting = Boolean(JSON.parse(enableFadLite.toLowerCase() ?? 'false'));
  }
  // check for base url attribute
  const baseUrl = (
    root?.hasAttribute('base-url')
      ? root?.getAttribute('base-url')
      : root?.hasAttribute('baseUrl')
      ? root?.getAttribute('baseUrl')
      : root?.hasAttribute('baseurl')
      ? root?.getAttribute('baseurl')
      : ''
  )
    ?.replace(/^\/+|\/+$/g, '')
    ?.trim();

  return fadLiteSetting && !baseUrl;
}

export function clearAllSessionVariables(): void {
  for (const sessionConstant in SessionObjectNames) {
    window.sessionStorage.removeItem(SessionObjectNames[sessionConstant]);
  }
}
