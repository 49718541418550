import { Type } from '@angular/core';
import { AppComponent } from '../app.component';
import { SearchLandingComponent } from '../pages/search-landing/search-landing.component';
import { ProviderDetailsComponent } from '../pages/provider-details/provider-details.component';
import { SchedulingComponent } from '../pages/scheduling/scheduling.component';
import { ProviderCardComponent } from '../components/provider-card/provider-card.component';
import { FindDoctorWidgetComponent } from '../pages/find-doctor-widget/find-doctor-widget.component';
import { VaccinationLocationComponent } from '../pages/vaccination-location/vaccination-location.component';
import { ResultCardComponent } from '../components/result-card/result-card.component';
import { FadLiteSearchLandingComponent } from '../pages/fad-lite-search-landing/fad-lite-search-landing.component';

export function getBaseLocation(root: HTMLElement): string {
  const base = root.getAttribute('base-url')?.replace(/^\/+|\/+$/g, '');
  return `/${base ?? ''}`;
}

export function getRootElement(): HTMLElement {
  const csFadEl = document.body.querySelector<HTMLElement>('cs-fad');
  if (csFadEl) {
    return csFadEl;
  } else {
    const angularElement = FadAngularElements.find((e: [string, Type<any>]) => {
      return !!document.body.querySelector<HTMLElement>(e[0]);
    });
    return angularElement ? document.body.querySelector<HTMLElement>(angularElement[0]) : null;
  }
}

export const FadAngularElements: [string, Type<any>][] = [
  ['fad-saas', AppComponent],
  ['fad-landing-page', SearchLandingComponent],
  ['fad-provider-details', ProviderDetailsComponent],
  ['fad-scheduling', SchedulingComponent],
  ['fad-provider-cards', ProviderCardComponent],
  ['fad-find-a-doctor', FindDoctorWidgetComponent],
  ['fad-covid-vaccine-launch', VaccinationLocationComponent],
  ['fad-provider-hero-card', ResultCardComponent],
  ['fad-lite-landing-page', FadLiteSearchLandingComponent]
];
