<div class="desktop-filter-buttons" #filtersContainer>
  <div *ngIf="isDesktopViewport">
    <div class="leftPanelHeader">
      <span class="filtersSpan">Filters</span>
      <div
        class="clear-all-btn"
        tabindex="0"
        role="button"
        aria-label="Clear all"
        (keydown.enter)="clearDesktopFilters()"
        (click)="clearDesktopFilters()"
      >
        <span>Clear all</span>
      </div>
    </div>

    <div class="filter-container">
      <div class="online-scheduling" *ngIf="showOnlineSchedulingFilter" [attr.aria-label]="'Online scheduling'" tabindex="0">
        <div class="section-header toggle-col-option" [ngClass]="{'disabled': filtersModel.onlineSchedulingProvidersCount === 0 }">
          <span>Online scheduling <span class="option-count">({{ filtersModel.onlineSchedulingProvidersCount }})</span></span>
          <cs-slide-toggle
            [disabled]="filtersModel.onlineSchedulingProvidersCount === 0"
            [checked]="onlineSchedulingToggle"
            (keydown.enter)="filterOnlineScheduling()"
            (keydown.space)="filterOnlineScheduling()"
            (click)="filterOnlineScheduling()"
          >
          </cs-slide-toggle>
        </div>
      </div>

      <div class="video-visits" *ngIf="showVideoVisitsFilter" [attr.aria-label]="'Video Visits'" tabindex="0">
        <div class="section-header toggle-col-option" [ngClass]="{'disabled': filtersModel.videoVisitsProvidersCount === 0 }">
          <span>Video visits <span class="option-count">({{ filtersModel.videoVisitsProvidersCount }})</span></span>
          <cs-slide-toggle
            [disabled]="filtersModel.videoVisitsProvidersCount === 0"
            [checked]="videoVisitsToggle"
            (keydown.enter)="filterVideoVisits()"
            (keydown.space)="filterVideoVisits()"
            (click)="filterVideoVisits()"
          >
          </cs-slide-toggle>
        </div>
      </div>

      <div class="accepting-new-patients" *ngIf="showAcceptingNewPatientsFilter" [attr.aria-label]="'Accepting new patients'" tabindex="0">
        <div class="section-header toggle-col-option" [ngClass]="{'disabled': filtersModel.newPatientsProvidersCount === 0 }">
          <span>Accepting new patients <span class="option-count">({{ filtersModel.newPatientsProvidersCount }})</span></span>
          <cs-slide-toggle
            [disabled]="filtersModel.newPatientsProvidersCount === 0"
            [checked]="newPatientsToggle"
            (keydown.enter)="filterNewPatients()"
            (keydown.space)="filterNewPatients()"
            (click)="filterNewPatients()"
          >
          </cs-slide-toggle>
        </div>
      </div>

      <div class="location-name" [attr.aria-label]="'Location'" *ngIf="filtersModel.locationName && showLocationName">
        <div class="section-header">
          <span>Location</span>
          <div class="close-icon" (click)="filterLocationName()">
            <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2  2 L16 16 M2 16 L16 2" />
            </svg>
          </div>
        </div>
        <div class="section-content">{{ filtersModel.locationName }}</div>
      </div>

      <cs-accordion [title]="'Search radius'" *ngIf="showSearchRadiusFilter && selectedLocation" [isCollapsed]="false">
        <div class="searchRadius">
          <span aria-label="Distance within" class="dialog-page-content p p2">Distance with in</span>
          <div tabindex="0" role="dialog" class="searchRadiusDialog">
            <dhcl-dropdown
              [placeholder]="'Select radius'"
              [selectedValue]="searchRadiusSelectedValue"
              [dropdowns]="searchRadiusList"
              (changed)="filterSearchRadius($event)"
            >
            </dhcl-dropdown>
          </div>
        </div>
      </cs-accordion>

      <cs-accordion [title]="'Doctor Availability'" *ngIf="showAvailabilityFilter" [isCollapsed]="isProviderAvailabilityCollapsed">
        <div class="patient-availability" (click)="filterAvailability(true)">
          <cs-availability-dialog [filtersModel]="filtersModel"> </cs-availability-dialog>
        </div>
      </cs-accordion>
      <cs-accordion [title]="'Specialty'" *ngIf="showSpecialtyFilter" [isCollapsed]="filtersModel.selectedSpecialties.length === 0">
        <cs-multiple-checkbox-searchable-dropdown
          id="specialty"
          [placeholder]="'Select Specialty'"
          [dropdowns]="filtersModel.availableSpecialties"
          (selectedDropDownValues)="selectedSpecialityValues($event)"
        >
        </cs-multiple-checkbox-searchable-dropdown>
      </cs-accordion>

      <cs-accordion
        [title]="'Medical group'"
        *ngIf="showMedicalGroupFilter"
        [tooltip]="medicalGroupHoverText"
        [tooltipInfo]="'More information about medical group'"
        [isCollapsed]="filtersModel.selectedMedicalGroup.length === 0"
      >
        <cs-multiple-checkbox-searchable-dropdown
          id="medicalGroups"
          [placeholder]="'Select Medical Group'"
          [showCount]="false"
          [dropdowns]="medicalGroupList"
          (selectedDropDownValues)="selectedMedicalValues($event)"
        >
        </cs-multiple-checkbox-searchable-dropdown>
      </cs-accordion>

      <cs-accordion
        [title]="'Hospital Affiliation'"
        *ngIf="showAffiliationFilter && affiliationHoverText !== ''"
        [tooltip]="affiliationHoverText"
        [isCollapsed]="filtersModel.selectedHospitals.length === 0"
      >
        <div *ngIf="affiliationToolTip !== ''" class="medical-group-filter-copy">{{ affiliationToolTip }}</div>
        <cs-multiple-checkbox-searchable-dropdown
          id="hospitalAffliations"
          [placeholder]="'Select Hospital Affiliation'"
          [dropdowns]="filtersModel.availableHospitals"
          (selectedDropDownValues)="selectedHospitalAffliations($event)"
        >
        </cs-multiple-checkbox-searchable-dropdown>
      </cs-accordion>

      <cs-accordion [title]="'Gender'" *ngIf="showGenderFilter" [isCollapsed]="filtersModel.genderSelect === ''">
        <div class="gender" role="radiogroup" aria-label="Select Gender">
          <div
            class="radio-button-option"
            (click)="filterGender('female')"
            (keydown.enter)="filterGender('female')"
            (keydown.space)="filterGender('female')"
            role="radio"
            [attr.aria-checked]="filtersModel.genderSelect === 'female'"
            tabindex="0"
          >
            <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
              <path
                *ngIf="filtersModel.genderSelect === 'female'"
                d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
              />
            </svg>
            Female&nbsp;
            <span>({{ filtersModel.femaleCount }})</span>
          </div>
          <div
            class="radio-button-option"
            (click)="filterGender('male')"
            (keydown.enter)="filterGender('male')"
            (keydown.space)="filterGender('male')"
            role="radio"
            [attr.aria-checked]="filtersModel.genderSelect === 'male'"
            tabindex="0"
          >
            <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
              <path
                *ngIf="filtersModel.genderSelect === 'male'"
                d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
              />
            </svg>
            Male&nbsp;
            <span>({{ filtersModel.maleCount }})</span>
          </div>
          <div
            class="radio-button-option"
            (click)="filterGender('non-binary')"
            (keydown.enter)="filterGender('non-binary')"
            (keydown.space)="filterGender('non-binary')"
            role="radio"
            [attr.aria-checked]="filtersModel.genderSelect === 'non-binary'"
            tabindex="0"
          >
            <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
              <path
                *ngIf="filtersModel.genderSelect === 'non-binary'"
                d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
              />
            </svg>
            Non-Binary&nbsp;
            <span>({{ filtersModel.nonbinaryCount }})</span>
          </div>
          <div
            class="radio-button-option"
            (click)="filterGender('other')"
            (keydown.enter)="filterGender('other')"
            (keydown.space)="filterGender('other')"
            role="radio"
            [attr.aria-checked]="filtersModel.genderSelect === 'other'"
            tabindex="0"
          >
            <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
              <path
                *ngIf="filtersModel.genderSelect === 'other'"
                d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
              />
            </svg>
            Other&nbsp;
            <span>({{ filtersModel.otherCount }})</span>
          </div>
        </div>
      </cs-accordion>

      <cs-accordion [title]="'Insurance'" *ngIf="showInsuranceFilter" [isCollapsed]="filtersModel.selectedInsurances.length === 0">
        <cs-multiple-checkbox-searchable-dropdown
          id="insurance"
          [placeholder]="'Select Insurance'"
          [dropdowns]="filtersModel.availableInsurances"
          (selectedDropDownValues)="selectedInsurances($event)"
        >
        </cs-multiple-checkbox-searchable-dropdown>
      </cs-accordion>

      <cs-accordion [title]="'Language'" *ngIf="showLanguageFilter" [isCollapsed]="filtersModel.selectedLanguages.length === 0">
        <cs-multiple-checkbox-searchable-dropdown
          id="language"
          [placeholder]="'Select Language'"
          [dropdowns]="filtersModel.availableLanguages"
          (selectedDropDownValues)="selectedLanguages($event)"
        >
        </cs-multiple-checkbox-searchable-dropdown>
      </cs-accordion>
    </div>
  </div>
</div>
