import { Component, OnInit } from '@angular/core';
import { ResizeService } from '../../../app/services/resize.service';
import { ERLinkCategories } from '../../../app/models/configuration';
import { ConfigurationService } from '../../services/configuration.service';
import { TitleTagHandlerService } from 'src/app/services/title-tag-handler.service';
import { SessionObjectNames } from 'src/app/models/stringconst-names';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'cs-search-landing',
  templateUrl: './search-landing.component.html',
  styleUrls: ['./search-landing.component.scss']
})
export class SearchLandingComponent implements OnInit {
  showMyProviders: boolean;
  landingPageHeader: string;
  showLegalCopy: boolean;
  legalCopy: string;
  legalCopyToolTip: string;
  legalCopyToolTipInfo: string;
  showEmergencyCare: boolean;
  showEmergencyCopy: boolean;
  showRegionalHelplineNumbers: boolean;
  helplineNumbers: any[];
  isDesktopViewport: boolean;
  displayInfo: string;
  displayHeadingInvalidInfo: string;
  emergencyCareLinkContent: ERLinkCategories[] = [];
  emergencyCopy: string;
  emergencyNumber: string;
  myProvidersLinkContent: string;
  isCernerPage: boolean;
  base_url: string;
  
  constructor(private resizeService: ResizeService, private configService: ConfigurationService,
    private titleTagHandlerService: TitleTagHandlerService) {}

  ngOnInit(): void {
    window.sessionStorage.removeItem(SessionObjectNames.SearchFrom);
    this.titleTagHandlerService.setPageTitle('','', null);
    this.showMyProviders = this.configService.showMyProviders();
    this.landingPageHeader = this.configService.getLandingPageHeader();
    this.showRegionalHelplineNumbers = this.configService.showRegionalHelplineNumbers();
    this.displayInfo = this.configService.getHelplineHeading();
    this.displayHeadingInvalidInfo = this.configService.getHelplineHeadingInvalid();
    this.isDesktopViewport = this.resizeService.isDesktopViewport;
    if (this.configService.getHelplineNumbers().length > 0) {
      this.helplineNumbers = this.configService.getHelplineNumbers();
    } else {
      this.helplineNumbers = [
        {
          heading: 'Arizona',
          phone: '(855) 697-0781'
        },
        {
          heading: 'California',
          phone: '(866) 914-3627'
        },
        {
          heading: 'Nevada',
          phone: '(866) 984-3627'
        }
      ];
    }
    this.getHelplineNumbers();
    this.myProvidersLinkContent = this.configService.myProvidersLinkScheme();
    this.showEmergencyCare = this.configService.showEmergencyCare();
    this.emergencyCareLinkContent = this.configService.getEmergencyCareLinkScheme();
    this.showEmergencyCopy = this.configService.showEmergencyCopy();
    this.emergencyCopy = this.configService.getEmergencyCopy();
    this.emergencyNumber = this.configService.getEmergencyNumber();
    this.showLegalCopy = this.configService.showLegalCopy();
    this.legalCopy = this.configService.getLegalCopy();
    this.legalCopyToolTip = this.configService.getLegalCopyToolTip();
    this.legalCopyToolTipInfo = this.configService.getLegalCopyToolTipInfo();
  }

  getHelplineNumbers() {
    this.helplineNumbers.forEach((helpline: any) => {
      const cleaned = helpline.phone.toString().replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        helpline.displayPhone = `(${match[1]}) ${match[2]}-${match[3]}`;
        helpline.phone = `+1${match[0]}`;
      } else {
        return;
      }
    });
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      // eslint-disable-next-line no-console
      console.log('TOKEN DECODED ERROR', Error);
      return null;
    }
  }
}
