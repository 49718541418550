<div class="locations-container" [ngClass]="isUpdatedProviderDetails?'update-container-style':''" id="{{routId}}">
  <div *ngIf="isUpdatedProviderDetails" class="info-line-break"></div>
  <cs-google-maps [officeAddresses]="officeAddresses" [centerPoint]="centerPoint"> </cs-google-maps>
  <div
    class="office-list"
    [ngClass]="{
      'show-less-content': hideExtraContent,
      'has-more-than-one-office': hasMoreThanOneOffice,
      'has-only-one-office': !hasMoreThanOneOffice,
      'update-officelist-style': isUpdatedProviderDetails && hasMoreThanOneOffice,
      'one-officelist-style': isUpdatedProviderDetails && !hasMoreThanOneOffice
    }"
  >
    <div
      (click)="setSelectedOffice(office)"
      [ngClass]="isUpdatedProviderDetails?'new-office':'office'"
      *ngFor="let office of officeAddresses; let i = index"
    >
      <ng-container
        [ngTemplateOutlet]="isUpdatedProviderDetails ? newOfficeDetails : officeDetails"
        [ngTemplateOutletContext]="{ office: office, i: i }"
      ></ng-container>
    </div>
  </div>
  <div class="show-more-less" *ngIf="showMoreLessButton">
    <a href="javascript:;" (click)="handleMoreLess()">
      {{ hideExtraContent ? 'More' : 'Less' }}
      <svg class="more-arrow" [ngClass]="{ 'less-arrow': !hideExtraContent }" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 1L10.5067 10.4933L1.01426 1" />
      </svg>
    </a>
  </div>
  <div *ngIf="isUpdatedProviderDetails" class="info-line-break"></div>
</div>

<ng-template #officeDetails let-office="office" let-i="i">
  <div class="rank-icon">
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0938 35.2969C12.7266 36.2812 14.2031 36.2812 14.8359 35.2969C25.1016 20.5312 27 18.9844 27 13.5C27 6.04688 20.9531 0 13.5 0C5.97656 0 0 6.04688 0 13.5C0 18.9844 1.82812 20.5312 12.0938 35.2969Z"
      />
    </svg>
    <span *ngIf="officeAddresses.length > 1" class="rank-number">{{ i + 1 }}</span>
  </div>
  <div class="office-details">
    <div class="office-address">
      <div class="office-name">
        <h3
          class="office-name-text"
          [ngClass]="{
          'dh': divisionCodes.includes('dignity-health'),
          'chi': !divisionCodes.includes('dignity-health')
        }"
          *ngIf="office.name && (office.name.length == 1 ? !office.name.includes('.') : true)"
        >
          {{ office.name }}
        </h3>
        <h3>{{office.description}}</h3>
        <h3
          class="office-name-text"
          [ngClass]="{
          'dh': divisionCodes.includes('dignity-health'),
          'chi': !divisionCodes.includes('dignity-health')
        }"
          *ngIf="!office.name"
        >
          {{ provider.officeNames[0] }}
        </h3>
      </div>
      <div class="street" *ngIf="isDesktopViewport">
        <div *ngIf="office.phones?.length > 0 || office.faxes?.length > 0; else noPhoneAndFax">
          <p>
            {{ office.address }}, <span> {{ office.city }}<span *ngIf="office.city">, </span>{{ office.state }} {{ office.zip }} </span>
          </p>
        </div>
        <ng-template #noPhoneAndFax>
          <p>{{ office.address }}</p>
          <p>{{ office.city }}<span *ngIf="office.city">, </span>{{ office.state }} {{ office.zip }}</p>
        </ng-template>
      </div>
      <div class="address" *ngIf="!isDesktopViewport">
        <p>{{ office.address }}</p>
        <p>{{ office.city }}<span *ngIf="office.city">, </span>{{ office.state }} {{ office.zip }}</p>
      </div>
      <div class="contact-number phone" *ngIf="office.phones?.length > 0">
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.2314 15.4191L15.9814 12.1691C16.0627 11.816 15.8783 11.4535 15.547 11.3097L12.047 9.8097C11.7408 9.67845 11.3814 9.76595 11.172 10.0253L9.62203 11.9191C7.22515 10.7941 5.2314 8.82845 4.08453 6.38158L5.97828 4.83158C6.23765 4.61908 6.32515 4.26283 6.1939 3.95658L4.6939 0.456576C4.54703 0.122201 4.18453 -0.0621745 3.8314 0.0190755L0.581401 0.769076C0.243901 0.847201 0.000150681 1.1472 0.000150681 1.50033C0.000150681 9.50345 6.48453 16.0003 14.5002 16.0003C14.8502 16.0003 15.1533 15.7597 15.2314 15.4191Z"
          />
        </svg>
        <p *ngIf="!isDesktopViewport">
          <a href="tel:{{ office.phones }}">{{ office.phones }}</a>
        </p>
        <p *ngIf="isDesktopViewport">{{ office.phones }}</p>
      </div>
      <div class="contact-number fax" *ngIf="office.faxes?.length > 0">
        <p>Fax: {{ office.faxes }}</p>
      </div>
      <div class="view-all-providers" *ngIf="viewAllLinkNeeded">
        <a tabindex="0" (click)="loadAllProvidersForLocation(office.id)">View all providers at this location</a>
      </div>
      <div class="get-directions p p2">
        <a
          tabindex="0"
          href="https://www.google.com/maps/search/{{ office.address }} {{ office.city }} {{ office.state }} {{ office.zip }}"
          target="_blank"
          >Get Directions</a
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #newOfficeDetails let-office="office" let-i="i">
  <div class="office-inner">
    <div class="office-info">
      <svg width="19" height="28" viewBox="0 0 19 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.51042 27.4673C8.95573 28.1776 9.99479 28.1776 10.4401 27.4673C17.6641 16.8137 19 15.6976 19 11.7405C19 6.36293 14.7448 2 9.5 2C4.20573 2 0 6.36293 0 11.7405C0 15.6976 1.28646 16.8137 8.51042 27.4673Z"
          fill="#55a63a"
        />
        <text x="5.3" y="18.7" fill="white">{{i+1}}</text>
      </svg>
      <h3>{{ office.name }}</h3>
      <h3>{{office.description}}</h3>
    </div>
    <div class="office-address">
      <div class="address-container">
        <div class="address">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.41345 9.74448C1.81811 9.51296 1.52043 9.39719 1.43353 9.23039C1.35819 9.08579 1.35809 8.91354 1.43326 8.76886C1.51997 8.60195 1.8175 8.48584 2.41258 8.25361L19.3003 1.66327C19.8375 1.45364 20.1061 1.34883 20.2777 1.40616C20.4268 1.45596 20.5437 1.57292 20.5935 1.72197C20.6509 1.8936 20.5461 2.16219 20.3364 2.69937L13.7461 19.5871C13.5139 20.1822 13.3977 20.4797 13.2308 20.5664C13.0862 20.6416 12.9139 20.6415 12.7693 20.5662C12.6025 20.4793 12.4867 20.1816 12.2552 19.5862L9.6271 12.8282C9.58011 12.7074 9.55661 12.647 9.52031 12.5961C9.48815 12.551 9.44871 12.5115 9.40361 12.4794C9.35273 12.4431 9.29231 12.4196 9.17146 12.3726L2.41345 9.74448Z"
              stroke="#4D525A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="p1">
            {{ office.address }},<span> {{ office.city }}<span *ngIf="office.city">, </span>{{ office.state }} {{ office.zip }} </span>
          </p>
        </div>
        <div class="phone">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.38028 8.85335C9.07627 10.303 10.0251 11.6616 11.2266 12.8632C12.4282 14.0648 13.7869 15.0136 15.2365 15.7096C15.3612 15.7694 15.4235 15.7994 15.5024 15.8224C15.7828 15.9041 16.127 15.8454 16.3644 15.6754C16.4313 15.6275 16.4884 15.5704 16.6027 15.4561C16.9523 15.1064 17.1271 14.9316 17.3029 14.8174C17.9658 14.3864 18.8204 14.3864 19.4833 14.8174C19.6591 14.9316 19.8339 15.1064 20.1835 15.4561L20.3783 15.6509C20.9098 16.1824 21.1755 16.4481 21.3198 16.7335C21.6069 17.301 21.6069 17.9713 21.3198 18.5389C21.1755 18.8242 20.9098 19.09 20.3783 19.6214L20.2207 19.779C19.6911 20.3087 19.4263 20.5735 19.0662 20.7757C18.6667 21.0001 18.0462 21.1615 17.588 21.1601C17.1751 21.1589 16.8928 21.0788 16.3284 20.9186C13.295 20.0576 10.4326 18.4332 8.04466 16.0452C5.65668 13.6572 4.03221 10.7948 3.17124 7.76144C3.01103 7.19699 2.93092 6.91477 2.9297 6.50182C2.92833 6.0436 3.08969 5.42311 3.31411 5.0236C3.51636 4.66357 3.78117 4.39876 4.3108 3.86913L4.46843 3.7115C4.99987 3.18006 5.2656 2.91433 5.55098 2.76999C6.11854 2.48292 6.7888 2.48292 7.35636 2.76999C7.64174 2.91433 7.90747 3.18006 8.43891 3.7115L8.63378 3.90637C8.98338 4.25597 9.15819 4.43078 9.27247 4.60655C9.70347 5.26945 9.70347 6.12403 9.27247 6.78692C9.15819 6.96269 8.98338 7.1375 8.63378 7.4871C8.51947 7.60142 8.46231 7.65857 8.41447 7.72538C8.24446 7.96281 8.18576 8.30707 8.26748 8.58743C8.29048 8.66632 8.32041 8.72866 8.38028 8.85335Z"
              stroke="#4D525A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="p1 phone-number">{{ office.phones }}</p>
          <p class="p1 phone-number-link">
            <a href="tel:{{ office.phones }}">{{ office.phones }}</a>
          </p>
        </div>
        <div class="fax" *ngIf="office.faxes?.length > 0">
          <p>Fax:</p>
          <p>{{ office?.faxes }}</p>
        </div>
        <div class="address-link">
          <div><a tabindex="0" (click)="loadAllProvidersForLocation(office.id)">View all providers at this location</a></div>
          <div>
            <a
              tabindex="0"
              href="https://www.google.com/maps/search/{{ office.address }} {{ office.city }} {{ office.state }} {{ office.zip }}"
              target="_blank"
              >Get Directions</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
